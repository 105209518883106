.buy-online{
    @extend .x-padding-120;
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;

    &__picture{
        width: 50%;
        position: relative;
        @media screen and (max-width: 1024px) { width: 100%; }

        picture{
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
        }
    }

    &__content{
        @extend .y-padding-130;
        @extend .left-padding-80;
        width: 50%;
        @media screen and (max-width: 1024px) {
            width: 100%;
            padding: 0;
        }
    }

    &__over-title{
        @extend .over-title;
        @include fontsize(32, 0, 0, 0, 22, 0, 0);
    }

    &__title{
        @include fontsize(55, 0, 0, 0, 22, 0, 0);
        @extend .top-padding-30;
        font-weight: bold;
        line-height: 1.0909em; // 60px/55px
        color: $color2;
    }

    &__form{
        @extend .top-padding-70;
        .field{
            margin-bottom: 0;
            z-index: 3;
            position: relative;
        }
    }

    &__form-button{
        @extend .top-padding-40;
    }
}