/*
|--------------------------------------------------------------------------
| RUBRIQUE OUVERTE
|--------------------------------------------------------------------------
*/
.rubric.js-accordion-opened {
    border-bottom: 7px solid $color2;


    .rubric__transition {
        opacity: 1;
        transform: none;
        transition: opacity 700ms $easeInOutQuart 100ms, transform 700ms $easeInOutQuart 100ms;
    }

    .rubric__title {
        color: $color1;
    }

    .rubric__icon:before {
        background: $color1;
    }
    .rubric__icon .rubric__svg {
        transform: translate(-50%, -50%) rotate(180deg);
    }
}