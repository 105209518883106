.online-sales {
    &__container {
        background: #fff;

        @extend .x-padding-80;
        @extend .bottom-padding-190;
    }

    &__title {
        color: #000;

        @extend .huge-title;
        //@extend .y-padding-100;
        padding-top: var(--y-padding-100);
    }

    &__subtitle {
        color: #000;

        @extend .large-title;
        padding-top: var(--y-padding-100);
        padding-bottom: var(--y-padding-30);
    }
}