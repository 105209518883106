:root{
    --page-content-background-color: transparent;
    --page-content-text-color: #{$color2};
}
.dark-bg{
    --page-content-background-color: #{$color7};
    --page-content-text-color: white;
}

.white-bg {
    --page-content-background-color: #fff;
}

.main{
    background-color: $color3;
    position: relative;

    &__content{
        @extend .x-padding-180;
        @extend .y-padding-100;
        position: relative;
        background: var(--page-content-background-color);
        overflow-x: hidden;

        &__top{
            &__intro{
                @include fontsize(35,  0, 30, 25, 22, 21, 20);
                color: var(--page-content-text-color);
                line-height: 1.4285714em; //50px/35px
                @extend .bottom-padding-40;
            }
        }
    }

    &__content--jobs {
        padding-top: 0;
    }

    &__rubrics,
    &__documents{
        @extend .x-padding-180;
        @extend .top-padding-90;
        @extend .bottom-padding-130;
        background: white;

        h2{
            @extend .large-title;
            @extend .bottom-padding-50;
        }
    }

    &__documents-desc{
        @extend .bottom-padding-50;
    }

    &__components{
        h2{
            @extend .large-title;
            @extend .bottom-padding-50;
        }
    }

    &__content__print__footer{
        display: none;
    }
}