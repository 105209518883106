.testimonials-slider{

    --margin-width: 280px;
    --padding-text: 40px;
    --picture-ratio: 56.03448%; // 650px /1160px
    --text-width: 480px;

    @media screen and (max-width: 1024px) {
        --picture-ratio: 100%;
        --text-width: 380px;
    }

    @media screen and (max-width: 800px) {
        --picture-ratio: 125%;
        --text-width: 350px;
        --padding-text: 30px;
    }

    @media screen and (max-width: 700px) {
        --margin-width: 0;
        --picture-ratio: 75%;
    }

    background-color: $color3;
    overflow-x: hidden;
    position: relative;

    &__testimonials{
        background-color: white;
        max-width: var(--text-width);
        position: absolute;
        top: 50%;
        left: var(--x-padding-180);
        z-index: 4;
        transform: translate(0, -50%);

        @media screen and (max-width: 700px) {
            position: relative;
            transform: none;
            max-width: 100%;
            left: auto;
            background-color: $color3;
        }
    }

    &__testimonial{
        color: $color2;
        padding: var(--padding-text);
        padding-bottom: 100px;
    }

    &__testimonial-text{
        color: rgba($color2, 0.5);
        font-size: rem(18);
        line-height: 1.6666em; //30px /18px
        padding-top: 35px;
        position: relative;

        &::before {
            content: '';
            position: absolute;
            background-image: url(../medias/images/icons/quote.png);
            width: 21px;
            height: 21px;
            background-size: cover;
            left: 0;
            top: 2px;
        }
    }

    &__testimonial-author{
        color: $color2;
        font-size: rem(14);
        font-weight: 500;
        margin-top: 20px;
    }

    &__arrows{
        position: absolute;
        left: var(--padding-text);
        bottom: var(--padding-text);
        z-index: 5;
    }

    &__pager{
        position: absolute;
        right: var(--padding-text);
        bottom: var(--padding-text);
        z-index: 5;
        font-size: rem(18);
        color: $color4;

        span:first-child(){
            color: $color2;
        }
    }

    &__pictures{
        .slick-list{
            padding-left: var(--margin-width);
            overflow: visible;
        }
    }

    &__picture{
        width: 100%;
        padding-bottom: var(--picture-ratio);
        position: relative;

        picture{
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
        }
    }


}