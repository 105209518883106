/*
|--------------------------------------------------------------------------
| OVERLAY VISIBLE
|--------------------------------------------------------------------------
*/
.show-overlay-menu {
    
    .overlay-menu {
        visibility: visible;
        pointer-events: all;

        &__close{
            opacity: 1;
        }
        
        &__background { background: rgba(#000, 0.4); }
        &__container {
            right: 0px;

            @media screen and (max-width: 600px) { right: 0%; }
        }
        &__main-level,
        &__secondary-level{
            opacity: 1;
            transform: none;
        }
    }
}


/*
|--------------------------------------------------------------------------
| OVERLAY SE FERME
|--------------------------------------------------------------------------
*/
.show-overlay-menu.closing-overlay-menu {

    .overlay-menu {
        &__background { background: rgba(#000, 0); }

        &__close{
            opacity: 0;
        }

        &__container {
            right: -500px;

            @media screen and (max-width: 600px) { right: -100%; }
        }
        &__main-level,
        &__secondary-level{
            opacity: 0;
            transform: translateX(200px);
        }
    }

}


/*
|--------------------------------------------------------------------------
| SOUS-MENU OUVERT
|--------------------------------------------------------------------------
*/
.overlay-menu {
    .js-accordion-opened &__second-level-arrow { transform: translate(0,50%) rotate(-180deg); }
    .js-accordion-opened &__third-level { opacity: 1; }
    //.js-accordion-opened &__second-level-button { padding-bottom: 20px; }
}