.similar-products{
    @extend .x-padding-120;
    background-color: white;
    overflow: hidden;

    &__over-title{
        @extend .over-title;
    }

    &__title{
        @extend .huge-title;
        @extend .top-padding-30;
        @extend .bottom-padding-100;

        span{
            display: block;
        }
    }

    &__product-slider{
        @media screen and (max-width: 768px) { padding-top: 30px; }
    }

    &__all-products{
        @extend .top-padding-50;
    }
    
}