.search-form{
    &__wrapper{
        display: flex;
        border-bottom: 1px solid $color2;
        position: relative;
    }

    &__input{
        border: 0;
        font-weight: 500;
        font-size: rem(18);
        padding: 12px 0;
        flex-grow: 1;
        margin-right: 40px;

        & + .search-form__x {
            opacity: 0;
            pointer-events: none;
        
            position: absolute;
            right: 30px;
            top: 50%;
            transform: translate(0, -50%);
            width: 20px;
            height: 20px;
            cursor: pointer;
            transition: opacity 0.3s;
        
            &::before,
            &::after{
                content: '';
                position: absolute;
                background-color: $color2;
                top: 50%;
                left: 50%;
                transform: translate(-50%,-50%) rotate(45deg);
                width: 20px;
                height: 2px;
                transition: background-color 0.3s;
            }
            &::after{
                transform: translate(-50%,-50%) rotate(-45deg);
            }

            &:hover{
                &::before,
                &::after{
                    background-color: rgba($color2,0.5);
                }
            }
        }

        &:not(:placeholder-shown) + .search-form__x {
            opacity: 1;
            pointer-events: all;
        }

    }

    &__btn{
        opacity: 1;
        transition: opacity 0.3s $easeInOutCubic;
        display: flex;
        align-items: center;
        svg{
            width: 20px;
            height: 20px;
            fill: $color2;
        }

        &:hover{
            opacity: 0.6;
        }
    }
}