// Documents normal
.documents {
    .document {
        position: relative;
        border-bottom: 1px solid $color2;
    
        &:first-child { border-top: 1px solid $color2; }
    
        &__link {
            position: relative;
            cursor: pointer;
            padding: 20px 0;
            min-height: 155px;
            display: flex;
            align-items: center;

            @media screen and (max-width: 1200px) { padding-top: 50px; padding-bottom: 50px; }
            @media screen and (max-width: 1024px) { padding-top: 40px; padding-bottom: 40px; }
            @media screen and (max-width: 600px)  { padding-top: 30px; padding-bottom: 30px; }

            @media screen and (max-width: 768px) { min-height: 130px; }
            @media screen and (max-width: 600px) { min-height: 110px; }
        }
    
        &__link:hover{
            .document__icon:before {
                background: $color1;
                transform: scale(1.20512); // 94px/78px
            }
            .document__title{
                color: $color1;
            }
        }
    
        &__title {
            padding-right: 50px;
            line-height: 1.5;
            color: $color2;
            transition: color 0.3s $easeInOutCubic;
            padding-left: 20px;
            @extend .large-title-smaller-mobile;

            @media screen and (max-width: 1024px) { padding-left: 0; }
        }
    
        &__icon {
            position: absolute;
            width: 78px;
            height: 78px;
            top: 50%;
            right: 20px;
            transform: translateY(-50%);

            @media screen and (max-width: 1024px) { right: 0; width: 60px; height: 60px; }
            @media screen and (max-width: 600px) { width: 34px; height: 34px; }
        }
    
        &__icon:before {
            content: '';
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            background: $color2;
            border-radius: 50%;
            transition: background 300ms, transform 300ms $easeOutCubic;
        }
    
        &__svg {
            position: absolute;
            width: 22px;
            height: 22px;
            top: 50%;
            left: 50%;
            fill: white;
            transform: translate(-50%, -50%);
            transition: transform 300ms $easeOutCubic, stroke 300ms;

            @media screen and (max-width: 600px) { width: 15px; height: 15px; }
        }
        
        &__svg-upload {
            width: 16px;
            height: 16px;

            @media screen and (max-width: 600px) { width: 10px; height: 10px; }
        }
    }
}

// Documents plus
.documents.documents--plus {
    .document {
        &__link {
            padding-top: 20px;
            padding-bottom: 20px;

            @media screen and (max-width: 600px) { padding-top: 30px; padding-bottom: 30px; }
        }

        &__flex {
            display: flex;
            align-items: center;
            width: 100%;
        }

        &__text {
            width: calc(100% - 170px);

            @media screen and (max-width: 1200px) { width: calc(100% - 155px); }
            @media screen and (max-width: 1024px) { width: calc(100% - 125px); }
            @media screen and (max-width: 768px)  { width: calc(100% - 95px);  }
            @media screen and (max-width: 600px)  { width: 100%; }
        }

        &__subtitle {
            margin-bottom: 5px;
            padding-left: 20px;
            @extend .minuscule-title;

            @media screen and (max-width: 1024px) { padding-left: 0; }
        }


        &__picture {
            width: 220px;
            height: 100px;
            margin-right: 30px;
            overflow: hidden;

            @media screen and (max-width: 1200px) { width: 125px; height: 125px; }
            @media screen and (max-width: 1024px) { width: 100px; height: 100px; margin-right: 25px; }
            @media screen and (max-width: 768px)  { width: 75px;  height: 75px; margin-right: 20px; }
            @media screen and (max-width: 600px)  { display: none; }
        }

        &:not(.document--no-image){
            .document__title,
            .document__subtitle{
                padding-left: 0;
            }
        }

    }
}

// Document normal dans une rubrique
.documents-in-rubric {
    .document {
        border-bottom: 1px solid rgba($color2, 0.2);
        
        &:first-child {
            border-top: 1px solid rgba($color2, 0.2);
        }
        
        &__link {
            display: block;
            position: relative;
            padding: 25px 60px 25px 0;
        }
    
        &__link:hover {
            .document__title { color: $color1; }
            .document__svg { fill: $color1; }
        }
    
        &__title {
            font: rem(22)/1 $font1;
            color: $color2;
            transition: color 300ms;
        }
    
        &__svg {
            position: absolute;
            width: 14px;
            height: 16px;
            top: 50%;
            right: 30px;
            fill: $color2;
            transform: translateY(-50%);
            z-index: 1;
            transition: fill 300ms;
        }
    
        &__svg-upload {
            width: 11px;
            height: 11px;
        }

        &.document--no-image{
            .document__flex{
                padding-left: 20px;
            }
        }
    }
}

// Document normal dans une rubrique
.documents-in-rubric.documents-in-rubric--plus {
    .document {
        &__flex {
            display: flex;
            align-items: center;
        }

        &__text {
            width: calc(100% - 130px);

            @media screen and (max-width: 1200px) { width: calc(100% - 155px); }
            @media screen and (max-width: 1024px) { width: calc(100% - 125px); }
            @media screen and (max-width: 768px)  { width: calc(100% - 95px);  }
            @media screen and (max-width: 600px)  { width: 100%; }
        }

        &__picture {
            width: 220px;
            height: 100px;
            margin-right: 30px;
            overflow: hidden;

            @media screen and (max-width: 1200px) { width: 125px; height: 125px; }
            @media screen and (max-width: 1024px) { width: 100px; height: 100px; margin-right: 25px; }
            @media screen and (max-width: 768px)  { width: 75px;  height: 75px; margin-right: 20px; }
            @media screen and (max-width: 600px)  { display: none; }
        }

        &__subtitle {
            margin-bottom: 5px;
            @extend .minuscule-title;
        }
    }
}

// Document dans une rubrique en tant que composante
.documents-in-rubric.documents-in-rubric--small {
    .document {
        max-width: 475px;

        &__link {
            padding-right: 30px;
        }

        &__svg {
            right: 0;
        }

        &__title {
            font: 700 rem(13)/1 $font1;
            text-transform: uppercase;
            letter-spacing: 1px;
        }
    }
}