.about-us {
    &__container {

    }

    &__text {
        margin-bottom: 60px;
    }

    &__title {
        @extend .large-title;

        margin-bottom: 35px;
    }

    &__paragraph {
        @extend .paragraph;
    }

    &__paragraph:not(:last-child) {
        margin-bottom: 25px;
    }
}