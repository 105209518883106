.become-a-member-form{
    @extend .x-padding-80;
    @extend .y-padding-130;
    background-color: white;

    &__title{
        @extend .huge-title;
        @extend .bottom-padding-80;
        text-align: center;
        span{
            display: block;
        }
    }
}