.show-overlay-search:not(.closing-overlay-search){
    .header{
        &__nav{
            &__primary{
                &__search{
                    // Icone loupe
                    svg{  opacity: 0; }

                    // Icone X
                    &>span{ opacity: 1; }
                }
            }
        }
    }
}