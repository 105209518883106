.tag-list{
    display: flex;
    flex-wrap: wrap;
    margin-left: -5px;
    margin-right: -5px;
    margin-bottom: -10px;
    li{
        font-weight: 500;
        color: $color2;
        background-color: $color3;
        padding: 8px 12px 7px 12px;
        margin: 0 5px;
        margin-bottom: 10px;
        font-size: rem(11);
        text-transform: uppercase;
        letter-spacing: 0.075em;
    }
}