/*
|--------------------------------------------------------------------------
| OVERLAY VISIBLE
|--------------------------------------------------------------------------
*/
.show-overlay-dropdown {
    .overlay-dropdown {
        visibility: visible;
        pointer-events: all;

        &__background { background: rgba(#000, 0.4); }

        &__section { transition: opacity 800ms $easeInOutQuart, visibility 0ms ease 800ms; }

        &__sub-section-container { transition: transform 800ms $easeInOutQuart; }
    }
}


/*
|--------------------------------------------------------------------------
| SECTION VISIBLE
|--------------------------------------------------------------------------
*/
@mixin section($section) {
    .show-overlay-dropdown[data-section="#{$section}"] {
        .header {
            [data-section="#{$section}"] span::after { transform: translate(0, -50%) rotate(180deg); }
        }
        
        .overlay-dropdown__container{
            height: 100%;
        }

        .overlay-dropdown [data-section="#{$section}"] {
            opacity: 1;
            visibility: visible;
            transition: opacity 800ms $easeInOutQuart;

            .overlay-dropdown__sub-section-container {
                transform: none;
                transition: transform 800ms $easeInOutQuart;
            }
        }
    }
}

@include section(products);
@include section(choose-technoflex);
@include section(where-to-find);



/*
|--------------------------------------------------------------------------
| OVERLAY SE FERME
|--------------------------------------------------------------------------
*/
.show-overlay-dropdown.closing-overlay-dropdown {
    .overlay-dropdown {
        &__background { background: rgba(#000, 0); }
    }
}