:root{
    --page-top-background-color: white;
}
.dark-bg{
    --page-top-background-color: #{$color7};
}

.page-top-2-cols{
    --rapid-access-height: 130px;
    --button-spacing: 50px;
    --print-button-size: 70px;

    @media screen and (max-width: 1300px) {
        --button-spacing: 30px;
    }

    @media screen and (max-width: 800px) {
        --rapid-access-height: 100px;
    }

    @media screen and (max-width: 500px) {
        --rapid-access-height: 80px;
    }

    height: calc(100vh - var(--header-height));
    min-height: 500px;
    position: relative;
    background-color: var(--page-top-background-color);
    display: flex;
    flex-wrap: wrap;

    @media screen and (max-width: 800px) {
        flex-wrap: nowrap;
        flex-direction: column;
        min-height: 700px;
    }

    &__content{
        @extend .x-padding-80;
        padding-bottom: var(--rapid-access-height);
        width: 50%;
        height: 100%;
        position: relative;
        display: flex;
        align-items: center;

        @media screen and (max-width: 1024px) { width: 60%; }
        @media screen and (max-width: 800px) {
            width: 100%;
            height: auto;
            padding-top: 80px;
            padding-bottom: 80px;
        }


        .breadcrumbs{
            @extend .x-padding-80;
            position: absolute;
            width: 100%;
            left: 0;
            top: 0;
        }

        .grid-pattern{
            left: 0;
            bottom: var(--rapid-access-height);
            height: 70px;
            width: 110%;
            background-position: right 1px;
            @media screen and (max-width: 800px) {
                display: none;
            }
        }
    }

    &__over-title{
        @extend .bottom-padding-30;
        @extend .medium-title;
        font-weight: normal;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    &__over-title-print-logo{
        display: none;
    }

    &__date-and-categ{
        @extend .bottom-padding-30;
        @extend .medium-title;
        color: $color1;
        font-size: rem(13);
        text-transform: uppercase;
        font-weight: 500;
        padding-bottom: 25px;
    }

    &__title{
        h1{
            @extend .huge-title;
        }
    }

    &__button{
        @extend .top-padding-50;
    }

    .tag-list{
        @extend .top-padding-50;
    }

    &__dots{
        position: absolute;
        right: 25px;
        bottom: 50%;
        transform: translate(0, calc(50% - var(--rapid-access-height)/2 ));

        .slick-dots{
            bottom: 0;
            position: relative;
        }

        @media screen and (max-width: 800px) {
            right: auto;
            bottom: 10px;
            transform: translate(0, 0);
        }
    }

    &__rapid-access{
        background-color: $color2;
        height: var(--rapid-access-height);
        position: absolute;
        bottom: 0;
        left: 0;
        min-width: 60%;
        max-width: 100%;
        z-index: 2;
        overflow-x: auto;

        @media screen and (max-width: 800px) {
            width: 100%;
        }

        ul{
            display: flex;
            height: 100%;
            li{
                position: relative;
                a,span{
                    height: 100%;
                    padding: 0 var(--button-spacing);
                    display: flex;
                    align-items: center;
                    color: white;
                    white-space: nowrap;
                    font-size: rem(18);
                    font-weight: bold;
                    cursor: pointer;
                    transition: color 0.3s $easeInOutCubic;
                }

                &:first-child{
                    a,span{
                        font-weight: normal;

                        @media screen and (max-width: 768px) {
                            font-size: rem(14);
                        }

                        cursor: default;
                        &::after{
                            content: '';
                            background-color: white;
                            opacity: 0.5;
                            height: 18px;
                            width: 1px;
                            position: absolute;
                            top: 50%;
                            right: 0;
                            transform: translate(0,-50%);
                        }
                    }
                }

                &:not(:first-child){
                    a,span{
                        &::before{
                            content: '';
                            position: absolute;
                            top: 0;
                            height: 0;
                            left: var(--button-spacing);
                            right: var(--button-spacing);
                            transition: height 0.3s $easeInOutCubic;
                            background-color: $color1;
                        }
                        &:hover{
                            color: $color1;
                            &::before{
                                height: 7px;
                            }
                        }
                    }
                }
            }
        }
    }

    &__print-btn{
        position: absolute;
        bottom: calc(var(--rapid-access-height)*0.5);
        right: 50px;
        width: var(--print-button-size);
        height: var(--print-button-size);
        z-index: 2;
        transform: translate(0, 50%);

        @media screen and (max-width: 1300px) {
            display: none;
        }
        svg{
            width: 24px;
            height: 24px;
            fill: white;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%,-50%);
            z-index: 3;
            pointer-events: none;
        }
        &::after{
            content:'';
            position: absolute;
            width: 100%;
            height: 100%;
            border-radius: 50%;
            background-color: $color1;
            z-index: 2;
            cursor: pointer;
            transition: transform 0.3s $easeInOutCubic;
        }
        &:hover{
            &::after{
                transform: scale(1.1);
            }
        }
    }

    &__picture{
        width: 50%;
        height: 100%;
        background-color: $color8;

        @media screen and (max-width: 1024px) { width: 40%; }
        @media screen and (max-width: 800px) {
            width: 100%;
            flex-grow: 1;
            padding-bottom: var(--rapid-access-height);
            overflow: hidden;
        }
    }

    &__credit{
        position: absolute;
        top: 0;
        right: 0;
        background-color: rgba($color2,0.5);
        color: white;
        padding: 20px 30px;
        font-size: rem(13);
        z-index: 1;
        @media screen and (max-width: 768px) { padding: 14px 20px; }
    }

    &__picture-wrapper{
        height: 100%;
        margin-bottom: 0 !important;

        .slick-list,
        .slick-track,
        .slick-slide>div{
            height: 100%;
        }

        .slick-dots{
            left: 0;
            bottom: 50%;
            transform: translate(calc(-100% + -15px ), calc(50% - var(--rapid-access-height)/2 ));
        }

    }

    &__picture-slide{
        height: 100%;

        picture{
            height: 100%;
        }
    }

    &--bigger-min-height{
        min-height: 700px;
        @media screen and (max-width: 800px) {
            min-height: 700px;
        }
    }

    &--inverted{
        flex-direction: row-reverse;
        @media screen and (max-width: 800px) { flex-direction: column; }
        .page-top-2-cols__content{
            .grid-pattern{
                display: none;
            }
        }
        .page-top-2-cols__picture{
            padding-bottom: var(--rapid-access-height);
        }
    }

    &--no-rapid-access{
        .page-top-2-cols__content{
            padding-bottom: 0;

            @media screen and (max-width: 1024px) { padding-bottom: var(--x-padding-80); }

            .grid-pattern{
                bottom: 0;
            }
        }

        @media screen and (max-width: 1024px) { 
            .page-top-2-cols__picture{
                padding-bottom: 0;
            }
        }
    }

    &--smaller-title{
        .page-top-2-cols__title{
            h1{
                @include fontsize(50, 0, 32, 30, 26, 0, 0);
            }
        }
    }

}