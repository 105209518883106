.pagination{
    display: flex;
    justify-content: center;
    li{
        a,span{
            text-align: center;
            display: inline-block;
            font-size: rem(18);
            color: $color2;
            line-height: 30px;
            height: 30px;
            width: 30px;
            margin: 0 5px;
            font-weight: 500;
            transition: color 0.3s $easeInOutCubic;
            @media screen and (max-width: 600px) {
                font-size: rem(16);
                line-height: 25px;
                height: 25px;
                width: 25px;
            }
        }
        a{
            &:hover{
                color: rgba($color2,0.4);
            }
        }
        span{
            color: $color1;
        }
    }

    &__prev,
    &__next{
        a,span{
            position: relative;
            width: 30px;
            svg{
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%,-50%) rotate(90deg);
                transition: transform 0.3s $easeInOutCubic;
                width: 19px;
                height: 26px;
                fill: rgba($color2,0.2);
            }
        }
        a{
            svg{
                fill: $color1;
            }
            &:hover{
                svg{
                    transform: translate(calc(-50% - 10px),-50%) rotate(90deg);
                }
            }
        }
    }

    &__prev{
        margin-right: 30px;
        @media screen and (max-width: 768px) { margin-right: 0; }
    }

    &__next{
        margin-left: 30px;
        @media screen and (max-width: 768px) { margin-left: 0; }
        a,span{
            svg{
                transform: translate(-50%,-50%) rotate(-90deg);
            }
        }
        a{
            &:hover{
                svg{
                    transform: translate(calc(-50% + 10px),-50%) rotate(-90deg);
                }
            }
        }
    }
}