/*** ----- IMPORTS  ----- ***/
@import url(../node_modules/@fancyapps/fancybox/dist/jquery.fancybox.min.css);
@import url(../libraries/overlay.min.css);
/*** ----- TECHNICAL ----- ***/
:root {
  --grid-gaps: 25px;
  --grid-bigger-gaps: 40px; }
  @media screen and (max-width: 1200px) {
    :root {
      --grid-gaps: 15px; } }
  @media screen and (max-width: 768px) {
    :root {
      --grid-gaps: 10px; } }
  @media screen and (max-width: 1200px) {
    :root {
      --grid-bigger-gaps: 30px; } }
  @media screen and (max-width: 768px) {
    :root {
      --grid-bigger-gaps: 20px; } }

/*** ----- COLORS ----- ***/
/*** ----- FONTS ----- ***/
/*** ----- EASINGS ----- ***/
:root {
  --header-height: 125px; }
  @media screen and (max-width: 900px) {
    :root {
      --header-height: 83px; } }

html, body, div, span, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
abbr, address, cite, code,
del, dfn, em, img, ins, kbd, q, samp,
small, strong, sub, sup, var,
b, i,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, figcaption, figure,
footer, header, hgroup, menu, nav, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent; }

body {
  line-height: 1; }

article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block; }

nav ul {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none; }

a {
  margin: 0;
  padding: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent; }

ins {
  background-color: #ff9;
  color: #000;
  text-decoration: none; }

mark {
  background-color: #ff9;
  color: #000;
  font-style: italic;
  font-weight: bold; }

del {
  text-decoration: line-through; }

abbr[title], dfn[title] {
  border-bottom: 1px dotted;
  cursor: help; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #cccccc;
  margin: 1em 0;
  padding: 0; }

input, select {
  vertical-align: middle; }

/*** Blanko ***/
* {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

/* clears the 'X' from Internet Explorer */
input[type=search]::-ms-clear {
  display: none;
  width: 0;
  height: 0; }

input[type=search]::-ms-reveal {
  display: none;
  width: 0;
  height: 0; }

/* clears the 'X' from Chrome */
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  display: none; }

h1, h2, h3, h4, h5, h6 {
  font-weight: normal; }

ol, ul {
  list-style: none; }

input:focus {
  outline: none; }

a {
  -webkit-tap-highlight-color: transparent; }

.slick-slide, .slick-slide a, .slick-slide:focus {
  outline: none !important; }

.slick-loading .slick-list {
  background: none; }

.stripe-loading-indicator .stripe, .stripe-loading-indicator .stripe-loaded {
  background: #00c281 !important; }

/*
|--------------------------------------------------------------------------
| COMMON STYLES
|--------------------------------------------------------------------------
*/
/*
|--------------------------------------------------------------------------
| FUNCTIONS
|--------------------------------------------------------------------------
*/
.grid {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  flex-flow: row wrap;
  width: 100%; }
  .grid.gaps {
    width: calc(100% + var(--grid-gaps) + var(--grid-gaps));
    margin-left: calc(var(--grid-gaps)*-1);
    margin-right: calc(var(--grid-gaps)*-1); }
    .grid.gaps .column {
      padding-right: var(--grid-gaps);
      padding-left: var(--grid-gaps); }
  .grid.biggerGaps {
    width: calc(100% + var(--grid-bigger-gaps) + var(--grid-bigger-gaps));
    margin-left: calc(var(--grid-bigger-gaps)*-1);
    margin-right: calc(var(--grid-bigger-gaps)*-1); }
    .grid.biggerGaps .column {
      padding-right: var(--grid-bigger-gaps);
      padding-left: var(--grid-bigger-gaps); }

.col1 {
  width: 8.33333%; }

.col2 {
  width: 16.66667%; }

.col3 {
  width: 25%; }

.col4 {
  width: 33.33333%; }

.col5 {
  width: 41.66667%; }

.col6 {
  width: 50%; }

.col7 {
  width: 58.33333%; }

.col8 {
  width: 66.66667%; }

.col9 {
  width: 75%; }

.col10 {
  width: 83.33333%; }

.col11 {
  width: 91.66667%; }

.col12 {
  width: 100%; }

@media screen and (max-width: 1800px) {
  .col1_1800 {
    width: 8.33333%; }
  .col2_1800 {
    width: 16.66667%; }
  .col3_1800 {
    width: 25%; }
  .col4_1800 {
    width: 33.33333%; }
  .col5_1800 {
    width: 41.66667%; }
  .col6_1800 {
    width: 50%; }
  .col7_1800 {
    width: 58.33333%; }
  .col8_1800 {
    width: 66.66667%; }
  .col9_1800 {
    width: 75%; }
  .col10_1800 {
    width: 83.33333%; }
  .col11_1800 {
    width: 91.66667%; }
  .col12_1800 {
    width: 100%; } }

@media screen and (max-width: 1700px) {
  .col1_1700 {
    width: 8.33333%; }
  .col2_1700 {
    width: 16.66667%; }
  .col3_1700 {
    width: 25%; }
  .col4_1700 {
    width: 33.33333%; }
  .col5_1700 {
    width: 41.66667%; }
  .col6_1700 {
    width: 50%; }
  .col7_1700 {
    width: 58.33333%; }
  .col8_1700 {
    width: 66.66667%; }
  .col9_1700 {
    width: 75%; }
  .col10_1700 {
    width: 83.33333%; }
  .col11_1700 {
    width: 91.66667%; }
  .col12_1700 {
    width: 100%; } }

@media screen and (max-width: 1600px) {
  .col1_1600 {
    width: 8.33333%; }
  .col2_1600 {
    width: 16.66667%; }
  .col3_1600 {
    width: 25%; }
  .col4_1600 {
    width: 33.33333%; }
  .col5_1600 {
    width: 41.66667%; }
  .col6_1600 {
    width: 50%; }
  .col7_1600 {
    width: 58.33333%; }
  .col8_1600 {
    width: 66.66667%; }
  .col9_1600 {
    width: 75%; }
  .col10_1600 {
    width: 83.33333%; }
  .col11_1600 {
    width: 91.66667%; }
  .col12_1600 {
    width: 100%; } }

@media screen and (max-width: 1500px) {
  .col1_1500 {
    width: 8.33333%; }
  .col2_1500 {
    width: 16.66667%; }
  .col3_1500 {
    width: 25%; }
  .col4_1500 {
    width: 33.33333%; }
  .col5_1500 {
    width: 41.66667%; }
  .col6_1500 {
    width: 50%; }
  .col7_1500 {
    width: 58.33333%; }
  .col8_1500 {
    width: 66.66667%; }
  .col9_1500 {
    width: 75%; }
  .col10_1500 {
    width: 83.33333%; }
  .col11_1500 {
    width: 91.66667%; }
  .col12_1500 {
    width: 100%; } }

@media screen and (max-width: 1400px) {
  .col1_1400 {
    width: 8.33333%; }
  .col2_1400 {
    width: 16.66667%; }
  .col3_1400 {
    width: 25%; }
  .col4_1400 {
    width: 33.33333%; }
  .col5_1400 {
    width: 41.66667%; }
  .col6_1400 {
    width: 50%; }
  .col7_1400 {
    width: 58.33333%; }
  .col8_1400 {
    width: 66.66667%; }
  .col9_1400 {
    width: 75%; }
  .col10_1400 {
    width: 83.33333%; }
  .col11_1400 {
    width: 91.66667%; }
  .col12_1400 {
    width: 100%; } }

@media screen and (max-width: 1300px) {
  .col1_1300 {
    width: 8.33333%; }
  .col2_1300 {
    width: 16.66667%; }
  .col3_1300 {
    width: 25%; }
  .col4_1300 {
    width: 33.33333%; }
  .col5_1300 {
    width: 41.66667%; }
  .col6_1300 {
    width: 50%; }
  .col7_1300 {
    width: 58.33333%; }
  .col8_1300 {
    width: 66.66667%; }
  .col9_1300 {
    width: 75%; }
  .col10_1300 {
    width: 83.33333%; }
  .col11_1300 {
    width: 91.66667%; }
  .col12_1300 {
    width: 100%; } }

@media screen and (max-width: 1200px) {
  .col1_1200 {
    width: 8.33333%; }
  .col2_1200 {
    width: 16.66667%; }
  .col3_1200 {
    width: 25%; }
  .col4_1200 {
    width: 33.33333%; }
  .col5_1200 {
    width: 41.66667%; }
  .col6_1200 {
    width: 50%; }
  .col7_1200 {
    width: 58.33333%; }
  .col8_1200 {
    width: 66.66667%; }
  .col9_1200 {
    width: 75%; }
  .col10_1200 {
    width: 83.33333%; }
  .col11_1200 {
    width: 91.66667%; }
  .col12_1200 {
    width: 100%; } }

@media screen and (max-width: 1100px) {
  .col1_1100 {
    width: 8.33333%; }
  .col2_1100 {
    width: 16.66667%; }
  .col3_1100 {
    width: 25%; }
  .col4_1100 {
    width: 33.33333%; }
  .col5_1100 {
    width: 41.66667%; }
  .col6_1100 {
    width: 50%; }
  .col7_1100 {
    width: 58.33333%; }
  .col8_1100 {
    width: 66.66667%; }
  .col9_1100 {
    width: 75%; }
  .col10_1100 {
    width: 83.33333%; }
  .col11_1100 {
    width: 91.66667%; }
  .col12_1100 {
    width: 100%; } }

@media screen and (max-width: 1024px) {
  .col1_1024 {
    width: 8.33333%; }
  .col2_1024 {
    width: 16.66667%; }
  .col3_1024 {
    width: 25%; }
  .col4_1024 {
    width: 33.33333%; }
  .col5_1024 {
    width: 41.66667%; }
  .col6_1024 {
    width: 50%; }
  .col7_1024 {
    width: 58.33333%; }
  .col8_1024 {
    width: 66.66667%; }
  .col9_1024 {
    width: 75%; }
  .col10_1024 {
    width: 83.33333%; }
  .col11_1024 {
    width: 91.66667%; }
  .col12_1024 {
    width: 100%; } }

@media screen and (max-width: 900px) {
  .col1_900 {
    width: 8.33333%; }
  .col2_900 {
    width: 16.66667%; }
  .col3_900 {
    width: 25%; }
  .col4_900 {
    width: 33.33333%; }
  .col5_900 {
    width: 41.66667%; }
  .col6_900 {
    width: 50%; }
  .col7_900 {
    width: 58.33333%; }
  .col8_900 {
    width: 66.66667%; }
  .col9_900 {
    width: 75%; }
  .col10_900 {
    width: 83.33333%; }
  .col11_900 {
    width: 91.66667%; }
  .col12_900 {
    width: 100%; } }

@media screen and (max-width: 768px) {
  .col1_768 {
    width: 8.33333%; }
  .col2_768 {
    width: 16.66667%; }
  .col3_768 {
    width: 25%; }
  .col4_768 {
    width: 33.33333%; }
  .col5_768 {
    width: 41.66667%; }
  .col6_768 {
    width: 50%; }
  .col7_768 {
    width: 58.33333%; }
  .col8_768 {
    width: 66.66667%; }
  .col9_768 {
    width: 75%; }
  .col10_768 {
    width: 83.33333%; }
  .col11_768 {
    width: 91.66667%; }
  .col12_768 {
    width: 100%; } }

@media screen and (max-width: 700px) {
  .col1_700 {
    width: 8.33333%; }
  .col2_700 {
    width: 16.66667%; }
  .col3_700 {
    width: 25%; }
  .col4_700 {
    width: 33.33333%; }
  .col5_700 {
    width: 41.66667%; }
  .col6_700 {
    width: 50%; }
  .col7_700 {
    width: 58.33333%; }
  .col8_700 {
    width: 66.66667%; }
  .col9_700 {
    width: 75%; }
  .col10_700 {
    width: 83.33333%; }
  .col11_700 {
    width: 91.66667%; }
  .col12_700 {
    width: 100%; } }

@media screen and (max-width: 600px) {
  .col1_600 {
    width: 8.33333%; }
  .col2_600 {
    width: 16.66667%; }
  .col3_600 {
    width: 25%; }
  .col4_600 {
    width: 33.33333%; }
  .col5_600 {
    width: 41.66667%; }
  .col6_600 {
    width: 50%; }
  .col7_600 {
    width: 58.33333%; }
  .col8_600 {
    width: 66.66667%; }
  .col9_600 {
    width: 75%; }
  .col10_600 {
    width: 83.33333%; }
  .col11_600 {
    width: 91.66667%; }
  .col12_600 {
    width: 100%; } }

@media screen and (max-width: 500px) {
  .col1_500 {
    width: 8.33333%; }
  .col2_500 {
    width: 16.66667%; }
  .col3_500 {
    width: 25%; }
  .col4_500 {
    width: 33.33333%; }
  .col5_500 {
    width: 41.66667%; }
  .col6_500 {
    width: 50%; }
  .col7_500 {
    width: 58.33333%; }
  .col8_500 {
    width: 66.66667%; }
  .col9_500 {
    width: 75%; }
  .col10_500 {
    width: 83.33333%; }
  .col11_500 {
    width: 91.66667%; }
  .col12_500 {
    width: 100%; } }

@media screen and (max-width: 425px) {
  .col1_425 {
    width: 8.33333%; }
  .col2_425 {
    width: 16.66667%; }
  .col3_425 {
    width: 25%; }
  .col4_425 {
    width: 33.33333%; }
  .col5_425 {
    width: 41.66667%; }
  .col6_425 {
    width: 50%; }
  .col7_425 {
    width: 58.33333%; }
  .col8_425 {
    width: 66.66667%; }
  .col9_425 {
    width: 75%; }
  .col10_425 {
    width: 83.33333%; }
  .col11_425 {
    width: 91.66667%; }
  .col12_425 {
    width: 100%; } }

@media screen and (max-width: 375px) {
  .col1_375 {
    width: 8.33333%; }
  .col2_375 {
    width: 16.66667%; }
  .col3_375 {
    width: 25%; }
  .col4_375 {
    width: 33.33333%; }
  .col5_375 {
    width: 41.66667%; }
  .col6_375 {
    width: 50%; }
  .col7_375 {
    width: 58.33333%; }
  .col8_375 {
    width: 66.66667%; }
  .col9_375 {
    width: 75%; }
  .col10_375 {
    width: 83.33333%; }
  .col11_375 {
    width: 91.66667%; }
  .col12_375 {
    width: 100%; } }

@media screen and (max-width: 320px) {
  .col1_320 {
    width: 8.33333%; }
  .col2_320 {
    width: 16.66667%; }
  .col3_320 {
    width: 25%; }
  .col4_320 {
    width: 33.33333%; }
  .col5_320 {
    width: 41.66667%; }
  .col6_320 {
    width: 50%; }
  .col7_320 {
    width: 58.33333%; }
  .col8_320 {
    width: 66.66667%; }
  .col9_320 {
    width: 75%; }
  .col10_320 {
    width: 83.33333%; }
  .col11_320 {
    width: 91.66667%; }
  .col12_320 {
    width: 100%; } }

@font-face {
  font-family: 'Aktiv Grotesk';
  src: url("../fonts/aktivgrotesk-bold.woff2") format("woff2"), url("../fonts/aktivgrotesk-bold.woff") format("woff");
  font-weight: bold;
  font-style: normal; }

@font-face {
  font-family: 'Aktiv Grotesk';
  src: url("../fonts/aktivgrotesk-medium.woff2") format("woff2"), url("../fonts/aktivgrotesk-medium.woff") format("woff");
  font-weight: 500;
  font-style: normal; }

@font-face {
  font-family: 'Aktiv Grotesk';
  src: url("../fonts/aktivgrotesk-regular.woff2") format("woff2"), url("../fonts/aktivgrotesk-regular.woff") format("woff");
  font-weight: normal;
  font-style: normal; }

/*
|--------------------------------------------------------------------------
| PADDING X
|--------------------------------------------------------------------------
*/
:root {
  --x-padding-180: 180px;
  --x-padding-120: 120px;
  --x-padding-80: 80px;
  --x-padding-50: 50px;
  --x-padding-40: 40px; }
  @media screen and (min-width: 1801px) {
    :root {
      --x-padding-180: 15vw;
      --x-padding-120: 12vw; } }
  @media screen and (max-width: 1400px) {
    :root {
      --x-padding-180: 140px;
      --x-padding-120: 110px; } }
  @media screen and (max-width: 1200px) {
    :root {
      --x-padding-180: 100px;
      --x-padding-120: 100px; } }
  @media screen and (max-width: 1024px) {
    :root {
      --x-padding-180: 50px;
      --x-padding-120: 50px;
      --x-padding-80: 50px; } }
  @media screen and (max-width: 768px) {
    :root {
      --x-padding-180: 30px;
      --x-padding-120: 30px;
      --x-padding-80: 30px;
      --x-padding-50: 30px;
      --x-padding-40: 30px; } }
  @media screen and (max-width: 600px) {
    :root {
      --x-padding-180: 25px;
      --x-padding-120: 25px;
      --x-padding-80: 25px;
      --x-padding-50: 25px;
      --x-padding-40: 25px; } }
  @media screen and (max-width: 320px) {
    :root {
      --x-padding-50: 20px; } }

.right-padding-180, .x-padding-180, .main__content, .main__rubrics, .main__documents, .page-top:not(.page-top--with-picture):not(.page-top--wide) .page-top__title, .component--form, .jobs-list__container, .installation, .newsletter-subscription {
  padding-right: var(--x-padding-180); }

.left-padding-180, .x-padding-180, .main__content, .main__rubrics, .main__documents, .page-top:not(.page-top--with-picture):not(.page-top--wide) .page-top__title, .component--form, .jobs-list__container, .installation, .newsletter-subscription {
  padding-left: var(--x-padding-180); }

.right-padding-120, .x-padding-120, .home-banner__text-slider, .home-products, .home-mission > div, .similar-news, .similar-products, .showcase__content, .footer__top, .footer__lang, .buy-online, .contact-form, .jobs-form, .home-products__content-left {
  padding-right: var(--x-padding-120); }

.left-padding-120, .x-padding-120, .home-banner__text-slider, .home-products, .home-mission > div, .similar-news, .similar-products, .showcase__content, .footer__top, .footer__lang, .buy-online, .contact-form, .jobs-form {
  padding-left: var(--x-padding-120); }

.right-padding-80, .x-padding-80, .news-list, .page-top .breadcrumbs, .page-top__picture, .page-top--with-picture .page-top__title, .page-top--wide .page-top__title, .page-top-2-cols__content, .page-top-2-cols__content .breadcrumbs, .products-list, .footer__copyright, .banner-contact .breadcrumbs, .banner-contact__container, .become-a-member-form, .search-results, .online-sales__container {
  padding-right: var(--x-padding-80); }

.left-padding-80, .x-padding-80, .news-list, .page-top .breadcrumbs, .page-top__picture, .page-top--with-picture .page-top__title, .page-top--wide .page-top__title, .page-top-2-cols__content, .page-top-2-cols__content .breadcrumbs, .products-list, .footer__copyright, .banner-contact .breadcrumbs, .banner-contact__container, .become-a-member-form, .search-results, .online-sales__container, .buy-online__content {
  padding-left: var(--x-padding-80); }

.right-padding-50, .x-padding-50, .overlay-dropdown__sub-section, .overlay-search__form {
  padding-right: var(--x-padding-50); }

.left-padding-50, .x-padding-50, .overlay-dropdown__sub-section, .overlay-search__form, .header {
  padding-left: var(--x-padding-50); }

.right-padding-40, .x-padding-40 {
  padding-right: var(--x-padding-40); }

.left-padding-40, .x-padding-40 {
  padding-left: var(--x-padding-40); }

/*
|--------------------------------------------------------------------------
| PADDING Y
|--------------------------------------------------------------------------
*/
:root {
  --y-padding-190: 190px;
  --y-padding-170: 170px;
  --y-padding-160: 160px;
  --y-padding-150: 150px;
  --y-padding-130: 130px;
  --y-padding-100: 100px;
  --y-padding-90: 90px;
  --y-padding-80: 80px;
  --y-padding-70: 70px;
  --y-padding-60: 60px;
  --y-padding-50: 50px;
  --y-padding-40: 40px;
  --y-padding-30: 30px; }
  @media screen and (max-width: 1400px) {
    :root {
      --y-padding-190: 160px;
      --y-padding-170: 150px;
      --y-padding-160: 140px;
      --y-padding-150: 130px;
      --y-padding-130: 110px; } }
  @media screen and (max-width: 1200px) {
    :root {
      --y-padding-190: 140px;
      --y-padding-170: 150px;
      --y-padding-160: 130px;
      --y-padding-150: 120px;
      --y-padding-130: 110px; } }
  @media screen and (max-width: 1024px) {
    :root {
      --y-padding-190: 120px;
      --y-padding-170: 110px;
      --y-padding-160: 110px;
      --y-padding-150: 110px;
      --y-padding-130: 100px;
      --y-padding-100: 90px;
      --y-padding-90:  80px;
      --y-padding-80:  70px;
      --y-padding-60:  50px;
      --y-padding-50:  40px; } }
  @media screen and (max-width: 768px) {
    :root {
      --y-padding-190: 100px;
      --y-padding-170: 90px;
      --y-padding-160: 90px;
      --y-padding-150: 90px;
      --y-padding-130: 80px;
      --y-padding-100: 80px;
      --y-padding-90:  70px;
      --y-padding-80:  60px;
      --y-padding-70:  50px;
      --y-padding-60:  40px;
      --y-padding-50:  30px;
      --y-padding-40:  30px; } }
  @media screen and (max-width: 500px) {
    :root {
      --y-padding-190: 50px;
      --y-padding-170: 50px;
      --y-padding-160: 50px;
      --y-padding-150: 50px;
      --y-padding-130: 50px;
      --y-padding-100: 50px;
      --y-padding-90:  50px;
      --y-padding-80:  50px;
      --y-padding-70:  40px; } }

.top-padding-190, .y-padding-190 {
  padding-top: var(--y-padding-190); }

.bottom-padding-190, .y-padding-190, .online-sales__container {
  padding-bottom: var(--y-padding-190); }

.top-padding-170, .y-padding-170 {
  padding-top: var(--y-padding-170); }

.bottom-padding-170, .y-padding-170, .jobs-form {
  padding-bottom: var(--y-padding-170); }

.top-padding-160, .y-padding-160, .similar-news, .component--buy-online, .jobs-form, .generic-slider {
  padding-top: var(--y-padding-160); }

.bottom-padding-160, .y-padding-160, .similar-news, .component--buy-online, .newsletter-subscription {
  padding-bottom: var(--y-padding-160); }

.top-padding-150, .y-padding-150 {
  padding-top: var(--y-padding-150); }

.bottom-padding-150, .y-padding-150, .banner-contact__content, .contact-form {
  padding-bottom: var(--y-padding-150); }

.top-padding-130, .y-padding-130, .home-mission > div, .component--form, .component--similar-products, .buy-online__content, .become-a-member-form, .home-products, .home-products__picture, .newsletter-subscription, .contact-form {
  padding-top: var(--y-padding-130); }

.bottom-padding-130, .y-padding-130, .home-mission > div, .component--form, .component--similar-products, .buy-online__content, .become-a-member-form, .main__rubrics, .main__documents, .footer__top {
  padding-bottom: var(--y-padding-130); }

.top-padding-100, .y-padding-100, .main__content, .jobs-list__container, .installation, .news-list__pagination, .products-list__pagination, .footer__top, .banner-contact__content {
  padding-top: var(--y-padding-100); }

.bottom-padding-100, .y-padding-100, .main__content, .jobs-list__container, .installation, .similar-products__title, .news-list, .products-list, .contact-form__title--job, .jobs-form__title, .search-results {
  padding-bottom: var(--y-padding-100); }

.top-padding-90, .y-padding-90, .overlay-dropdown__sub-section, .main__rubrics, .main__documents {
  padding-top: var(--y-padding-90); }

.bottom-padding-90, .y-padding-90, .overlay-dropdown__sub-section {
  padding-bottom: var(--y-padding-90); }

.top-padding-80, .y-padding-80, .news-nav, .newsletter-subscription__form {
  padding-top: var(--y-padding-80); }

.bottom-padding-80, .y-padding-80, .home-products__title, .page-top__title, .footer__logo, .become-a-member-form__title {
  padding-bottom: var(--y-padding-80); }

.top-padding-70, .y-padding-70, .home-products__button, .news-list, .products-list, .buy-online__form {
  padding-top: var(--y-padding-70); }

.bottom-padding-70, .y-padding-70, .home-mission__title {
  padding-bottom: var(--y-padding-70); }

.top-padding-60, .y-padding-60, .overlay-search__form, .similar-news__text-button, .page-top__search {
  padding-top: var(--y-padding-60); }

.bottom-padding-60, .y-padding-60, .overlay-search__form, .rubric__share, .contact-form__title {
  padding-bottom: var(--y-padding-60); }

.top-padding-50, .y-padding-50, .similar-products__all-products, .page-top__title, .page-top-2-cols__button, .page-top-2-cols .tag-list, .overlay-dropdown__btn {
  padding-top: var(--y-padding-50); }

.bottom-padding-50, .y-padding-50, .main__rubrics h2, .main__documents h2, .main__documents-desc, .main__components h2, .news-list__count, .products-list__count {
  padding-bottom: var(--y-padding-50); }

.top-padding-40, .y-padding-40, .home-products__title, .home-mission__title, .buy-online__form-button {
  padding-top: var(--y-padding-40); }

.bottom-padding-40, .y-padding-40, .main__content__top__intro {
  padding-bottom: var(--y-padding-40); }

.top-padding-30, .y-padding-30, .similar-products__title, .news-thumb__content, .footer__button, .buy-online__title {
  padding-top: var(--y-padding-30); }

.bottom-padding-30, .y-padding-30, .page-top__over-title, .page-top__search, .page-top-2-cols__over-title, .page-top-2-cols__date-and-categ, .contact-form__sub-title {
  padding-bottom: var(--y-padding-30); }

/*
|--------------------------------------------------------------------------
| MARGIN Y
|--------------------------------------------------------------------------
*/
:root {
  --y-margin-60: 60px; }
  @media screen and (max-width: 1024px) {
    :root {
      --y-margin-60:  40px; } }
  @media screen and (max-width: 768px) {
    :root {
      --y-margin-60:  30px; } }

.top-margin-60, .y-margin-60, .rubric__document, .rubric__gallery, .rubric__share {
  margin-top: var(--y-margin-60); }

.bottom-margin-60, .y-margin-60 {
  margin-bottom: var(--y-margin-60); }

/*
|--------------------------------------------------------------------------
| CONTENT
|--------------------------------------------------------------------------
*/
body {
  font-family: "Aktiv Grotesk", sans-serif; }

a {
  display: inline-block;
  text-decoration: none;
  cursor: pointer; }

/*
|--------------------------------------------------------------------------
| IMAGES
|--------------------------------------------------------------------------
*/
picture {
  display: block;
  font-size: 0; }
  picture img {
    width: 100%;
    height: 100%; }
  picture [data-object-fit='cover'] {
    object-fit: cover;
    font-family: 'object-fit: cover;'; }
  picture [data-object-fit='contain'] {
    object-fit: contain;
    font-family: 'object-fit: contain;'; }

:root {
  --pattern-img: url('/themes/core/assets/medias/images/content/general/grid-pattern.png'); }

.dark-bg {
  --pattern-img: url('/themes/core/assets/medias/images/content/general/grid-pattern-dark-bg.png'); }

.grid-pattern {
  --pattern-width-large: 820px;
  --pattern-width-normal: 280px;
  --pattern-width-smaller: 150px;
  pointer-events: none;
  position: absolute;
  background-image: var(--pattern-img);
  opacity: 0.25;
  z-index: 2; }
  @media screen and (max-width: 1200px) {
    .grid-pattern {
      --pattern-width-large: 520px;
      --pattern-width-normal: 230px; } }
  @media screen and (max-width: 1024px) {
    .grid-pattern {
      --pattern-width-large: 425px;
      --pattern-width-normal: 150px;
      --pattern-width-smaller: 100px; } }
  @media screen and (max-width: 768px) {
    .grid-pattern {
      --pattern-width-large: 323px;
      --pattern-width-normal: 100px;
      --pattern-width-smaller: 75px; } }
  @media screen and (max-width: 500px) {
    .grid-pattern {
      --pattern-width-large: 220px;
      --pattern-width-normal: 50px;
      --pattern-width-smaller: 50px; } }
  .grid-pattern--top-right-horizontal {
    top: 0;
    right: 0;
    height: 70px;
    width: 371px;
    background-position: right top; }
    @media screen and (max-width: 900px) {
      .grid-pattern--top-right-horizontal {
        height: 50px; } }
    @media screen and (max-width: 500px) {
      .grid-pattern--top-right-horizontal {
        height: 30px; } }
  .grid-pattern--bottom-left-vertical {
    bottom: 0;
    left: 0;
    height: 870px;
    width: var(--pattern-width-normal);
    background-position: 0px 1px; }
  .grid-pattern--right-horizontal {
    top: 80px;
    right: 0;
    bottom: 0;
    width: var(--pattern-width-smaller);
    background-position: 1px 1px; }

.table-wrapper::-webkit-scrollbar,
.scroll-style::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 5px;
  height: 5px;
  background-color: white; }

.table-wrapper::-webkit-scrollbar-thumb,
.scroll-style::-webkit-scrollbar-thumb {
  background-color: #999999; }

.huge-title, .home-products__title, .home-mission__title, .similar-news__text-title, .similar-products__title, .page-top__title h1, .page-top-2-cols__title h1, .installation__title, .newsletter-subscription__title, .banner-contact__title, .contact-form__title, .jobs-form__title, .become-a-member-form__title, .online-sales__title {
  font-weight: 700;
  line-height: 1.2;
  color: #202020;
  font-size: 5rem; }
  @media screen and (max-width: 1024px) {
    .huge-title, .home-products__title, .home-mission__title, .similar-news__text-title, .similar-products__title, .page-top__title h1, .page-top-2-cols__title h1, .installation__title, .newsletter-subscription__title, .banner-contact__title, .contact-form__title, .jobs-form__title, .become-a-member-form__title, .online-sales__title {
      font-size: 3.75rem; } }
  @media screen and (max-width: 768px) {
    .huge-title, .home-products__title, .home-mission__title, .similar-news__text-title, .similar-products__title, .page-top__title h1, .page-top-2-cols__title h1, .installation__title, .newsletter-subscription__title, .banner-contact__title, .contact-form__title, .jobs-form__title, .become-a-member-form__title, .online-sales__title {
      font-size: 3.125rem; } }
  @media screen and (max-width: 425px) {
    .huge-title, .home-products__title, .home-mission__title, .similar-news__text-title, .similar-products__title, .page-top__title h1, .page-top-2-cols__title h1, .installation__title, .newsletter-subscription__title, .banner-contact__title, .contact-form__title, .jobs-form__title, .become-a-member-form__title, .online-sales__title {
      font-size: 2.5rem; } }
  .huge-title span, .home-products__title span, .home-mission__title span, .similar-news__text-title span, .similar-products__title span, .page-top__title h1 span, .page-top-2-cols__title h1 span, .installation__title span, .newsletter-subscription__title span, .banner-contact__title span, .contact-form__title span, .jobs-form__title span, .become-a-member-form__title span, .online-sales__title span {
    color: #00c281; }

.large-title, .main__rubrics h2, .main__documents h2, .main__components h2, .home-banner__text-slide-title, .dynamic h2, .form__title--contact, .contact-form__sub-title, .about-us__title, .online-sales__subtitle, .overlay-dropdown__sub-section-title, .overlay-share__title {
  font-weight: 700;
  line-height: 1.2;
  color: #202020;
  font-size: 2.1875rem; }
  @media screen and (max-width: 1024px) {
    .large-title, .main__rubrics h2, .main__documents h2, .main__components h2, .home-banner__text-slide-title, .dynamic h2, .form__title--contact, .contact-form__sub-title, .about-us__title, .online-sales__subtitle, .overlay-dropdown__sub-section-title, .overlay-share__title {
      font-size: 2rem; } }
  @media screen and (max-width: 768px) {
    .large-title, .main__rubrics h2, .main__documents h2, .main__components h2, .home-banner__text-slide-title, .dynamic h2, .form__title--contact, .contact-form__sub-title, .about-us__title, .online-sales__subtitle, .overlay-dropdown__sub-section-title, .overlay-share__title {
      font-size: 1.875rem; } }
  @media screen and (max-width: 425px) {
    .large-title, .main__rubrics h2, .main__documents h2, .main__components h2, .home-banner__text-slide-title, .dynamic h2, .form__title--contact, .contact-form__sub-title, .about-us__title, .online-sales__subtitle, .overlay-dropdown__sub-section-title, .overlay-share__title {
      font-size: 1.625rem; } }

.large-title-smaller-mobile, .rubric__title, .documents .document__title, .links__title {
  font-weight: 700;
  line-height: 1.2;
  color: #202020;
  font-size: 2.1875rem; }
  @media screen and (max-width: 1024px) {
    .large-title-smaller-mobile, .rubric__title, .documents .document__title, .links__title {
      font-size: 2rem; } }
  @media screen and (max-width: 768px) {
    .large-title-smaller-mobile, .rubric__title, .documents .document__title, .links__title {
      font-size: 1.375rem; } }

.medium-title, .news-thumb__title, .page-top-2-cols__over-title, .page-top-2-cols__date-and-categ, .dynamic h3, .form__title {
  font-weight: 700;
  line-height: 1.2;
  color: #202020;
  font-size: 1.375rem; }

.small-title, .dynamic h4 {
  font-weight: 700;
  line-height: 1.2;
  color: #202020;
  font-size: 1.125rem; }
  @media screen and (max-width: 1024px) {
    .small-title, .dynamic h4 {
      font-size: 1.0625rem; } }
  @media screen and (max-width: 768px) {
    .small-title, .dynamic h4 {
      font-size: 1rem; } }

.paragraph, .dynamic p, .dynamic > ol li, .dynamic > ul li, .about-us__paragraph {
  line-height: 1.55555em;
  color: #202020;
  font-size: 1.125rem; }
  @media screen and (max-width: 1024px) {
    .paragraph, .dynamic p, .dynamic > ol li, .dynamic > ul li, .about-us__paragraph {
      font-size: 1.0625rem; } }
  @media screen and (max-width: 768px) {
    .paragraph, .dynamic p, .dynamic > ol li, .dynamic > ul li, .about-us__paragraph {
      font-size: 1rem; } }
  @media screen and (max-width: 375px) {
    .paragraph, .dynamic p, .dynamic > ol li, .dynamic > ul li, .about-us__paragraph {
      font-size: 0.9375rem; } }

.over-title, .home-products__over-title, .home-mission__over-title, .similar-products__over-title, .buy-online__over-title {
  font-weight: bold;
  color: #202020;
  opacity: 0.4;
  font-size: 1.5625rem;
  padding-left: 22px;
  position: relative; }
  @media screen and (max-width: 425px) {
    .over-title, .home-products__over-title, .home-mission__over-title, .similar-products__over-title, .buy-online__over-title {
      font-size: 1.375rem; } }
  .over-title::before, .home-products__over-title::before, .home-mission__over-title::before, .similar-products__over-title::before, .buy-online__over-title::before {
    content: '';
    position: absolute;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    top: 50%;
    left: 0;
    transform: translate(0, -50%);
    background-color: #202020; }

.minuscule-title, .rubrics.rubrics--plus .rubric__subtitle, .documents.documents--plus .document__subtitle, .documents-in-rubric.documents-in-rubric--plus .document__subtitle {
  font-weight: 500;
  font-size: 0.8125rem;
  line-height: 1.5em;
  color: #00c281;
  text-transform: uppercase;
  letter-spacing: 1px; }
  @media screen and (max-width: 425px) {
    .minuscule-title, .rubrics.rubrics--plus .rubric__subtitle, .documents.documents--plus .document__subtitle, .documents-in-rubric.documents-in-rubric--plus .document__subtitle {
      font-size: 0.75rem; } }

.news-title, .page-top--news-details .page-top__title h1 {
  font-weight: 700;
  line-height: 1.2;
  color: #202020;
  font-size: 3.125rem; }
  @media screen and (max-width: 1024px) {
    .news-title, .page-top--news-details .page-top__title h1 {
      font-size: 2.5rem; } }
  @media screen and (max-width: 425px) {
    .news-title, .page-top--news-details .page-top__title h1 {
      font-size: 1.875rem; } }

.jobs-title {
  font-weight: normal;
  line-height: 1.2;
  color: #202020;
  font-size: 5rem; }
  @media screen and (max-width: 1024px) {
    .jobs-title {
      font-size: 3.75rem; } }
  @media screen and (max-width: 768px) {
    .jobs-title {
      font-size: 3.125rem; } }
  @media screen and (max-width: 425px) {
    .jobs-title {
      font-size: 2.5rem; } }

.dark-bg .huge-title, .dark-bg .home-products__title, .dark-bg .home-mission__title, .dark-bg .similar-news__text-title, .dark-bg .similar-products__title, .dark-bg .page-top__title h1, .page-top__title .dark-bg h1, .dark-bg .page-top-2-cols__title h1, .page-top-2-cols__title .dark-bg h1, .dark-bg .installation__title, .dark-bg .newsletter-subscription__title, .dark-bg .banner-contact__title, .dark-bg .contact-form__title, .dark-bg .jobs-form__title, .dark-bg .become-a-member-form__title, .dark-bg .online-sales__title,
.dark-bg .large-title,
.dark-bg .main__rubrics h2,
.main__rubrics .dark-bg h2,
.dark-bg .main__documents h2,
.main__documents .dark-bg h2,
.dark-bg .main__components h2,
.main__components .dark-bg h2,
.dark-bg .home-banner__text-slide-title,
.dark-bg .dynamic h2,
.dynamic .dark-bg h2,
.dark-bg .form__title--contact,
.dark-bg .contact-form__sub-title,
.dark-bg .about-us__title,
.dark-bg .online-sales__subtitle,
.dark-bg .overlay-dropdown__sub-section-title,
.dark-bg .overlay-share__title,
.dark-bg .medium-title,
.dark-bg .news-thumb__title,
.dark-bg .page-top-2-cols__over-title,
.dark-bg .page-top-2-cols__date-and-categ,
.dark-bg .dynamic h3,
.dynamic .dark-bg h3,
.dark-bg .form__title,
.dark-bg .small-title,
.dark-bg .dynamic h4,
.dynamic .dark-bg h4,
.dark-bg .paragraph,
.dark-bg .dynamic p,
.dynamic .dark-bg p,
.dark-bg .dynamic > ol li,
.dynamic > ol .dark-bg li,
.dark-bg .dynamic > ul li,
.dynamic > ul .dark-bg li,
.dark-bg .about-us__paragraph,
.dark-bg .over-title,
.dark-bg .home-products__over-title,
.dark-bg .home-mission__over-title,
.dark-bg .similar-products__over-title,
.dark-bg .buy-online__over-title {
  color: white; }

.dark-bg .over-title::before, .dark-bg .home-products__over-title::before, .dark-bg .home-mission__over-title::before, .dark-bg .similar-products__over-title::before, .dark-bg .buy-online__over-title::before {
  background-color: white; }

@media print {
  * {
    color: black !important; }
  .header,
  .breadcrumbs,
  .showcase,
  .footer,
  .grid-pattern,
  .main__components,
  .page-top-2-cols__rapid-access,
  .page-top-2-cols__print-btn,
  .slick-dots,
  .slick-arrows,
  .generic-slider,
  .filter-list,
  .pagination,
  .news-nav,
  .similar-news,
  .back-to-top {
    display: none !important; }
  .page-top__title,
  .page-top__picture,
  .main__content,
  .main__rubrics,
  .page-top-2-cols__content,
  .products-list,
  .banner-contact__container,
  .contact-form,
  .jobs-list__container,
  .jobs-form,
  .online-sales__container {
    padding-left: 0 !important;
    padding-right: 0 !important; }
  .page-top__title {
    padding-bottom: 0 !important; }
  .main__content {
    background-color: white !important;
    padding-bottom: 0; }
  .page-top-2-cols {
    flex-wrap: nowrap !important;
    flex-direction: column !important;
    height: 100vh !important; }
    .page-top-2-cols__wrapper {
      width: 100%;
      overflow: hidden; }
    .page-top-2-cols__content {
      width: 100% !important;
      height: auto !important;
      padding-bottom: 80px !important; }
    .page-top-2-cols__over-title-print-logo {
      display: block !important; }
    .page-top-2-cols__picture {
      width: 100% !important;
      height: auto !important;
      flex-grow: 1 !important;
      padding-bottom: 0 !important;
      overflow: hidden !important; }
    .page-top-2-cols .slick-list,
    .page-top-2-cols .slick-track,
    .page-top-2-cols .slick-slide {
      width: 100% !important; }
    .page-top-2-cols .slick-list .slick-track .slick-slide {
      opacity: 1 !important; }
      .page-top-2-cols .slick-list .slick-track .slick-slide:not(:first-child) {
        display: none !important; }
  .main__content__print__footer {
    margin-top: 80px;
    display: flex !important;
    justify-content: space-between;
    font-size: 13px; }
    .main__content__print__footer svg {
      width: 200px;
      height: 40px; }
    .main__content__print__footer__info {
      display: flex;
      align-items: center;
      flex-wrap: wrap; }
      .main__content__print__footer__info__wrapper div:first-child {
        margin-bottom: 10px; }
      .main__content__print__footer__info__wrapper span {
        margin-left: 30px; }
  .rubric {
    margin-left: 0;
    margin-right: 0; }
    .rubric__header, .rubric__content {
      margin-left: 0;
      margin-right: 0; }
    .rubric__content {
      height: auto !important; }
    .rubric__transition {
      opacity: 1 !important;
      transform: none !important; }
  /*.products-list__items .column{

    }*/ }

.header {
  display: flex;
  align-items: center;
  box-shadow: 0px 0px 20px rgba(153, 153, 153, 0.2);
  z-index: 1;
  position: relative; }
  .header__logo {
    margin-right: auto; }
    .header__logo a {
      margin-right: 20px; }
      @media screen and (max-width: 768px) {
        .header__logo a {
          margin-right: 0; } }
      .header__logo a svg {
        width: 100%;
        max-width: 330px;
        height: calc(var(--header-height) - 4px); }
        @media screen and (max-width: 768px) {
          .header__logo a svg {
            max-width: 200px; } }
  .header__nav__secondary, .header__nav__primary {
    display: flex;
    justify-content: flex-end; }
    .header__nav__secondary li a, .header__nav__primary li a {
      color: #202020; }
  @media screen and (max-width: 900px) {
    .header__nav__secondary {
      display: none; } }
  .header__nav__secondary li a {
    font-size: 0.75rem;
    padding: 15px 18px;
    opacity: 1;
    transition: opacity 0.3s cubic-bezier(0.76, 0, 0.24, 1); }
    .header__nav__secondary li a:hover {
      opacity: 0.5; }
  .header__nav__secondary li:last-child {
    position: relative; }
    .header__nav__secondary li:last-child::before {
      content: '';
      position: absolute;
      height: 12px;
      width: 2px;
      left: 0;
      top: 50%;
      background-color: #999999;
      transform: translate(0, -50%);
      pointer-events: none; }
    .header__nav__secondary li:last-child a {
      padding-left: 30px;
      padding-right: 30px; }
  .header__nav__primary {
    border-top: 1px solid rgba(32, 32, 32, 0.1); }
    @media screen and (max-width: 900px) {
      .header__nav__primary {
        border-top: 0; } }
    .header__nav__primary li:nth-last-child(2) {
      display: none; }
    .header__nav__primary li a {
      font-size: 0.8125rem;
      padding: 10px 25px;
      font-weight: 700;
      height: 100%;
      display: flex;
      align-items: center;
      transition: color 0.3s cubic-bezier(0.76, 0, 0.24, 1);
      text-transform: uppercase; }
      .header__nav__primary li a span {
        padding-right: 20px;
        position: relative; }
        .header__nav__primary li a span::after {
          content: '';
          position: absolute;
          top: 50%;
          right: 0;
          width: 0;
          height: 0;
          border-left: 5px solid transparent;
          border-right: 5px solid transparent;
          border-top: 5px solid #00c281;
          transform: translate(0, -50%);
          transition: transform 0.3s cubic-bezier(0.76, 0, 0.24, 1); }
      .header__nav__primary li a:hover {
        color: #00c281; }
      .header__nav__primary li a.no-link span {
        padding-right: 0; }
        .header__nav__primary li a.no-link span::after {
          content: normal; }
    @media screen and (max-width: 900px) {
      .header__nav__primary li:nth-last-child(2) {
        display: block; }
      .header__nav__primary li:not(:nth-last-child(2)):not(:last-child) {
        display: none; } }
    .header__nav__primary__menu-btn, .header__nav__primary__search {
      width: 82px;
      height: 82px;
      position: relative;
      display: block;
      cursor: pointer;
      transition: background-color 0.3s cubic-bezier(0.76, 0, 0.24, 1); }
    .header__nav__primary__menu-btn {
      background-color: transparent; }
      .header__nav__primary__menu-btn:hover {
        background-color: #f5f5f5; }
    .header__nav__primary__search {
      background-color: #00c281;
      margin-left: 15px; }
      @media screen and (max-width: 900px) {
        .header__nav__primary__search {
          margin-left: 0; } }
      .header__nav__primary__search svg {
        position: absolute;
        left: 50%;
        top: 50%;
        fill: white;
        width: 30px;
        height: 30px;
        transform: translate(-50%, -50%);
        opacity: 1;
        transition: opacity 0.3s cubic-bezier(0.76, 0, 0.24, 1); }
      .header__nav__primary__search > span {
        width: 21px;
        height: 21px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 1;
        opacity: 0;
        transition: opacity 0.3s cubic-bezier(0.76, 0, 0.24, 1); }
        .header__nav__primary__search > span::before, .header__nav__primary__search > span::after {
          content: '';
          width: 2px;
          height: 29px;
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%) rotate(45deg);
          background-color: white;
          z-index: 2; }
        .header__nav__primary__search > span::after {
          transform: translate(-50%, -50%) rotate(-45deg); }
      .header__nav__primary__search:hover {
        background-color: #03b177; }

:root {
  --page-content-background-color: transparent;
  --page-content-text-color: #202020; }

.dark-bg {
  --page-content-background-color: #272727;
  --page-content-text-color: white; }

.white-bg {
  --page-content-background-color: #fff; }

.main {
  background-color: #f5f5f5;
  position: relative; }
  .main__content {
    position: relative;
    background: var(--page-content-background-color);
    overflow-x: hidden; }
    .main__content__top__intro {
      font-size: 2.1875rem;
      color: var(--page-content-text-color);
      line-height: 1.4285714em; }
      @media screen and (max-width: 1024px) {
        .main__content__top__intro {
          font-size: 1.875rem; } }
      @media screen and (max-width: 768px) {
        .main__content__top__intro {
          font-size: 1.5625rem; } }
      @media screen and (max-width: 425px) {
        .main__content__top__intro {
          font-size: 1.375rem; } }
      @media screen and (max-width: 375px) {
        .main__content__top__intro {
          font-size: 1.3125rem; } }
      @media screen and (max-width: 320px) {
        .main__content__top__intro {
          font-size: 1.25rem; } }
  .main__content--jobs {
    padding-top: 0; }
  .main__rubrics, .main__documents {
    background: white; }
  .main__content__print__footer {
    display: none; }

.home-banner {
  --bottom-gap: 127px;
  --right-gap: 270px;
  --dots-gap: 75px;
  --circle-diameter: 110px;
  background-color: white;
  height: calc(100vh - var(--header-height));
  position: relative; }
  @media screen and (max-width: 1300px) {
    .home-banner {
      display: flex;
      flex-direction: column;
      --circle-diameter: 90px; } }
  @media screen and (max-width: 768px) {
    .home-banner {
      --circle-diameter: 80px; } }
  .home-banner .slick-list,
  .home-banner .slick-track,
  .home-banner .slick-slide > div {
    height: 100%; }
  .home-banner__slider-wrapper {
    height: 100%;
    position: relative;
    padding-bottom: var(--bottom-gap); }
    @media screen and (max-width: 1300px) {
      .home-banner__slider-wrapper {
        padding-bottom: 0;
        flex-grow: 1;
        height: 0; } }
    @media screen and (max-width: 800px) {
      .home-banner__slider-wrapper::after {
        content: '';
        position: absolute;
        z-index: 5;
        height: 70px;
        left: 0;
        right: 0;
        bottom: 0;
        background: black;
        background: linear-gradient(0deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0) 100%); } }
  .home-banner__picture-slider {
    height: 100%;
    background-color: #999999; }
  .home-banner__picture-slide {
    height: 100%;
    position: relative; }
    .home-banner__picture-slide picture {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0; }
  .home-banner__text-slider {
    position: absolute;
    height: calc(var(--bottom-gap) * 2);
    left: 0;
    bottom: 0;
    right: var(--right-gap);
    background-color: #202020;
    color: white; }
    @media screen and (max-width: 1300px) {
      .home-banner__text-slider {
        right: 0;
        position: relative;
        height: auto; } }
    .home-banner__text-slider > div {
      height: 100%; }
      .home-banner__text-slider > div.slick-dotted {
        margin-bottom: 0; }
    .home-banner__text-slider.slick-dotted.slick-slider {
      margin-bottom: 0; }
    .home-banner__text-slider .slick-dots {
      position: absolute;
      left: --x-padding-120;
      bottom: 50%;
      transform: translate(0, 50%); }
      @media screen and (max-width: 800px) {
        .home-banner__text-slider .slick-dots {
          z-index: 6;
          transform: none;
          bottom: calc(100% + 20px); } }
  .home-banner__text-slide {
    height: 100%;
    display: flex;
    align-items: center;
    padding-left: var(--dots-gap); }
    @media screen and (max-width: 1300px) {
      .home-banner__text-slide {
        padding-top: 45px;
        padding-bottom: 45px; } }
    @media screen and (max-width: 800px) {
      .home-banner__text-slide {
        padding-left: 0; } }
  .home-banner__text-wrapper {
    display: flex;
    align-items: center;
    height: 100%; }
  .home-banner__text-flex {
    display: flex;
    align-items: flex-end;
    width: 100%; }
    @media screen and (max-width: 1300px) {
      .home-banner__text-flex {
        flex-wrap: wrap; } }
  .home-banner__text-slide-title {
    color: white;
    padding-right: 100px;
    flex-grow: 1; }
    @media screen and (max-width: 1300px) {
      .home-banner__text-slide-title {
        width: 100%;
        padding-right: 0; } }
  .home-banner__text-slide-button {
    margin-right: 1px; }
    @media screen and (max-width: 1300px) {
      .home-banner__text-slide-button {
        padding-top: 15px; } }
    .home-banner__text-slide-button .btn {
      white-space: nowrap; }
  .home-banner__down {
    position: absolute;
    bottom: 0;
    right: calc(var(--right-gap) * -1);
    height: calc(var(--bottom-gap) * 2);
    width: var(--right-gap);
    overflow: hidden; }
    @media screen and (max-width: 1300px) {
      .home-banner__down {
        display: none;
        width: calc(var(--circle-diameter)*1.1);
        height: calc(var(--circle-diameter)*1.1);
        bottom: 200px;
        right: 40px;
        top: 0;
        transform: translate(0, -50%); } }
    @media screen and (max-width: 800px) {
      .home-banner__down {
        z-index: 6; } }
    @media screen and (max-width: 768px) {
      .home-banner__down {
        right: 20px; } }
  .home-banner__down-arrow-container {
    position: absolute;
    left: 50%;
    top: 50%;
    width: var(--circle-diameter);
    height: var(--circle-diameter);
    transform: translate(-50%, -50%);
    cursor: pointer; }
    .home-banner__down-arrow-container svg {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 28px;
      height: 40px;
      fill: white;
      z-index: 2;
      animation: float 3s ease-in-out infinite; }

@keyframes float {
  0% {
    transform: translate(-50%, -50%); }
  50% {
    transform: translate(-50%, calc(-50% + 10px)); }
  100% {
    transform: translate(-50%, -50%); } }
    .home-banner__down-arrow-container::after {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      background-color: #00c281;
      border-radius: 50%;
      transform: scale(1);
      transition: transform 0.3s cubic-bezier(0.76, 0, 0.24, 1); }
    .home-banner__down-arrow-container:hover::after {
      transform: scale(1.1); }
    .home-banner__down-arrow-container span {
      position: absolute;
      font-size: 1rem;
      color: #202020;
      font-weight: 700;
      white-space: nowrap;
      left: 50%;
      bottom: -38px;
      transform: translate(-50%, 0%); }

.home-products {
  background-color: white;
  overflow-x: hidden;
  position: relative; }
  .home-products__title span {
    display: block; }
  @media screen and (max-width: 1024px) {
    .home-products__title {
      padding-bottom: 40px; } }
  .home-products__text p {
    font-size: 1.0625rem;
    line-height: 2em; }
  @media screen and (max-width: 1024px) {
    .home-products__button {
      padding-top: 40px;
      padding-bottom: 30px; } }
  .home-products__content {
    display: flex;
    align-items: center;
    align-items: stretch; }
    @media screen and (max-width: 1024px) {
      .home-products__content {
        flex-wrap: wrap; } }
  .home-products__content-left {
    z-index: 2;
    background: #fff;
    margin-left: calc(var(--x-padding-120) * -1);
    padding-left: var(--x-padding-120);
    display: flex;
    align-items: center; }
    @media screen and (max-width: 1024px) {
      .home-products__content-left {
        width: 100%;
        padding-bottom: 70px; } }
  .home-products__content-right {
    width: 63.666666%; }
    @media screen and (max-width: 1024px) {
      .home-products__content-right {
        width: 100%;
        z-index: 3;
        position: relative; } }
  .home-products__content-right-wrapper {
    margin-right: calc(var(--x-padding-120) * -1);
    padding-right: var(--x-padding-120); }
  @media screen and (max-width: 768px) {
    .home-products__picture {
      margin-left: calc(var(--x-padding-120)* -1);
      margin-right: calc(var(--x-padding-120)* -1); } }
  .home-products__picture > div {
    position: relative;
    width: 100%;
    padding-top: 50%; }
    @media screen and (max-width: 1500px) {
      .home-products__picture > div {
        padding-top: 60%; } }
    @media screen and (max-width: 600px) {
      .home-products__picture > div {
        padding-top: 75%; } }
    .home-products__picture > div picture {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0; }
  .home-products .grid-pattern {
    top: 0;
    bottom: 0;
    left: 0;
    width: var(--pattern-width-normal); }
  .home-products__over-title, .home-products__title, .home-products__text, .home-products__button, .home-products__picture {
    z-index: 3;
    position: relative; }

.home-mission {
  background-color: white; }
  .home-mission > div {
    margin-right: calc(var(--x-padding-120)*1.8);
    margin-top: calc(var(--y-padding-160)*-1);
    background-color: #202020;
    z-index: 4;
    position: relative; }
    @media screen and (max-width: 768px) {
      .home-mission > div {
        margin-right: 0;
        margin-top: 0; } }
  .home-mission__title {
    font-size: 3.75rem; }
    @media screen and (max-width: 1024px) {
      .home-mission__title {
        font-size: 2.5rem; } }
    @media screen and (max-width: 768px) {
      .home-mission__title {
        font-size: 1.875rem; } }
    @media screen and (max-width: 425px) {
      .home-mission__title {
        font-size: 1.75rem; } }

.similar-news {
  background-color: white;
  position: relative; }
  .similar-news > div {
    display: flex;
    align-items: center;
    flex-wrap: wrap; }
  .similar-news__text {
    width: 38%; }
    @media screen and (max-width: 1400px) {
      .similar-news__text {
        width: 50%; } }
    @media screen and (max-width: 1024px) {
      .similar-news__text {
        width: 100%; } }
  .similar-news__list {
    width: 62%; }
    @media screen and (max-width: 1400px) {
      .similar-news__list {
        width: 50%; } }
    @media screen and (max-width: 1024px) {
      .similar-news__list {
        width: 100%;
        padding-top: 60px; } }
    @media screen and (max-width: 768px) {
      .similar-news__list {
        width: 100%;
        padding-top: 60px; }
        .similar-news__list .column:not(:last-child) {
          padding-bottom: 60px; } }
  .similar-news__text {
    position: relative;
    z-index: 3; }
  .similar-news .grid-pattern {
    top: 0;
    bottom: 0;
    left: 0;
    width: var(--pattern-width-normal); }

.similar-products {
  background-color: white;
  overflow: hidden; }
  .similar-products__title span {
    display: block; }
  @media screen and (max-width: 768px) {
    .similar-products__product-slider {
      padding-top: 30px; } }

.news-thumb {
  position: relative;
  z-index: 3;
  width: 100%; }
  .news-thumb__picture > div {
    width: 100%;
    padding-top: 114.7058823529412%;
    position: relative;
    overflow: hidden; }
    @media screen and (max-width: 1024px) {
      .news-thumb__picture > div {
        padding-top: 100%; } }
    .news-thumb__picture > div picture {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      transform: scale(1);
      transition: transform 0.3s; }
  .news-thumb__date {
    color: #00c281;
    font-size: 0.8125rem;
    text-transform: uppercase;
    font-weight: 500;
    margin-bottom: 15px; }
  .news-thumb:hover .news-thumb__picture > div picture {
    transform: scale(1.05); }

.news-list {
  background-color: white; }
  .news-list__count {
    font-size: 1.375rem;
    color: #202020; }
  .news-list__items {
    margin-bottom: calc(var(--grid-bigger-gaps)*-2); }
    .news-list__items .column {
      padding-bottom: calc(var(--grid-bigger-gaps)*2); }
  .news-list__item {
    width: 100%; }

.news-nav__wrapper {
  border-top: 9px solid rgba(32, 32, 32, 0.1);
  padding-top: 30px;
  display: flex;
  justify-content: space-between; }

.news-nav__prev, .news-nav__next {
  text-transform: uppercase;
  font-size: 0.8125rem;
  font-weight: 500;
  color: #202020;
  display: flex;
  align-items: center;
  padding: 0 20px; }
  .news-nav__prev span, .news-nav__next span {
    padding-right: 20px;
    display: inline-block; }
  .news-nav__prev svg, .news-nav__next svg {
    width: 19px;
    height: 26px;
    fill: #00c281;
    transform: rotate(-90deg);
    transition: transform 0.3s cubic-bezier(0.76, 0, 0.24, 1); }
  .news-nav__prev:not(span):hover svg, .news-nav__next:not(span):hover svg {
    transform: rotate(-90deg) translate(0, 14px); }

.news-nav__prev {
  flex-direction: row-reverse; }
  .news-nav__prev span {
    padding-left: 20px;
    padding-right: 0;
    display: inline-block; }
  .news-nav__prev svg {
    transform: rotate(90deg); }
  .news-nav__prev:not(span):hover svg {
    transform: rotate(90deg) translate(0, 14px); }

.news-nav span.news-nav__prev,
.news-nav span.news-nav__next {
  color: rgba(32, 32, 32, 0.5); }
  .news-nav span.news-nav__prev svg,
  .news-nav span.news-nav__next svg {
    fill: rgba(32, 32, 32, 0.2); }

.tag-list {
  display: flex;
  flex-wrap: wrap;
  margin-left: -5px;
  margin-right: -5px;
  margin-bottom: -10px; }
  .tag-list li {
    font-weight: 500;
    color: #202020;
    background-color: #f5f5f5;
    padding: 8px 12px 7px 12px;
    margin: 0 5px;
    margin-bottom: 10px;
    font-size: 0.6875rem;
    text-transform: uppercase;
    letter-spacing: 0.075em; }

.filter-list {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: -10px; }
  @media screen and (max-width: 1024px) {
    .filter-list {
      display: none; } }
  .filter-list li {
    margin-bottom: 10px; }
    .filter-list li:not(:last-child) {
      margin-right: 20px; }
      @media screen and (max-width: 768px) {
        .filter-list li:not(:last-child) {
          margin-right: 10px; } }
    .filter-list li .filter-list__item {
      padding: 16px 20px;
      display: inline-block;
      border: 1px solid rgba(153, 153, 153, 0.3);
      background-color: white;
      text-transform: uppercase;
      font-size: 0.8125rem;
      color: #202020;
      font-weight: 500;
      cursor: pointer;
      transition: color 0.3s cubic-bezier(0.76, 0, 0.24, 1), background-color 0.3s cubic-bezier(0.76, 0, 0.24, 1);
      white-space: nowrap; }
      .filter-list li .filter-list__item:hover {
        color: rgba(32, 32, 32, 0.3); }
      .filter-list li .filter-list__item--active {
        background-color: #00c281;
        color: white; }
        .filter-list li .filter-list__item--active:hover {
          background-color: #03b177;
          color: white; }

.filter-list-mobile {
  display: none; }
  @media screen and (max-width: 1024px) {
    .filter-list-mobile {
      display: block; } }
  .filter-list-mobile .field {
    margin-bottom: 0; }

.products-slider {
  position: relative;
  /* the parent */ }
  .products-slider__arrows {
    position: absolute;
    top: -50px;
    right: 0; }
  .products-slider__slide {
    width: 46%; }
  .products-slider .slick-slide {
    margin: 0 var(--grid-gaps); }
  .products-slider .slick-list {
    margin-left: calc(var(--grid-gaps)*-1);
    margin-right: calc(var(--grid-gaps)*-1);
    overflow: visible; }

:root {
  --dot-color: #202020;
  --dot-opacity: 0.1; }

.dark-bg {
  --dot-color: white;
  --dot-opacity: 0.3; }

@media screen and (max-width: 800px) {
  .dark-dots-800 {
    --dot-color: #202020; }
    .dark-dots-800 .dark-bg {
      --dot-color: #202020; } }

.slick-dots {
  width: auto;
  display: flex;
  flex-direction: column; }
  .slick-dots--horizontal {
    flex-direction: row; }
  .slick-dots li {
    height: 26px;
    width: 26px;
    margin: 0px; }
    .slick-dots li:not(:last-child) {
      margin-bottom: 10px; }
    .slick-dots li button {
      height: 26px;
      width: 26px;
      position: relative; }
      .slick-dots li button::before, .slick-dots li button::after {
        content: '';
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        height: 10px;
        width: 10px;
        border-radius: 50%;
        background-color: var(--dot-color);
        opacity: var(--dot-opacity);
        transition: width 0.3s cubic-bezier(0.76, 0, 0.24, 1), height 0.3s cubic-bezier(0.76, 0, 0.24, 1); }
      .slick-dots li button::after {
        background-color: transparent;
        border: 0 solid var(--dot-color);
        transition: width 0.3s cubic-bezier(0.76, 0, 0.24, 1), height 0.3s cubic-bezier(0.76, 0, 0.24, 1), border 0.3s cubic-bezier(0.76, 0, 0.24, 1); }
    .slick-dots li.slick-active button::before {
      height: 6px;
      width: 6px;
      opacity: 1; }
    .slick-dots li.slick-active button::after {
      height: 22px;
      width: 22px;
      opacity: 1;
      border-width: 2px; }

.horizontal-dots .slick-dots {
  flex-direction: row; }
  .horizontal-dots .slick-dots li {
    margin-bottom: 0; }
    .horizontal-dots .slick-dots li:not(:last-child) {
      margin-right: 10px; }

@media screen and (max-width: 800px) {
  .horizontal-dots-800 .slick-dots {
    flex-direction: row; }
    .horizontal-dots-800 .slick-dots li {
      margin-bottom: 0; }
      .horizontal-dots-800 .slick-dots li:not(:last-child) {
        margin-right: 10px; } }

:root {
  --arrow-color:#00c281;
  --arrow-color-hover:#00c281; }

.dark-bg {
  --arrow-color: #00c281; }

.slick-arrows {
  --arrow-width: 26px;
  --arrow-height: 19px;
  --arrow-travel: 5px;
  width: 100px;
  height: 20px;
  position: relative; }
  .slick-arrows .slick-prev,
  .slick-arrows .slick-next {
    width: var(--arrow-width);
    height: var(--arrow-height);
    z-index: 3; }
    .slick-arrows .slick-prev::before,
    .slick-arrows .slick-next::before {
      content: normal; }
    .slick-arrows .slick-prev svg,
    .slick-arrows .slick-next svg {
      width: var(--arrow-height);
      height: var(--arrow-width);
      fill: var(--arrow-color);
      transition: fill 0.3s cubic-bezier(0.76, 0, 0.24, 1), transform 0.3s cubic-bezier(0.76, 0, 0.24, 1); }
  .slick-arrows .slick-prev {
    left: 0; }
    .slick-arrows .slick-prev svg {
      transform: rotate(90deg) translate(-3px, -4px); }
  .slick-arrows .slick-next {
    right: 0; }
    .slick-arrows .slick-next svg {
      transform: rotate(-90deg) translate(3px, 4px); }
  .slick-arrows .slick-prev:hover svg,
  .slick-arrows .slick-next:hover svg {
    fill: var(--arrow-color-hover); }
  .slick-arrows .slick-prev:hover svg {
    transform: rotate(90deg) translate(-3px, calc( -4px - (var(--arrow-travel)*-1))); }
  .slick-arrows .slick-next:hover svg {
    transform: rotate(-90deg) translate(3px, calc( 4px + var(--arrow-travel))); }

.page-top {
  background-color: white;
  position: relative; }
  .page-top__over-title {
    font-weight: normal;
    color: #00c281;
    text-transform: uppercase;
    font-size: 0.8125rem;
    font-weight: 500; }
  @media screen and (max-width: 768px) {
    .page-top__title {
      margin-top: -20px; } }
  .page-top__title-date {
    color: #00c281;
    font-size: 0.8125rem;
    text-transform: uppercase;
    font-weight: 500;
    padding-bottom: 25px; }
  .page-top__search {
    max-width: 625px; }
  .page-top__picture {
    position: relative;
    z-index: 1; }
    @media screen and (max-width: 768px) {
      .page-top__picture {
        padding-left: 0;
        padding-right: 0; } }
    .page-top__picture__wrapper {
      position: relative; }
      .page-top__picture__wrapper picture {
        height: 530px; }
        @media screen and (max-width: 1200px) {
          .page-top__picture__wrapper picture {
            height: 500px; } }
        @media screen and (max-width: 1024px) {
          .page-top__picture__wrapper picture {
            height: 400px; } }
        @media screen and (max-width: 768px) {
          .page-top__picture__wrapper picture {
            height: 350px; } }
        @media screen and (max-width: 600px) {
          .page-top__picture__wrapper picture {
            height: 300px; } }
        @media screen and (max-width: 500px) {
          .page-top__picture__wrapper picture {
            height: 250px; } }
    .page-top__picture__credit {
      position: absolute;
      top: 0;
      right: 0;
      background-color: rgba(32, 32, 32, 0.5);
      color: white;
      padding: 20px 30px;
      font-size: 0.8125rem; }
      @media screen and (max-width: 768px) {
        .page-top__picture__credit {
          padding: 14px 20px; } }
  .page-top__filter-wrapper {
    display: flex;
    justify-content: space-between;
    padding-top: var(--y-padding-60); }
    @media screen and (max-width: 1024px) {
      .page-top__filter-wrapper {
        flex-wrap: wrap; } }
  .page-top .filter-list,
  .page-top .filter-list-mobile {
    position: relative;
    z-index: 4; }
    @media screen and (max-width: 1024px) {
      .page-top .filter-list,
      .page-top .filter-list-mobile {
        width: 100%; } }
  .page-top .filter-sort {
    min-width: 300px;
    padding-left: var(--x-padding-40); }
    @media screen and (max-width: 1024px) {
      .page-top .filter-sort {
        width: 100%;
        margin-top: var(--y-padding-30);
        padding-left: 0; } }
  .page-top--with-picture:after {
    content: '';
    position: absolute;
    background-color: #f5f5f5;
    bottom: 0;
    height: 270px;
    width: 100%; }
    @media screen and (max-width: 1200px) {
      .page-top--with-picture:after {
        height: 250px; } }
    @media screen and (max-width: 1024px) {
      .page-top--with-picture:after {
        height: 200px; } }
    @media screen and (max-width: 768px) {
      .page-top--with-picture:after {
        height: 175px; } }
    @media screen and (max-width: 600px) {
      .page-top--with-picture:after {
        height: 150px; } }
    @media screen and (max-width: 500px) {
      .page-top--with-picture:after {
        height: 125px; } }
  .page-top > .grid-pattern {
    top: 90px;
    right: 0;
    height: 971px;
    width: var(--pattern-width-smaller);
    background-position: 1px 1px; }
  .page-top--no-bottom-padding .page-top__title {
    padding-bottom: 0; }
  .page-top--news-details .page-top__title h1 {
    max-width: 80%; }
    @media screen and (max-width: 1200px) {
      .page-top--news-details .page-top__title h1 {
        max-width: 100%; } }

:root {
  --page-top-background-color: white; }

.dark-bg {
  --page-top-background-color: #272727; }

.page-top-2-cols {
  --rapid-access-height: 130px;
  --button-spacing: 50px;
  --print-button-size: 70px;
  height: calc(100vh - var(--header-height));
  min-height: 500px;
  position: relative;
  background-color: var(--page-top-background-color);
  display: flex;
  flex-wrap: wrap; }
  @media screen and (max-width: 1300px) {
    .page-top-2-cols {
      --button-spacing: 30px; } }
  @media screen and (max-width: 800px) {
    .page-top-2-cols {
      --rapid-access-height: 100px; } }
  @media screen and (max-width: 500px) {
    .page-top-2-cols {
      --rapid-access-height: 80px; } }
  @media screen and (max-width: 800px) {
    .page-top-2-cols {
      flex-wrap: nowrap;
      flex-direction: column;
      min-height: 700px; } }
  .page-top-2-cols__content {
    padding-bottom: var(--rapid-access-height);
    width: 50%;
    height: 100%;
    position: relative;
    display: flex;
    align-items: center; }
    @media screen and (max-width: 1024px) {
      .page-top-2-cols__content {
        width: 60%; } }
    @media screen and (max-width: 800px) {
      .page-top-2-cols__content {
        width: 100%;
        height: auto;
        padding-top: 80px;
        padding-bottom: 80px; } }
    .page-top-2-cols__content .breadcrumbs {
      position: absolute;
      width: 100%;
      left: 0;
      top: 0; }
    .page-top-2-cols__content .grid-pattern {
      left: 0;
      bottom: var(--rapid-access-height);
      height: 70px;
      width: 110%;
      background-position: right 1px; }
      @media screen and (max-width: 800px) {
        .page-top-2-cols__content .grid-pattern {
          display: none; } }
  .page-top-2-cols__over-title {
    font-weight: normal;
    display: flex;
    align-items: center;
    justify-content: space-between; }
  .page-top-2-cols__over-title-print-logo {
    display: none; }
  .page-top-2-cols__date-and-categ {
    color: #00c281;
    font-size: 0.8125rem;
    text-transform: uppercase;
    font-weight: 500;
    padding-bottom: 25px; }
  .page-top-2-cols__dots {
    position: absolute;
    right: 25px;
    bottom: 50%;
    transform: translate(0, calc(50% - var(--rapid-access-height)/2)); }
    .page-top-2-cols__dots .slick-dots {
      bottom: 0;
      position: relative; }
    @media screen and (max-width: 800px) {
      .page-top-2-cols__dots {
        right: auto;
        bottom: 10px;
        transform: translate(0, 0); } }
  .page-top-2-cols__rapid-access {
    background-color: #202020;
    height: var(--rapid-access-height);
    position: absolute;
    bottom: 0;
    left: 0;
    min-width: 60%;
    max-width: 100%;
    z-index: 2;
    overflow-x: auto; }
    @media screen and (max-width: 800px) {
      .page-top-2-cols__rapid-access {
        width: 100%; } }
    .page-top-2-cols__rapid-access ul {
      display: flex;
      height: 100%; }
      .page-top-2-cols__rapid-access ul li {
        position: relative; }
        .page-top-2-cols__rapid-access ul li a, .page-top-2-cols__rapid-access ul li span {
          height: 100%;
          padding: 0 var(--button-spacing);
          display: flex;
          align-items: center;
          color: white;
          white-space: nowrap;
          font-size: 1.125rem;
          font-weight: bold;
          cursor: pointer;
          transition: color 0.3s cubic-bezier(0.76, 0, 0.24, 1); }
        .page-top-2-cols__rapid-access ul li:first-child a, .page-top-2-cols__rapid-access ul li:first-child span {
          font-weight: normal;
          cursor: default; }
          @media screen and (max-width: 768px) {
            .page-top-2-cols__rapid-access ul li:first-child a, .page-top-2-cols__rapid-access ul li:first-child span {
              font-size: 0.875rem; } }
          .page-top-2-cols__rapid-access ul li:first-child a::after, .page-top-2-cols__rapid-access ul li:first-child span::after {
            content: '';
            background-color: white;
            opacity: 0.5;
            height: 18px;
            width: 1px;
            position: absolute;
            top: 50%;
            right: 0;
            transform: translate(0, -50%); }
        .page-top-2-cols__rapid-access ul li:not(:first-child) a::before, .page-top-2-cols__rapid-access ul li:not(:first-child) span::before {
          content: '';
          position: absolute;
          top: 0;
          height: 0;
          left: var(--button-spacing);
          right: var(--button-spacing);
          transition: height 0.3s cubic-bezier(0.76, 0, 0.24, 1);
          background-color: #00c281; }
        .page-top-2-cols__rapid-access ul li:not(:first-child) a:hover, .page-top-2-cols__rapid-access ul li:not(:first-child) span:hover {
          color: #00c281; }
          .page-top-2-cols__rapid-access ul li:not(:first-child) a:hover::before, .page-top-2-cols__rapid-access ul li:not(:first-child) span:hover::before {
            height: 7px; }
  .page-top-2-cols__print-btn {
    position: absolute;
    bottom: calc(var(--rapid-access-height)*0.5);
    right: 50px;
    width: var(--print-button-size);
    height: var(--print-button-size);
    z-index: 2;
    transform: translate(0, 50%); }
    @media screen and (max-width: 1300px) {
      .page-top-2-cols__print-btn {
        display: none; } }
    .page-top-2-cols__print-btn svg {
      width: 24px;
      height: 24px;
      fill: white;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 3;
      pointer-events: none; }
    .page-top-2-cols__print-btn::after {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      border-radius: 50%;
      background-color: #00c281;
      z-index: 2;
      cursor: pointer;
      transition: transform 0.3s cubic-bezier(0.76, 0, 0.24, 1); }
    .page-top-2-cols__print-btn:hover::after {
      transform: scale(1.1); }
  .page-top-2-cols__picture {
    width: 50%;
    height: 100%;
    background-color: #eaeaea; }
    @media screen and (max-width: 1024px) {
      .page-top-2-cols__picture {
        width: 40%; } }
    @media screen and (max-width: 800px) {
      .page-top-2-cols__picture {
        width: 100%;
        flex-grow: 1;
        padding-bottom: var(--rapid-access-height);
        overflow: hidden; } }
  .page-top-2-cols__credit {
    position: absolute;
    top: 0;
    right: 0;
    background-color: rgba(32, 32, 32, 0.5);
    color: white;
    padding: 20px 30px;
    font-size: 0.8125rem;
    z-index: 1; }
    @media screen and (max-width: 768px) {
      .page-top-2-cols__credit {
        padding: 14px 20px; } }
  .page-top-2-cols__picture-wrapper {
    height: 100%;
    margin-bottom: 0 !important; }
    .page-top-2-cols__picture-wrapper .slick-list,
    .page-top-2-cols__picture-wrapper .slick-track,
    .page-top-2-cols__picture-wrapper .slick-slide > div {
      height: 100%; }
    .page-top-2-cols__picture-wrapper .slick-dots {
      left: 0;
      bottom: 50%;
      transform: translate(calc(-100% + -15px), calc(50% - var(--rapid-access-height)/2)); }
  .page-top-2-cols__picture-slide {
    height: 100%; }
    .page-top-2-cols__picture-slide picture {
      height: 100%; }
  .page-top-2-cols--bigger-min-height {
    min-height: 700px; }
    @media screen and (max-width: 800px) {
      .page-top-2-cols--bigger-min-height {
        min-height: 700px; } }
  .page-top-2-cols--inverted {
    flex-direction: row-reverse; }
    @media screen and (max-width: 800px) {
      .page-top-2-cols--inverted {
        flex-direction: column; } }
    .page-top-2-cols--inverted .page-top-2-cols__content .grid-pattern {
      display: none; }
    .page-top-2-cols--inverted .page-top-2-cols__picture {
      padding-bottom: var(--rapid-access-height); }
  .page-top-2-cols--no-rapid-access .page-top-2-cols__content {
    padding-bottom: 0; }
    @media screen and (max-width: 1024px) {
      .page-top-2-cols--no-rapid-access .page-top-2-cols__content {
        padding-bottom: var(--x-padding-80); } }
    .page-top-2-cols--no-rapid-access .page-top-2-cols__content .grid-pattern {
      bottom: 0; }
  @media screen and (max-width: 1024px) {
    .page-top-2-cols--no-rapid-access .page-top-2-cols__picture {
      padding-bottom: 0; } }
  .page-top-2-cols--smaller-title .page-top-2-cols__title h1 {
    font-size: 3.125rem; }
    @media screen and (max-width: 1024px) {
      .page-top-2-cols--smaller-title .page-top-2-cols__title h1 {
        font-size: 2rem; } }
    @media screen and (max-width: 768px) {
      .page-top-2-cols--smaller-title .page-top-2-cols__title h1 {
        font-size: 1.875rem; } }
    @media screen and (max-width: 425px) {
      .page-top-2-cols--smaller-title .page-top-2-cols__title h1 {
        font-size: 1.625rem; } }

.search-form__wrapper {
  display: flex;
  border-bottom: 1px solid #202020;
  position: relative; }

.search-form__input {
  border: 0;
  font-weight: 500;
  font-size: 1.125rem;
  padding: 12px 0;
  flex-grow: 1;
  margin-right: 40px; }
  .search-form__input + .search-form__x {
    opacity: 0;
    pointer-events: none;
    position: absolute;
    right: 30px;
    top: 50%;
    transform: translate(0, -50%);
    width: 20px;
    height: 20px;
    cursor: pointer;
    transition: opacity 0.3s; }
    .search-form__input + .search-form__x::before, .search-form__input + .search-form__x::after {
      content: '';
      position: absolute;
      background-color: #202020;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%) rotate(45deg);
      width: 20px;
      height: 2px;
      transition: background-color 0.3s; }
    .search-form__input + .search-form__x::after {
      transform: translate(-50%, -50%) rotate(-45deg); }
    .search-form__input + .search-form__x:hover::before, .search-form__input + .search-form__x:hover::after {
      background-color: rgba(32, 32, 32, 0.5); }
  .search-form__input:not(:placeholder-shown) + .search-form__x {
    opacity: 1;
    pointer-events: all; }

.search-form__btn {
  opacity: 1;
  transition: opacity 0.3s cubic-bezier(0.76, 0, 0.24, 1);
  display: flex;
  align-items: center; }
  .search-form__btn svg {
    width: 20px;
    height: 20px;
    fill: #202020; }
  .search-form__btn:hover {
    opacity: 0.6; }

:root {
  --dynamic-ol-inside-left-padding: 35px;
  --dynamic-ul-inside-left-padding: 22px; }
  @media screen and (max-width: 768px) {
    :root {
      --dynamic-ol-inside-left-padding: 25px;
      --dynamic-ul-inside-left-padding: 15px; } }
  @media screen and (max-width: 500px) {
    :root {
      --dynamic-ol-inside-left-padding: 10px;
      --dynamic-ul-inside-left-padding: 10px; } }

.dynamic > :first-child {
  margin-top: 0 !important; }

.dynamic > :last-child {
  margin-bottom: 0 !important; }

.dynamic h2 {
  margin-top: 50px;
  margin-bottom: 30px; }
  @media screen and (max-width: 768px) {
    .dynamic h2 {
      margin-bottom: 20px; } }

.dynamic h3 {
  margin-top: 50px;
  margin-bottom: 20px; }

.dynamic h4 {
  margin-top: 40px;
  margin-bottom: 15px; }

.dynamic h2 + h3,
.dynamic h3 + h4,
.dynamic h4 + h5,
.dynamic h5 + h6 {
  margin-top: 0; }

.dynamic p {
  margin-top: 20px;
  margin-bottom: 20px; }
  .dynamic p.buttonMargin {
    margin-top: 40px;
    margin-bottom: 35px; }
    .dynamic p.buttonMargin .btn {
      margin-bottom: 5px; }

.dynamic strong, .dynamic b {
  font-weight: 700; }

.dynamic em, .dynamic i {
  font-style: italic; }

.dynamic a:not([class^="btn"]) {
  color: #202020;
  text-decoration: underline;
  transition: color 0.3s cubic-bezier(0.76, 0, 0.24, 1); }
  .dynamic a:not([class^="btn"]):hover {
    color: #00c281; }

.dynamic ol {
  list-style: none;
  counter-reset: li; }
  .dynamic ol > li {
    position: relative; }
    .dynamic ol > li:last-child {
      margin-bottom: 0; }
    .dynamic ol > li:before {
      content: counter(li) ".";
      counter-increment: li;
      display: inline-block;
      color: #00c281;
      font-weight: 700; }
      @media screen and (max-width: 768px) {
        .dynamic ol > li:before {
          font-size: 1rem; } }
      @media screen and (max-width: 500px) {
        .dynamic ol > li:before {
          font-size: 0.9375rem; } }
    .dynamic ol > li ol {
      width: 100%;
      counter-reset: li; }
      .dynamic ol > li ol li {
        padding-left: var(--dynamic-ol-inside-left-padding); }
        .dynamic ol > li ol li:before {
          content: counter(li, upper-alpha) ")";
          color: #202020; }
    .dynamic ol > li ul {
      width: 100%;
      counter-reset: li; }
      .dynamic ol > li ul li {
        padding-left: var(--dynamic-ol-inside-left-padding); }

.dynamic ul > li {
  position: relative; }
  .dynamic ul > li:before {
    content: '';
    width: 4px;
    height: 4px;
    background: #00c281;
    border: 1px solid #00c281;
    border-radius: 50%;
    transform: translateY(-3px); }
  .dynamic ul > li:last-child {
    padding-bottom: 0; }
  .dynamic ul > li ul {
    width: 100%;
    padding-left: var(--dynamic-ul-inside-left-padding); }
    .dynamic ul > li ul li::before {
      background: transparent;
      border-color: #00c281;
      border: 2px solid #00c281; }
    .dynamic ul > li ul li ul li:before {
      background: #00c281;
      border: 1px solid #00c281; }
  .dynamic ul > li ol {
    width: 100%;
    counter-reset: li; }
    .dynamic ul > li ol li {
      padding-left: var(--dynamic-ul-inside-left-padding); }

.dynamic > ol, .dynamic > ul {
  margin-bottom: 20px; }
  @media screen and (max-width: 1024px) {
    .dynamic > ol, .dynamic > ul {
      margin-bottom: 15px; } }
  @media screen and (max-width: 768px) {
    .dynamic > ol, .dynamic > ul {
      margin-bottom: 10px; } }
  .dynamic > ol > li:first-child, .dynamic > ul > li:first-child {
    padding-top: 0; }
  .dynamic > ol li, .dynamic > ul li {
    display: flex;
    flex-flow: row wrap;
    align-items: baseline;
    padding-top: 20px; }
    @media screen and (max-width: 1024px) {
      .dynamic > ol li, .dynamic > ul li {
        padding-top: 15px; } }
    @media screen and (max-width: 768px) {
      .dynamic > ol li, .dynamic > ul li {
        padding-top: 10px; } }
    .dynamic > ol li:before, .dynamic > ul li:before {
      margin-right: 15px; }
      @media screen and (max-width: 768px) {
        .dynamic > ol li:before, .dynamic > ul li:before {
          margin-right: 10px; } }
    .dynamic > ol li p, .dynamic > ul li p {
      flex: 1;
      margin: 0; }
    .dynamic > ol li b, .dynamic > ol li strong, .dynamic > ul li b, .dynamic > ul li strong {
      font-weight: 500; }

.dynamic blockquote {
  position: relative;
  padding-left: 40px; }
  .dynamic blockquote p {
    color: #00c281;
    font-size: 1.375rem;
    line-height: 1.5909em; }
    @media screen and (max-width: 1024px) {
      .dynamic blockquote p {
        font-size: 1.25rem; } }
    @media screen and (max-width: 768px) {
      .dynamic blockquote p {
        font-size: 1.125rem; } }
    @media screen and (max-width: 425px) {
      .dynamic blockquote p {
        font-size: 1rem; } }
  .dynamic blockquote::before {
    content: '';
    position: absolute;
    background-image: url("../medias/images/icons/quote.png");
    width: 21px;
    height: 21px;
    background-size: cover;
    left: 0;
    top: 2px; }

.dynamic pre {
  font-family: "Aktiv Grotesk", sans-serif;
  white-space: normal;
  font-size: 0.8125rem;
  line-height: 1.5384em;
  color: #999999;
  margin-top: 20px;
  margin-bottom: 20px; }

.dynamic .table-wrapper {
  overflow-x: auto;
  margin-top: 30px;
  margin-bottom: 30px; }
  .dynamic .table-wrapper table {
    overflow-x: hidden;
    width: 100%; }
    .dynamic .table-wrapper table tr th, .dynamic .table-wrapper table tr td {
      padding: 20px 25px;
      color: #202020;
      line-height: 1.5625em;
      background-color: white;
      font-size: 1.125rem; }
      @media screen and (max-width: 1024px) {
        .dynamic .table-wrapper table tr th, .dynamic .table-wrapper table tr td {
          font-size: 1.0625rem; } }
      @media screen and (max-width: 768px) {
        .dynamic .table-wrapper table tr th, .dynamic .table-wrapper table tr td {
          font-size: 1rem; } }
      @media screen and (max-width: 375px) {
        .dynamic .table-wrapper table tr th, .dynamic .table-wrapper table tr td {
          font-size: 0.9375rem; } }
      .dynamic .table-wrapper table tr th > :first-child, .dynamic .table-wrapper table tr td > :first-child {
        margin-top: 0; }
      .dynamic .table-wrapper table tr th > :last-child, .dynamic .table-wrapper table tr td > :last-child {
        margin-bottom: 0; }
      .dynamic .table-wrapper table tr th.dark, .dynamic .table-wrapper table tr td.dark {
        color: white;
        background-color: #202020; }
        .dynamic .table-wrapper table tr th.dark a, .dynamic .table-wrapper table tr th.dark p, .dynamic .table-wrapper table tr th.dark pre, .dynamic .table-wrapper table tr td.dark a, .dynamic .table-wrapper table tr td.dark p, .dynamic .table-wrapper table tr td.dark pre {
          color: white; }
        .dynamic .table-wrapper table tr th.dark a:hover, .dynamic .table-wrapper table tr td.dark a:hover {
          color: #00c281; }
        .dynamic .table-wrapper table tr th.dark h1, .dynamic .table-wrapper table tr th.dark h2, .dynamic .table-wrapper table tr th.dark h3, .dynamic .table-wrapper table tr th.dark h4, .dynamic .table-wrapper table tr td.dark h1, .dynamic .table-wrapper table tr td.dark h2, .dynamic .table-wrapper table tr td.dark h3, .dynamic .table-wrapper table tr td.dark h4 {
          color: #00c281; }
    .dynamic .table-wrapper table tr th {
      background-color: #00c281;
      color: white;
      text-align: left; }
    .dynamic .table-wrapper table tr:not(:last-child) td {
      border-bottom: 1px solid #d9d9d9; }
    .dynamic .table-wrapper table tr:only-child th:only-child, .dynamic .table-wrapper table tr:only-child td:only-child {
      padding: 55px 60px;
      border: 0; }

.hamburger {
  position: absolute;
  width: 30px;
  height: 20px;
  cursor: pointer;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%); }
  @media screen and (max-width: 425px) {
    .hamburger {
      width: 25px; } }
  .hamburger span {
    display: block;
    position: absolute;
    width: 100%;
    height: 2px;
    background: #202020;
    opacity: 1;
    left: 0%;
    transform: rotate(0deg);
    transition: 250ms cubic-bezier(0.76, 0, 0.24, 1); }
    .hamburger span:nth-child(1) {
      top: 0px; }
    .hamburger span:nth-child(2), .hamburger span:nth-child(3) {
      top: 8px; }
    .hamburger span:nth-child(4) {
      top: 16px; }

:root {
  --breadcrumbs-color: #999999;
  --breadcrumbs-color-hover: #202020; }

.dark-bg {
  --breadcrumbs-color: white;
  --breadcrumbs-color-hover: rgba(255,255,255,0.6); }

.breadcrumbs {
  height: 70px;
  display: flex;
  align-items: center;
  overflow-y: auto; }
  .breadcrumbs ul {
    display: flex;
    align-items: center; }
    .breadcrumbs ul li {
      position: relative; }
      .breadcrumbs ul li:not(:first-child) {
        padding-left: 13px; }
      .breadcrumbs ul li:not(:last-child) {
        padding-right: 17px; }
      @media screen and (max-width: 768px) {
        .breadcrumbs ul li:not(:first-child) {
          padding-left: 9px; }
        .breadcrumbs ul li:not(:last-child) {
          padding-right: 15px; } }
      .breadcrumbs ul li a, .breadcrumbs ul li span {
        color: var(--breadcrumbs-color);
        font-weight: 700;
        font-size: 0.75rem;
        display: inline-block;
        text-transform: uppercase;
        transition: color 0.3s cubic-bezier(0.76, 0, 0.24, 1);
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden; }
      .breadcrumbs ul li a svg {
        width: 14px;
        height: 13px;
        fill: var(--breadcrumbs-color);
        transition: fill 0.3s cubic-bezier(0.76, 0, 0.24, 1); }
      .breadcrumbs ul li a:hover {
        color: var(--breadcrumbs-color-hover); }
        .breadcrumbs ul li a:hover svg {
          fill: var(--breadcrumbs-color-hover); }
      .breadcrumbs ul li:not(:last-child)::after {
        content: '';
        position: absolute;
        top: calc(50%);
        right: 0;
        transform: translate(0, -50%);
        font-size: 0.875rem;
        color: var(--breadcrumbs-color);
        width: 4px;
        height: 4px;
        border-radius: 50%;
        background-color: var(--breadcrumbs-color); }
      .breadcrumbs ul li:last-child span {
        font-weight: 700;
        max-width: 160px; }

.rubric {
  position: relative;
  border-bottom: 1px solid #202020;
  background: transparent;
  transition: border-bottom 700ms; }
  .rubric:first-child {
    border-top: 1px solid #202020; }
  .rubric__header {
    position: relative;
    cursor: pointer;
    padding: 20px 0;
    padding-right: 115px;
    min-height: 155px;
    display: flex;
    align-items: center; }
    @media screen and (max-width: 1024px) {
      .rubric__header {
        padding-right: 80px; } }
    @media screen and (max-width: 768px) {
      .rubric__header {
        min-height: 130px; } }
    @media screen and (max-width: 600px) {
      .rubric__header {
        min-height: 110px;
        padding-right: 50px; } }
  .rubric__header:hover .rubric__icon:before {
    background: #00c281;
    transform: scale(1.20512); }
  .rubric__title {
    line-height: 1.5;
    color: #202020;
    transition: color 0.3s cubic-bezier(0.76, 0, 0.24, 1);
    padding-left: 20px; }
    @media screen and (max-width: 1024px) {
      .rubric__title {
        padding-left: 0; } }
  .rubric__icon {
    position: absolute;
    width: 78px;
    height: 78px;
    top: 50%;
    right: 20px;
    transform: translateY(-50%); }
    @media screen and (max-width: 1024px) {
      .rubric__icon {
        right: 0;
        width: 60px;
        height: 60px; } }
    @media screen and (max-width: 600px) {
      .rubric__icon {
        width: 34px;
        height: 34px; } }
  .rubric__icon:before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: #202020;
    border-radius: 50%;
    transition: background 300ms, transform 300ms cubic-bezier(0.33, 1, 0.68, 1); }
  .rubric__svg {
    position: absolute;
    width: 22px;
    height: 30px;
    top: 50%;
    left: 50%;
    fill: white;
    transform: translate(-50%, -50%) rotate(0deg);
    transition: transform 300ms cubic-bezier(0.33, 1, 0.68, 1), stroke 300ms; }
    @media screen and (max-width: 600px) {
      .rubric__svg {
        width: 15px;
        height: 21px; } }
  .rubric__content {
    height: 0;
    will-change: height;
    overflow: hidden;
    padding: 0 20px; }
    @media screen and (max-width: 1024px) {
      .rubric__content {
        padding: 0; } }
  .rubric__transition {
    opacity: 0;
    transform: translateY(100px);
    transition: opacity 700ms cubic-bezier(0.65, 0, 0.35, 1), transform 700ms cubic-bezier(0.65, 0, 0.35, 1); }
  .rubric__transition > :first-child {
    margin-top: 0; }
  .rubric__dynamic h3 {
    font-size: 1.375rem;
    margin: 20px 0 10px; }
    @media screen and (max-width: 375px) {
      .rubric__dynamic h3 {
        font-size: 1.3125rem; } }
    @media screen and (max-width: 320px) {
      .rubric__dynamic h3 {
        font-size: 1.25rem; } }
  .rubric__dynamic h4 {
    font-size: 1rem;
    margin: 15px 0 5px; }
  .rubric__dynamic p {
    margin: 5px 0 15px; }
  .rubric__dynamic blockquote {
    font-size: 1.4375rem; }
    @media screen and (max-width: 1024px) {
      .rubric__dynamic blockquote {
        font-size: 1.375rem; } }
    @media screen and (max-width: 768px) {
      .rubric__dynamic blockquote {
        font-size: 1.3125rem; } }
    @media screen and (max-width: 425px) {
      .rubric__dynamic blockquote {
        font-size: 1.25rem; } }
    @media screen and (max-width: 375px) {
      .rubric__dynamic blockquote {
        font-size: 1.1875rem; } }
    @media screen and (max-width: 320px) {
      .rubric__dynamic blockquote {
        font-size: 1.125rem; } }
    .rubric__dynamic blockquote p {
      font-size: 1.4375rem; }
      @media screen and (max-width: 1024px) {
        .rubric__dynamic blockquote p {
          font-size: 1.375rem; } }
      @media screen and (max-width: 768px) {
        .rubric__dynamic blockquote p {
          font-size: 1.3125rem; } }
      @media screen and (max-width: 425px) {
        .rubric__dynamic blockquote p {
          font-size: 1.25rem; } }
      @media screen and (max-width: 375px) {
        .rubric__dynamic blockquote p {
          font-size: 1.1875rem; } }
      @media screen and (max-width: 320px) {
        .rubric__dynamic blockquote p {
          font-size: 1.125rem; } }

.rubrics.rubrics--plus .rubric:not(.rubric__no-image) .rubric__header {
  padding-top: 20px;
  padding-bottom: 20px; }
  @media screen and (max-width: 600px) {
    .rubrics.rubrics--plus .rubric:not(.rubric__no-image) .rubric__header {
      padding-top: 30px;
      padding-bottom: 30px; } }

.rubrics.rubrics--plus .rubric__flex {
  display: flex;
  align-items: center;
  width: 100%; }

.rubrics.rubrics--plus .rubric__text {
  width: calc(100% - 170px); }
  @media screen and (max-width: 1200px) {
    .rubrics.rubrics--plus .rubric__text {
      width: calc(100% - 155px); } }
  @media screen and (max-width: 1024px) {
    .rubrics.rubrics--plus .rubric__text {
      width: calc(100% - 125px); } }
  @media screen and (max-width: 768px) {
    .rubrics.rubrics--plus .rubric__text {
      width: calc(100% - 95px); } }
  @media screen and (max-width: 600px) {
    .rubrics.rubrics--plus .rubric__text {
      width: 100%; } }

.rubrics.rubrics--plus .rubric__picture {
  width: 220px;
  height: 100px;
  margin-right: 30px;
  overflow: hidden; }
  @media screen and (max-width: 1200px) {
    .rubrics.rubrics--plus .rubric__picture {
      width: 125px;
      height: 125px; } }
  @media screen and (max-width: 1024px) {
    .rubrics.rubrics--plus .rubric__picture {
      width: 100px;
      height: 100px;
      margin-right: 25px; } }
  @media screen and (max-width: 768px) {
    .rubrics.rubrics--plus .rubric__picture {
      width: 75px;
      height: 75px;
      margin-right: 20px; } }
  @media screen and (max-width: 600px) {
    .rubrics.rubrics--plus .rubric__picture {
      display: none; } }

.rubrics.rubrics--plus .rubric__subtitle {
  margin-bottom: 5px;
  padding-right: 50px;
  padding-left: 20px; }
  @media screen and (max-width: 1024px) {
    .rubrics.rubrics--plus .rubric__subtitle {
      padding-left: 0; } }

.rubrics.rubrics--plus .rubric:not(.rubric__no-image) .rubric__title,
.rubrics.rubrics--plus .rubric:not(.rubric__no-image) .rubric__subtitle {
  padding-left: 0; }

.rubrics.rubrics--plus .rubric:not(.rubric__no-image) .rubric__transition {
  padding-top: 30px; }
  @media screen and (max-width: 1024px) {
    .rubrics.rubrics--plus .rubric:not(.rubric__no-image) .rubric__transition {
      padding-top: 15px; } }
  @media screen and (max-width: 600px) {
    .rubrics.rubrics--plus .rubric:not(.rubric__no-image) .rubric__transition {
      padding-top: 0px; } }

.fancybox-caption__body {
  font-family: "Aktiv Grotesk", sans-serif; }

.gallery {
  display: flex;
  flex-flow: row nowrap;
  max-width: 880px;
  height: 350px; }
  @media screen and (max-width: 768px) {
    .gallery {
      height: 335px; } }
  @media screen and (max-width: 500px) {
    .gallery {
      height: 200px; } }
  .gallery__left {
    flex: 1;
    margin-right: 30px; }
    @media screen and (max-width: 768px) {
      .gallery__left {
        margin-right: 15px; } }
    @media screen and (max-width: 500px) {
      .gallery__left {
        margin-right: 10px; } }
  .gallery__right {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    width: 160px; }
    @media screen and (max-width: 500px) {
      .gallery__right {
        width: 95px; } }
  .gallery__block {
    position: relative;
    height: 100%; }
  .gallery__block:before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: #202020;
    opacity: 0;
    transition: opacity 300ms;
    pointer-events: none; }
  .gallery__link {
    display: block;
    height: 100%; }
  .gallery__block {
    display: none; }
  .gallery__block-1, .gallery__block-2, .gallery__block-3 {
    display: block; }
  .gallery__block-1 .gallery__link, .gallery__block-2 .gallery__link, .gallery__block-3 .gallery__link {
    display: block;
    height: 100%; }
  .gallery__block-2, .gallery__block-3 {
    height: 160px; }
    @media screen and (max-width: 500px) {
      .gallery__block-2, .gallery__block-3 {
        height: 95px; } }
  .gallery__block-3:before {
    opacity: 0.8; }
  .gallery__block:hover:before {
    opacity: 0.5; }
  .gallery__picture {
    width: 100%;
    height: 100%; }
  .gallery__svg {
    position: absolute;
    width: 24px;
    height: 24px;
    top: 50%;
    left: 50%;
    stroke: #fff;
    fill: none;
    stroke-width: 5px;
    z-index: 1;
    pointer-events: none;
    transform: translate(-50%, -50%); }

.documents .document {
  position: relative;
  border-bottom: 1px solid #202020; }
  .documents .document:first-child {
    border-top: 1px solid #202020; }
  .documents .document__link {
    position: relative;
    cursor: pointer;
    padding: 20px 0;
    min-height: 155px;
    display: flex;
    align-items: center; }
    @media screen and (max-width: 1200px) {
      .documents .document__link {
        padding-top: 50px;
        padding-bottom: 50px; } }
    @media screen and (max-width: 1024px) {
      .documents .document__link {
        padding-top: 40px;
        padding-bottom: 40px; } }
    @media screen and (max-width: 600px) {
      .documents .document__link {
        padding-top: 30px;
        padding-bottom: 30px; } }
    @media screen and (max-width: 768px) {
      .documents .document__link {
        min-height: 130px; } }
    @media screen and (max-width: 600px) {
      .documents .document__link {
        min-height: 110px; } }
  .documents .document__link:hover .document__icon:before {
    background: #00c281;
    transform: scale(1.20512); }
  .documents .document__link:hover .document__title {
    color: #00c281; }
  .documents .document__title {
    padding-right: 50px;
    line-height: 1.5;
    color: #202020;
    transition: color 0.3s cubic-bezier(0.76, 0, 0.24, 1);
    padding-left: 20px; }
    @media screen and (max-width: 1024px) {
      .documents .document__title {
        padding-left: 0; } }
  .documents .document__icon {
    position: absolute;
    width: 78px;
    height: 78px;
    top: 50%;
    right: 20px;
    transform: translateY(-50%); }
    @media screen and (max-width: 1024px) {
      .documents .document__icon {
        right: 0;
        width: 60px;
        height: 60px; } }
    @media screen and (max-width: 600px) {
      .documents .document__icon {
        width: 34px;
        height: 34px; } }
  .documents .document__icon:before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: #202020;
    border-radius: 50%;
    transition: background 300ms, transform 300ms cubic-bezier(0.33, 1, 0.68, 1); }
  .documents .document__svg {
    position: absolute;
    width: 22px;
    height: 22px;
    top: 50%;
    left: 50%;
    fill: white;
    transform: translate(-50%, -50%);
    transition: transform 300ms cubic-bezier(0.33, 1, 0.68, 1), stroke 300ms; }
    @media screen and (max-width: 600px) {
      .documents .document__svg {
        width: 15px;
        height: 15px; } }
  .documents .document__svg-upload {
    width: 16px;
    height: 16px; }
    @media screen and (max-width: 600px) {
      .documents .document__svg-upload {
        width: 10px;
        height: 10px; } }

.documents.documents--plus .document__link {
  padding-top: 20px;
  padding-bottom: 20px; }
  @media screen and (max-width: 600px) {
    .documents.documents--plus .document__link {
      padding-top: 30px;
      padding-bottom: 30px; } }

.documents.documents--plus .document__flex {
  display: flex;
  align-items: center;
  width: 100%; }

.documents.documents--plus .document__text {
  width: calc(100% - 170px); }
  @media screen and (max-width: 1200px) {
    .documents.documents--plus .document__text {
      width: calc(100% - 155px); } }
  @media screen and (max-width: 1024px) {
    .documents.documents--plus .document__text {
      width: calc(100% - 125px); } }
  @media screen and (max-width: 768px) {
    .documents.documents--plus .document__text {
      width: calc(100% - 95px); } }
  @media screen and (max-width: 600px) {
    .documents.documents--plus .document__text {
      width: 100%; } }

.documents.documents--plus .document__subtitle {
  margin-bottom: 5px;
  padding-left: 20px; }
  @media screen and (max-width: 1024px) {
    .documents.documents--plus .document__subtitle {
      padding-left: 0; } }

.documents.documents--plus .document__picture {
  width: 220px;
  height: 100px;
  margin-right: 30px;
  overflow: hidden; }
  @media screen and (max-width: 1200px) {
    .documents.documents--plus .document__picture {
      width: 125px;
      height: 125px; } }
  @media screen and (max-width: 1024px) {
    .documents.documents--plus .document__picture {
      width: 100px;
      height: 100px;
      margin-right: 25px; } }
  @media screen and (max-width: 768px) {
    .documents.documents--plus .document__picture {
      width: 75px;
      height: 75px;
      margin-right: 20px; } }
  @media screen and (max-width: 600px) {
    .documents.documents--plus .document__picture {
      display: none; } }

.documents.documents--plus .document:not(.document--no-image) .document__title,
.documents.documents--plus .document:not(.document--no-image) .document__subtitle {
  padding-left: 0; }

.documents-in-rubric .document {
  border-bottom: 1px solid rgba(32, 32, 32, 0.2); }
  .documents-in-rubric .document:first-child {
    border-top: 1px solid rgba(32, 32, 32, 0.2); }
  .documents-in-rubric .document__link {
    display: block;
    position: relative;
    padding: 25px 60px 25px 0; }
  .documents-in-rubric .document__link:hover .document__title {
    color: #00c281; }
  .documents-in-rubric .document__link:hover .document__svg {
    fill: #00c281; }
  .documents-in-rubric .document__title {
    font: 1.375rem/1 "Aktiv Grotesk", sans-serif;
    color: #202020;
    transition: color 300ms; }
  .documents-in-rubric .document__svg {
    position: absolute;
    width: 14px;
    height: 16px;
    top: 50%;
    right: 30px;
    fill: #202020;
    transform: translateY(-50%);
    z-index: 1;
    transition: fill 300ms; }
  .documents-in-rubric .document__svg-upload {
    width: 11px;
    height: 11px; }
  .documents-in-rubric .document.document--no-image .document__flex {
    padding-left: 20px; }

.documents-in-rubric.documents-in-rubric--plus .document__flex {
  display: flex;
  align-items: center; }

.documents-in-rubric.documents-in-rubric--plus .document__text {
  width: calc(100% - 130px); }
  @media screen and (max-width: 1200px) {
    .documents-in-rubric.documents-in-rubric--plus .document__text {
      width: calc(100% - 155px); } }
  @media screen and (max-width: 1024px) {
    .documents-in-rubric.documents-in-rubric--plus .document__text {
      width: calc(100% - 125px); } }
  @media screen and (max-width: 768px) {
    .documents-in-rubric.documents-in-rubric--plus .document__text {
      width: calc(100% - 95px); } }
  @media screen and (max-width: 600px) {
    .documents-in-rubric.documents-in-rubric--plus .document__text {
      width: 100%; } }

.documents-in-rubric.documents-in-rubric--plus .document__picture {
  width: 220px;
  height: 100px;
  margin-right: 30px;
  overflow: hidden; }
  @media screen and (max-width: 1200px) {
    .documents-in-rubric.documents-in-rubric--plus .document__picture {
      width: 125px;
      height: 125px; } }
  @media screen and (max-width: 1024px) {
    .documents-in-rubric.documents-in-rubric--plus .document__picture {
      width: 100px;
      height: 100px;
      margin-right: 25px; } }
  @media screen and (max-width: 768px) {
    .documents-in-rubric.documents-in-rubric--plus .document__picture {
      width: 75px;
      height: 75px;
      margin-right: 20px; } }
  @media screen and (max-width: 600px) {
    .documents-in-rubric.documents-in-rubric--plus .document__picture {
      display: none; } }

.documents-in-rubric.documents-in-rubric--plus .document__subtitle {
  margin-bottom: 5px; }

.documents-in-rubric.documents-in-rubric--small .document {
  max-width: 475px; }
  .documents-in-rubric.documents-in-rubric--small .document__link {
    padding-right: 30px; }
  .documents-in-rubric.documents-in-rubric--small .document__svg {
    right: 0; }
  .documents-in-rubric.documents-in-rubric--small .document__title {
    font: 700 0.8125rem/1 "Aktiv Grotesk", sans-serif;
    text-transform: uppercase;
    letter-spacing: 1px; }

:root {
  --field-label-padding: 0 0 5px 0;
  --field-label-font-family: Aktiv Grotesk, sans-serif;
  --field-label-font-size: 13px;
  --field-label-font-weight: normal;
  --field-label-line-height: 1;
  --field-label-text-transform: inherit;
  --field-label-letter-spacing: inherit;
  --field-label-color: #999999;
  --field-padding: 15px 0;
  --field-border-radius: 0px;
  --field-font-family: Aktiv Grotesk, sans-serif;
  --field-font-size: 18px;
  --field-font-weight: normal;
  --field-line-height: 1;
  --field-text-transform: inherit;
  --field-letter-spacing: inherit;
  --field-color: #202020;
  --field-border-color: #202020;
  --field-background: transparent;
  --field-background-inactive: transparent;
  --field-color-inactive: #202020;
  --field-border-color-inactive: #202020;
  --field-transition: none;
  --field-color-placeholder: #999999;
  --box-dimension: 20px;
  --box-margin-right: 15px;
  --box-background: transparent;
  --box-background-active: #00c281;
  --radio-checkbox-font-family: Aktiv Grotesk, sans-serif;
  --radio-checkbox-font-size: rem(18);
  --radio-checkbox-font-weight: normal;
  --radio-checkbox-line-height: 1;
  --radio-checkbox-text-transform: inherit;
  --radio-checkbox-letter-spacing: inherit;
  --radio-checkbox-color: #202020;
  --radio-hook-dimension: 12px;
  --radio-dot-dimension: 7px;
  --radio-dot-color: white;
  --file-padding: 20px 60px 20px 0;
  --file-border-top: 0;
  --file-border-right: 0;
  --file-border-bottom: 1px solid #202020;
  --file-border-left: 0;
  --file-font-family: Aktiv Grotesk, sans-serif;
  --file-font-size: rem(16);
  --file-font-weight: 500;
  --file-line-height: 1.5;
  --file-text-transform: inherit;
  --file-letter-spacing: inherit;
  --file-color: #202020;
  --file-over-color: #00c281;
  --file-x-svg-dimension: 16px;
  --file-x-svg-top: calc(50% - (var(--file-x-svg-dimension) / 2));
  --file-x-svg-right: 0;
  --file-x-color: var(--file-color);
  --file-x-over-color: var(--file-over-color);
  --file-svg-color: var(--file-color);
  --file-svg-over-color: var(--file-over-color);
  --tail-label-padding: 5px 30px;
  --tail-label-min-height: 70px;
  --tail-label-font-family: Aktiv Grotesk, sans-serif;
  --tail-label-font-size: rem(18);
  --tail-label-font-weight: 400;
  --tail-label-line-height: 1.5;
  --tail-label-text-transform: inherit;
  --tail-label-letter-spacing: inherit;
  --tail-label-color: $color1;
  --tail-label-background: transparent;
  --tail-label-border: 1px solid #202020;
  --tail-label-transition: background 500ms var(--select-ease), border-color 500ms var(--select-ease);
  --tail-option-padding: 20px 30px;
  --tail-option-font-family: Aktiv Grotesk, sans-serif;
  --tail-option-font-size: rem(18);
  --tail-option-font-weight: 400;
  --tail-option-line-height: 1.5;
  --tail-option-text-transform: inherit;
  --tail-option-letter-spacing: inherit;
  --tail-option-color: $color1;
  --tail-option-background: #fff;
  --tail-option-over-color: #202020;
  --tail-option-over-background: #f5f5f5;
  --select-ease: cubic-bezier(0.76, 0, 0.24, 1); }

.dark-bg {
  --field-color: white;
  --field-border-color: white;
  --field-border-color-inactive: white;
  --field-color-inactive: white; }

.field {
  position: relative;
  margin-bottom: 55px; }
  @media screen and (max-width: 1024px) {
    .field {
      margin-bottom: 50px; } }
  @media screen and (max-width: 768px) {
    .field {
      margin-bottom: 40px; } }
  .field--more-margin {
    margin-bottom: 70px; }
    @media screen and (max-width: 1024px) {
      .field--more-margin {
        margin-bottom: 60px; } }
    @media screen and (max-width: 768px) {
      .field--more-margin {
        margin-bottom: 50px; } }
  .field__error {
    margin-top: 5px;
    font: 0.8125rem/1 var(--field-font-family);
    color: #c93030; }
  .field--title {
    margin-bottom: 20px;
    font: 1.125rem/1 var(--field-font-family); }
  .field ::-webkit-input-placeholder {
    color: var(--field-color-placeholder); }
  .field ::-moz-placeholder {
    color: var(--field-color-placeholder); }
  .field :-ms-input-placeholder {
    color: var(--field-color-placeholder); }
  .field :-moz-placeholder {
    color: var(--field-color-placeholder); }
  .field--input .field__input, .field--textarea .field__input, .field--newsletter .field__input {
    width: 100%;
    height: 32px;
    padding: 0 0 5px;
    font: 1.125rem/1.3 "Aktiv Grotesk", sans-serif;
    color: #202020;
    background: transparent;
    border: none;
    box-shadow: inset 0 -1px 0 0 #202020;
    border-radius: 0;
    -webkit-appearance: none; }
    .field--input .field__input:focus, .field--textarea .field__input:focus, .field--newsletter .field__input:focus {
      box-shadow: inset 0 -3px 0 0 #00c281; }
  .field--input .field__label, .field--textarea .field__label, .field--newsletter .field__label {
    position: absolute;
    top: 0px;
    left: 0;
    pointer-events: none;
    font: 1.125rem/1.3 "Aktiv Grotesk", sans-serif;
    color: #202020;
    transition: font-size 300ms, top 300ms cubic-bezier(0.33, 1, 0.68, 1); }
  .field--input .field__input:focus ~ .field__label, .field--input[data-inputvalue]:not([data-inputvalue=""]) .field__label,
  .field--textarea .field__input:focus ~ .field__label, .field--textarea[data-textareavalue]:not([data-textareavalue=""]) .field__label,
  .field--newsletter .field__input:focus ~ .field__label, .field--newsletter[data-inputvalue]:not([data-inputvalue=""]) .field__label,
  .field--input .field__input:-webkit-autofill ~ .field__label {
    font-size: 0.75rem;
    top: -20px; }
  .field--textarea .field__input {
    outline: none;
    resize: none;
    overflow: hidden; }
  .field--checkbox .field__label, .field--radio .field__label {
    display: flex;
    align-items: center;
    position: relative;
    margin-bottom: 20px;
    cursor: pointer; }
  .field--checkbox-inline .field__label, .field--radio-inline .field__label {
    display: inline-flex;
    margin: 0 30px 20px 0; }
  .field--checkbox .field__text, .field--radio .field__text {
    flex: 1;
    font-family: var(--radio-checkbox-font-family);
    font-size: var(--radio-checkbox-font-size);
    font-weight: var(--radio-checkbox-font-weight);
    line-height: var(--radio-checkbox-line-height);
    text-transform: var(--radio-checkbox-text-transform);
    letter-spacing: var(--radio-checkbox-letter-spacing);
    color: var(--radio-checkbox-color);
    order: 2;
    user-select: none; }
  .field--checkbox .field__input, .field--radio .field__input {
    position: absolute;
    opacity: 0;
    cursor: pointer; }
  .field--checkbox .field__input:checked ~ .field__box, .field--radio .field__input:checked ~ .field__box {
    background: var(--box-background-active);
    border-color: #00c281; }
  .field--checkbox .field__input:checked ~ .field__box:before, .field--radio .field__input:checked ~ .field__box:before {
    transform: translate(-50%, -50%) scale(1);
    border-color: #00c281; }
  .field--checkbox .field__box, .field--radio .field__box {
    position: relative;
    width: var(--box-dimension);
    height: var(--box-dimension);
    margin-right: var(--box-margin-right);
    background: var(--box-background);
    order: 1;
    transition: background 225ms;
    border: 1px solid #202020; }
    .field--checkbox .field__box:before, .field--radio .field__box:before {
      content: '';
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%) scale(0);
      transition: transform 225ms; }
  .field--checkbox .field__box:before {
    width: var(--radio-hook-dimension);
    height: var(--radio-hook-dimension);
    background: url("../medias/images/icons/crochet.svg") no-repeat center/contain; }
  .field--radio .field__box {
    border-radius: 50%; }
    .field--radio .field__box:before {
      width: var(--radio-dot-dimension);
      height: var(--radio-dot-dimension);
      background: var(--radio-dot-color);
      border-radius: 50%; }
  .field--file .field__input {
    position: absolute;
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    z-index: -1; }
  .field--file .field__label {
    display: block;
    position: relative;
    width: 100%;
    padding: var(--file-padding);
    border-top: var(--file-border-top);
    border-right: var(--file-border-right);
    border-bottom: var(--file-border-bottom);
    border-left: var(--file-border-left);
    cursor: pointer;
    transition: color 300ms, background 300ms; }
  .field--file .field__text {
    font-family: var(--file-font-family);
    font-size: var(--file-font-size);
    font-weight: var(--file-font-weight);
    line-height: var(--file-line-height);
    text-transform: var(--file-text-transform);
    letter-spacing: var(--file-letter-spacing);
    color: var(--file-color);
    transition: color 300ms; }
  .field--file .field__svg {
    position: absolute;
    width: var(--file-x-svg-dimension);
    height: var(--file-x-svg-dimension);
    top: var(--file-x-svg-top);
    right: var(--file-x-svg-right);
    fill: #00c281;
    transition: fill 300ms, opacity 300ms; }
  .field--file .field__x {
    position: absolute;
    width: var(--file-x-svg-dimension);
    height: var(--file-x-svg-dimension);
    top: var(--file-x-svg-top);
    right: var(--file-x-svg-right);
    opacity: 0;
    z-index: 1;
    cursor: pointer;
    pointer-events: none;
    transition: opacity 300ms, visibility 0ms ease 300ms; }
  .field--file .field__x:before, .field--file .field__x:after {
    content: '';
    position: absolute;
    width: 16px;
    height: 2px;
    top: 50%;
    left: 50%;
    background: var(--file-x-color);
    transition: background 300ms; }
    @media screen and (max-width: 600px) {
      .field--file .field__x:before, .field--file .field__x:after {
        width: 20px; } }
  .field--file .field__x:before {
    transform: translate(-50%, -50%) rotate(45deg); }
  .field--file .field__x:after {
    transform: translate(-50%, -50%) rotate(-45deg); }
  .field--file[data-file]:not([data-file=""]) .field__label {
    pointer-events: none; }
  .field--file[data-file]:not([data-file=""]) .field__svg {
    opacity: 0; }
  .field--file[data-file]:not([data-file=""]) .field__x {
    opacity: 1;
    visibility: visible;
    pointer-events: all; }
  .field--file .field__label:hover .field__text {
    color: var(--file-over-color); }
  .field--file .field__label:hover .field__svg {
    fill: var(--file-svg-over-color); }
  .field--file .field__x:hover:before, .field--file .field__x:hover:after {
    background: var(--file-x-over-color); }
  .field--select .field__label {
    display: block;
    padding: var(--field-label-padding);
    font-family: var(--field-label-font-family);
    font-size: var(--field-label-font-size);
    font-weight: var(--field-label-font-weight);
    line-height: var(--field-label-line-height);
    color: var(--field-label-color); }
  .field--select .tail-select {
    position: relative;
    z-index: 3; }
  .field--select .select-label {
    border: var(--tail-label-border);
    background: var(--tail-label-background);
    transition: var(--tail-label-transition);
    cursor: pointer; }
    .field--select .select-label:hover {
      background: #fff; }
  .field--select .label-inner {
    display: block;
    position: relative;
    padding: var(--tail-label-padding);
    min-height: var(--tail-label-min-height);
    font-family: var(--tail-label-font-family);
    font-size: var(--tail-label-font-size);
    font-weight: var(--tail-label-font-weight);
    line-height: var(--tail-label-line-height);
    text-transform: var(--tail-label-text-transform);
    letter-spacing: var(--tail-label-letter-spacing);
    color: var(--tail-label-color);
    display: flex;
    align-items: center; }
    .field--select .label-inner img {
      order: -1;
      margin-right: 30px; }
    .field--select .label-inner:before {
      content: '';
      position: absolute;
      width: 14px;
      height: 14px;
      top: 50%;
      right: 30px;
      transform: translateY(-50%);
      background: url("../medias/images/icons/pointer.svg") no-repeat center/contain;
      transition: transform 500ms var(--select-ease); }
  .field--select .select-dropdown {
    position: absolute;
    width: 100%;
    top: 0%;
    left: 0;
    opacity: 0;
    background: #fff;
    min-height: var(--tail-label-min-height);
    z-index: -1;
    visibility: hidden;
    pointer-events: none;
    transition: top 500ms var(--select-ease), opacity 500ms var(--select-ease), visibility 0ms ease 500ms;
    box-shadow: #cfcfcf 0 0 18px; }
  .field--select .dropdown-inner {
    overflow: auto;
    max-height: 195px; }
  .field--select .dropdown-option {
    padding: var(--tail-option-padding);
    font-family: var(--tail-option-font-family);
    font-size: var(--tail-option-font-size);
    font-weight: var(--tail-option-font-weight);
    line-height: var(--tail-option-line-height);
    text-transform: var(--tail-option-text-transform);
    letter-spacing: var(--tail-option-letter-spacing);
    color: var(--tail-option-color);
    background: var(--tail-option-background);
    cursor: pointer;
    transition: background 300ms, color 300ms; }
    .field--select .dropdown-option:hover {
      color: var(--tail-option-over-color);
      background: var(--tail-option-over-background); }
    .field--select .dropdown-option.selected {
      background: #f5f5f5; }
    .field--select .dropdown-option.has-image {
      display: flex;
      align-items: center; }
      .field--select .dropdown-option.has-image img {
        order: -1;
        margin-right: 30px; }
  .field--select select {
    width: 100%;
    padding: var(--tail-label-padding);
    font-family: var(--tail-label-font-family);
    font-size: var(--tail-label-font-size);
    font-weight: var(--tail-label-font-weight);
    line-height: var(--tail-label-line-height);
    text-transform: var(--tail-label-text-transform);
    letter-spacing: var(--tail-label-letter-spacing);
    color: var(--tail-label-color);
    border: var(--tail-label-border);
    min-height: var(--tail-label-min-height);
    background: transparent;
    outline: none;
    -webkit-appearance: none;
    background: url("../medias/images/icons/pointer.svg") no-repeat calc(100% - 20px) 50%/12px 12px; }
  .field select::-ms-expand {
    display: none; }
  .field--select .tail-select.active {
    box-shadow: #cfcfcf 0 0 18px; }
    .field--select .tail-select.active .select-label {
      background: #fff;
      border-color: #fff; }
    .field--select .tail-select.active .label-inner:before {
      transform: translateY(-50%) rotate(-180deg); }
    .field--select .tail-select.active .select-dropdown {
      opacity: 1;
      top: 100%;
      visibility: visible;
      pointer-events: all;
      transition: top 500ms var(--select-ease), opacity 500ms var(--select-ease); }
  .field.alt-style {
    margin-bottom: 0; }
    @media screen and (min-width: 1024px) {
      .field.alt-style .select-label {
        border-left: 0;
        border-top: 0;
        border-right: 0; }
      .field.alt-style .label-inner {
        min-height: 47px; } }

:root {
  --auto-fill-color: white;
  --auto-fill-text: #202020;
  --auto-fill-border-color: #202020; }

.dark-bg {
  --auto-fill-color: #202020;
  --auto-fill-text: white;
  --auto-fill-border-color: white; }

.gray-bg {
  --auto-fill-color: #f5f5f5;
  --auto-fill-text: #202020;
  --auto-fill-border-color: #202020; }

/* Change the white to any color ;) */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px var(--auto-fill-color) inset !important;
  -webkit-text-fill-color: var(--auto-fill-text);
  transition: none;
  border-bottom: 1px solid var(--auto-fill-border-color) !important; }

input:-webkit-autofill::first-line {
  font-family: "Aktiv Grotesk", sans-serif;
  font-size: var(--field-font-size); }

.form__title {
  margin-bottom: 30px; }
  @media screen and (max-width: 1024px) {
    .form__title {
      margin-bottom: 25px; } }
  @media screen and (max-width: 768px) {
    .form__title {
      margin-bottom: 20px; } }

.form__title--contact {
  color: #a6a6a6;
  margin-bottom: 45px; }

.form__grid-gaps {
  width: calc(100% + 80px);
  margin-left: -40px;
  margin-right: -40px; }
  @media screen and (max-width: 1200px) {
    .form__grid-gaps {
      width: calc(100% + 50px);
      margin-right: -25px;
      margin-left: -25px; } }
  @media screen and (max-width: 1024px) {
    .form__grid-gaps {
      width: calc(100% + 30px);
      margin-right: -15px;
      margin-left: -15px; } }
  @media screen and (max-width: 900px) {
    .form__grid-gaps {
      width: calc(100% + 10px);
      margin-right: -5px;
      margin-left: -5px; } }

.form__column {
  padding-right: 40px;
  padding-left: 40px; }
  @media screen and (max-width: 1200px) {
    .form__column {
      padding-right: 25px;
      padding-left: 25px; } }
  @media screen and (max-width: 1024px) {
    .form__column {
      padding-right: 15px;
      padding-left: 15px; } }
  @media screen and (max-width: 900px) {
    .form__column {
      padding-right: 5px;
      padding-left: 5px; } }

.form__column--align-center {
  display: flex;
  justify-content: center; }

.component:first-child {
  margin-top: 0; }

.component:last-child {
  margin-bottom: 0; }

.showcase {
  display: block; }
  .showcase__picture {
    width: 100%; }
    .showcase__picture picture {
      width: 100%;
      height: 425px; }
    @media screen and (max-width: 1024px) {
      .showcase__picture {
        padding-top: 38%;
        position: relative; }
        .showcase__picture picture {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          height: auto;
          width: auto; } }
    @media screen and (max-width: 1024px) {
      .showcase__picture {
        padding-top: 45%; } }
  .showcase__content {
    font-size: 4.0625rem;
    display: flex;
    align-items: center;
    background-color: #202020;
    color: white;
    height: 234px;
    padding-top: 20px;
    padding-bottom: 20px;
    font-weight: 700;
    position: relative; }
    @media screen and (max-width: 1440px) {
      .showcase__content {
        font-size: 3.75rem; } }
    @media screen and (max-width: 1024px) {
      .showcase__content {
        font-size: 3.125rem; } }
    @media screen and (max-width: 768px) {
      .showcase__content {
        font-size: 2.5rem; } }
    @media screen and (max-width: 425px) {
      .showcase__content {
        font-size: 1.875rem; } }
    @media screen and (max-width: 375px) {
      .showcase__content {
        font-size: 1.5625rem; } }
    @media screen and (max-width: 320px) {
      .showcase__content {
        font-size: 1.375rem; } }
    @media screen and (max-width: 1200px) {
      .showcase__content {
        height: 180px; } }
    @media screen and (max-width: 1024px) {
      .showcase__content {
        height: 140px; } }
    @media screen and (max-width: 768px) {
      .showcase__content {
        height: 120px; } }
    .showcase__content span {
      position: relative;
      z-index: 2;
      padding-right: 30px; }
    .showcase__content::before {
      content: "";
      position: absolute;
      top: 0;
      height: 100%;
      left: 0;
      background-color: #00c281;
      width: 100%;
      transform-origin: right;
      transition: transform 0.6s cubic-bezier(0.4, 0, 0, 1);
      transform: scaleX(0);
      z-index: 1; }
  .showcase__icon {
    width: 120px;
    height: 120px;
    position: relative;
    margin-left: auto;
    z-index: 2;
    flex-shrink: 0; }
    @media screen and (max-width: 1200px) {
      .showcase__icon {
        width: 100px;
        height: 100px; } }
    @media screen and (max-width: 1024px) {
      .showcase__icon {
        width: 90px;
        height: 90px; } }
    @media screen and (max-width: 768px) {
      .showcase__icon {
        width: 60px;
        height: 60px; } }
    .showcase__icon svg {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%) rotate(-90deg);
      fill: #00c281;
      width: 27px;
      height: 39px;
      z-index: 1; }
      @media screen and (max-width: 768px) {
        .showcase__icon svg {
          width: 18px;
          height: 24px; } }
    .showcase__icon::after {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      border-radius: 50%;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%) scale(1);
      background-color: white;
      z-index: 0;
      transition: transform 0.3s cubic-bezier(0.76, 0, 0.24, 1); }
  .showcase:hover .showcase__content::before {
    transition: transform 0.6s cubic-bezier(0.4, 0, 0, 1);
    transform: scaleX(1);
    transform-origin: left; }
  .showcase:hover .showcase__icon::after {
    transform: translate(-50%, -50%) scale(1.1); }

.shareBtn {
  display: flex;
  align-items: center;
  font-size: 0.8125rem;
  font-weight: 700;
  text-transform: uppercase;
  color: #202020;
  transition: color 0.3s cubic-bezier(0.76, 0, 0.24, 1); }
  .shareBtn svg {
    width: 15px;
    height: 15px;
    margin-right: 20px;
    fill: #202020;
    transition: fill 0.3s cubic-bezier(0.76, 0, 0.24, 1); }
  .shareBtn:hover {
    color: #00c281; }
    .shareBtn:hover svg {
      fill: #00c281; }

.product-thumb__picture {
  width: 100%;
  padding-bottom: 100%;
  background-color: #f5f5f5;
  position: relative; }

.product-thumb__picture-text {
  position: absolute;
  top: 0;
  left: 0;
  color: white;
  font-size: 0.6875rem;
  background-color: #00c281;
  text-transform: uppercase;
  font-weight: 500;
  padding: 14px 24px;
  z-index: 2; }

.product-thumb__picture-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0; }
  .product-thumb__picture-wrapper picture {
    height: 100%;
    width: 100%;
    overflow: hidden; }
    .product-thumb__picture-wrapper picture img {
      transform: scale(1);
      transition: transform 0.3s; }

.product-thumb__text {
  padding-top: 22px;
  padding-bottom: 22px;
  color: #202020; }

.product-thumb__over-title {
  font-size: 0.875rem;
  margin-bottom: 8px; }

.product-thumb__title {
  font-size: 1.5625rem;
  font-weight: bold; }

.product-thumb :hover .product-thumb__picture-wrapper picture img {
  transform: scale(1.05); }

.products-list {
  background-color: white; }
  .products-list__count {
    font-size: 1.375rem;
    color: #202020; }
  .products-list__items {
    margin-bottom: calc(var(--grid-bigger-gaps)*-2); }
    .products-list__items .column {
      padding-bottom: calc(var(--grid-bigger-gaps)*2); }
  .products-list__item {
    width: 100%; }

.jobs-list__container {
  background: #fff; }

.pagination {
  display: flex;
  justify-content: center; }
  .pagination li a, .pagination li span {
    text-align: center;
    display: inline-block;
    font-size: 1.125rem;
    color: #202020;
    line-height: 30px;
    height: 30px;
    width: 30px;
    margin: 0 5px;
    font-weight: 500;
    transition: color 0.3s cubic-bezier(0.76, 0, 0.24, 1); }
    @media screen and (max-width: 600px) {
      .pagination li a, .pagination li span {
        font-size: 1rem;
        line-height: 25px;
        height: 25px;
        width: 25px; } }
  .pagination li a:hover {
    color: rgba(32, 32, 32, 0.4); }
  .pagination li span {
    color: #00c281; }
  .pagination__prev a, .pagination__prev span, .pagination__next a, .pagination__next span {
    position: relative;
    width: 30px; }
    .pagination__prev a svg, .pagination__prev span svg, .pagination__next a svg, .pagination__next span svg {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%) rotate(90deg);
      transition: transform 0.3s cubic-bezier(0.76, 0, 0.24, 1);
      width: 19px;
      height: 26px;
      fill: rgba(32, 32, 32, 0.2); }
  .pagination__prev a svg, .pagination__next a svg {
    fill: #00c281; }
  .pagination__prev a:hover svg, .pagination__next a:hover svg {
    transform: translate(calc(-50% - 10px), -50%) rotate(90deg); }
  .pagination__prev {
    margin-right: 30px; }
    @media screen and (max-width: 768px) {
      .pagination__prev {
        margin-right: 0; } }
  .pagination__next {
    margin-left: 30px; }
    @media screen and (max-width: 768px) {
      .pagination__next {
        margin-left: 0; } }
    .pagination__next a svg, .pagination__next span svg {
      transform: translate(-50%, -50%) rotate(-90deg); }
    .pagination__next a:hover svg {
      transform: translate(calc(-50% + 10px), -50%) rotate(-90deg); }

.footer {
  border-top: 1px solid #202020; }
  .footer__bottom {
    display: flex;
    background-color: #f5f5f5; }
    @media screen and (max-width: 800px) {
      .footer__bottom {
        flex-direction: column; } }
  .footer__logo svg {
    max-width: 100%;
    max-height: 67px;
    width: 340px; }
  .footer__content {
    justify-content: space-between; }
    @media screen and (max-width: 768px) {
      .footer__content > div:not(:first-child) {
        display: none; } }
    .footer__content .column:first-child {
      min-width: 55%; }
  .footer__title {
    font-size: 1.125rem;
    font-weight: 700;
    margin-bottom: 18px; }
  .footer__contact-info {
    font-size: 1.125rem;
    line-height: 2em; }
    .footer__contact-info a:not([class^="btn"]) {
      color: #202020; }
      .footer__contact-info a:not([class^="btn"]):hover {
        text-decoration: underline; }
  .footer__button {
    margin-bottom: -10px; }
    @media screen and (max-width: 1024px) {
      .footer__button {
        padding-bottom: 50px; } }
    @media screen and (max-width: 768px) {
      .footer__button {
        padding-bottom: 0; } }
    .footer__button a:not(:last-child) {
      margin-right: 10px;
      margin-bottom: 10px; }
  .footer__menu li a {
    font-size: 1.125rem;
    padding: 8px 0;
    color: #202020;
    transition: color 0.3s cubic-bezier(0.76, 0, 0.24, 1); }
    .footer__menu li a:hover {
      color: #00c281; }
  .footer__lang {
    background: #202020;
    color: white;
    display: flex;
    align-items: center;
    height: 100px; }
    @media screen and (max-width: 800px) {
      .footer__lang {
        height: 70px; } }
    .footer__lang span {
      padding-right: 15px;
      display: inline-block;
      font-weight: 700; }
    .footer__lang ul {
      display: flex; }
      .footer__lang ul li a {
        color: white;
        padding: 15px;
        transition: color 0.3s cubic-bezier(0.76, 0, 0.24, 1); }
        .footer__lang ul li a.active, .footer__lang ul li a:hover {
          color: #00c281; }
      .footer__lang ul li:last-child {
        position: relative; }
        .footer__lang ul li:last-child::before {
          content: '';
          position: absolute;
          width: 2px;
          height: 13px;
          background-color: white;
          left: 0;
          top: 50%;
          transform: translate(-50%, -50%);
          pointer-events: none; }
  .footer__copyright {
    display: flex;
    align-items: center;
    flex-grow: 1;
    font-size: 0.75rem; }
    @media screen and (max-width: 800px) {
      .footer__copyright {
        padding-top: 20px;
        padding-bottom: 20px; } }
    @media screen and (max-width: 650px) {
      .footer__copyright {
        flex-wrap: wrap; } }
    .footer__copyright span {
      margin-right: 60px; }
      @media screen and (max-width: 900px) {
        .footer__copyright span {
          margin-right: 40px; } }
      .footer__copyright span a {
        color: #202020;
        transition: color 0.3s cubic-bezier(0.76, 0, 0.24, 1); }
        .footer__copyright span a:hover {
          color: #00c281; }
    @media screen and (max-width: 650px) {
      .footer__copyright span:first-child {
        width: 100%;
        margin-bottom: 10px; } }
    .footer__copyright__social {
      display: flex;
      align-items: center;
      margin-left: auto; }
      .footer__copyright__social li a {
        padding-left: 12px;
        padding-right: 12px;
        display: inline-block; }
        .footer__copyright__social li a svg {
          width: 18px;
          height: 18px;
          fill: #202020;
          transition: fill 0.3s cubic-bezier(0.76, 0, 0.24, 1); }
        .footer__copyright__social li a:hover svg {
          fill: #00c281; }

.btn,
.btn--secondary {
  color: #202020;
  position: relative;
  background-color: transparent;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 0.75rem;
  padding: 22px 34px;
  border: 1px solid #202020;
  transition: color 0.3s cubic-bezier(0.76, 0, 0.24, 1); }
  .btn span,
  .btn--secondary span {
    position: relative;
    z-index: 2;
    /*svg{
            fill: $color1;
            height: 1em;
            width: 1em;
            margin-left: 20px;
        }*/ }
  .btn::before,
  .btn--secondary::before {
    content: "";
    position: absolute;
    top: 0;
    height: 100%;
    left: 0;
    background-color: #202020;
    width: 100%;
    transform-origin: right;
    transition: transform 0.3s cubic-bezier(0.4, 0, 0, 1);
    transform: scaleX(0);
    z-index: 1; }
  .btn:hover,
  .btn--secondary:hover {
    color: white; }
    .btn:hover::before,
    .btn--secondary:hover::before {
      transition: transform 0.3s cubic-bezier(0.4, 0, 0, 1);
      transform: scaleX(1);
      transform-origin: left; }
  .btn__with-icon span,
  .btn--secondary__with-icon span {
    display: flex;
    align-items: center; }
  .btn__with-icon svg,
  .btn--secondary__with-icon svg {
    margin-left: 18px;
    fill: #00c281;
    width: 16px;
    height: 16px; }

.btn--secondary {
  color: white;
  background-color: #202020; }
  .btn--secondary::before {
    background-color: #f5f5f5; }
  .btn--secondary:hover {
    color: #202020; }

.dark-bg .btn,
.dark-bg .btn--secondary {
  color: white;
  border: 1px solid white;
  background-color: transparent; }
  .dark-bg .btn::before,
  .dark-bg .btn--secondary::before {
    background-color: white; }
  .dark-bg .btn:hover,
  .dark-bg .btn--secondary:hover {
    color: #202020; }

.installation {
  --block-height: 800px;
  background-color: #202020;
  height: var(--block-height);
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative; }
  @media screen and (max-width: 1024px) {
    .installation {
      --block-height: 500px; } }
  @media screen and (max-width: 768px) {
    .installation {
      --block-height: 300px; } }
  .installation__title {
    position: relative;
    z-index: 2; }
  .installation__play-button {
    /*position: absolute;
        bottom: -75px;
        left: 50%;
        transform: translate(-50%,0);*/
    font-size: 2.5rem;
    cursor: pointer;
    opacity: 1;
    transition: opacity 0.3s cubic-bezier(0.76, 0, 0.24, 1);
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: center;
    /*@media screen and (max-width: 768px) {
            position: static;
            transform: none;
            padding-top: 30px;
        }*/ }
    @media screen and (max-width: 768px) {
      .installation__play-button {
        font-size: 1.4375rem; } }
    @media screen and (max-width: 425px) {
      .installation__play-button {
        font-size: 1.25rem; } }
    .installation__play-button span {
      margin-right: 18px;
      color: white; }
    .installation__play-button svg {
      fill: white;
      height: 50px;
      width: 50px; }
      @media screen and (max-width: 768px) {
        .installation__play-button svg {
          height: 30px;
          width: 30px; } }
    .installation__play-button:hover {
      opacity: 0.6; }
  .installation picture {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    opacity: 0.5; }

.buy-online {
  display: flex;
  flex-wrap: wrap;
  align-items: stretch; }
  .buy-online__picture {
    width: 50%;
    position: relative; }
    @media screen and (max-width: 1024px) {
      .buy-online__picture {
        width: 100%; } }
    .buy-online__picture picture {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0; }
  .buy-online__content {
    width: 50%; }
    @media screen and (max-width: 1024px) {
      .buy-online__content {
        width: 100%;
        padding: 0; } }
  .buy-online__over-title {
    font-size: 2rem; }
    @media screen and (max-width: 425px) {
      .buy-online__over-title {
        font-size: 1.375rem; } }
  .buy-online__title {
    font-size: 3.4375rem;
    font-weight: bold;
    line-height: 1.0909em;
    color: #202020; }
    @media screen and (max-width: 425px) {
      .buy-online__title {
        font-size: 1.375rem; } }
  .buy-online__form .field {
    margin-bottom: 0;
    z-index: 3;
    position: relative; }

.newsletter-subscription {
  background-color: #202020;
  position: relative; }
  .newsletter-subscription__title {
    text-align: center; }
    .newsletter-subscription__title span {
      display: block; }
  .newsletter-subscription__form {
    max-width: 500px;
    margin: auto; }
    .newsletter-subscription__form .field {
      margin-bottom: 0; }
      .newsletter-subscription__form .field__box {
        border: 1px solid #fff; }
      .newsletter-subscription__form .field__text {
        color: #fff; }
      .newsletter-subscription__form .field .field__input {
        padding-right: 55px;
        color: white;
        box-shadow: none !important;
        border-bottom: 2px solid #00c281;
        padding-bottom: 10px;
        padding-top: 10px;
        height: auto; }
        .newsletter-subscription__form .field .field__input:-webkit-autofill, .newsletter-subscription__form .field .field__input:-webkit-autofill:hover, .newsletter-subscription__form .field .field__input:-webkit-autofill:focus, .newsletter-subscription__form .field .field__input:-webkit-autofill:active {
          -webkit-box-shadow: 0 0 0 30px #202020 inset !important; }
      .newsletter-subscription__form .field button {
        position: absolute;
        right: 0;
        top: 0;
        height: 100%;
        width: 36px;
        border: none;
        background: none;
        cursor: pointer; }
        .newsletter-subscription__form .field button:focus {
          outline: none;
          box-shadow: none; }
        .newsletter-subscription__form .field button svg {
          position: absolute;
          top: 50%;
          left: 50%;
          fill: #00c281;
          width: 19px;
          height: 26px;
          transition: transform 0.3s cubic-bezier(0.76, 0, 0.24, 1);
          transform: translate(-50%, -50%) rotate(-90deg); }
        .newsletter-subscription__form .field button:hover svg {
          transform: translate(calc(50% + 10px), -50%) rotate(-90deg); }
  .newsletter-subscription .grid-pattern {
    width: var(--pattern-width-normal);
    height: 100%;
    left: 0;
    top: 0; }

.banner-contact {
  background: #202020; }
  .banner-contact__container {
    width: 100%;
    position: relative; }
  .banner-contact__title, .banner-contact__email, .banner-contact__phone {
    font-family: "Aktiv Grotesk", sans-serif;
    color: #fff; }
  .banner-contact__title {
    padding-bottom: 60px; }
    @media screen and (max-width: 500px) {
      .banner-contact__title {
        padding-bottom: 40px; } }
    @media screen and (max-width: 400px) {
      .banner-contact__title {
        padding-bottom: 30px; } }
  .banner-contact__email, .banner-contact__phone {
    font-size: 3.125rem;
    font-weight: 700;
    opacity: 1;
    transition: color 300ms;
    position: relative; }
    @media screen and (max-width: 1024px) {
      .banner-contact__email, .banner-contact__phone {
        font-size: 2.5rem; } }
    @media screen and (max-width: 768px) {
      .banner-contact__email, .banner-contact__phone {
        font-size: 1.875rem; } }
    @media screen and (max-width: 425px) {
      .banner-contact__email, .banner-contact__phone {
        font-size: 1.625rem; } }
    @media screen and (max-width: 375px) {
      .banner-contact__email, .banner-contact__phone {
        font-size: 1.25rem; } }
    .banner-contact__email::after, .banner-contact__phone::after {
      content: '';
      bottom: -12px;
      left: 0;
      position: absolute;
      height: 6px;
      width: 100%;
      background-color: #00c281; }
    .banner-contact__email:hover, .banner-contact__phone:hover {
      color: #00c281; }
  .banner-contact__email {
    margin-bottom: 30px; }
    @media screen and (max-width: 400px) {
      .banner-contact__email {
        margin-bottom: 20px; } }
  .banner-contact__down-button {
    position: absolute;
    right: var(--x-padding-80);
    bottom: 75px; }
    @media screen and (max-width: 1024px) {
      .banner-contact__down-button {
        position: static;
        padding-bottom: var(--y-padding-100); } }
  .banner-contact__pattern {
    width: var(--pattern-width-large);
    height: 75px;
    left: 0;
    bottom: 0;
    background-position: 0px 1px; }
    @media screen and (max-width: 500px) {
      .banner-contact__pattern {
        height: 40px; } }

.contact-form {
  background: #fff; }
  .contact-form__align-center {
    text-align: center; }
  .contact-form__title-color {
    color: #00c281; }
  .contact-form__sub-title {
    color: #999999; }
  .contact-form__sub-title--center-black {
    text-align: center;
    color: #202020; }
  .contact-form__sub-title--black {
    color: #202020; }
  .contact-form__sub-title--white {
    color: white;
    text-align: center;
    padding-top: var(--y-padding-40); }

.jobs-form__title {
  text-align: center; }

.jobs-form__title-color {
  color: #00c281; }

.become-a-member-form {
  background-color: white; }
  .become-a-member-form__title {
    text-align: center; }
    .become-a-member-form__title span {
      display: block; }

.testimonials-slider {
  --margin-width: 280px;
  --padding-text: 40px;
  --picture-ratio: 56.03448%;
  --text-width: 480px;
  background-color: #f5f5f5;
  overflow-x: hidden;
  position: relative; }
  @media screen and (max-width: 1024px) {
    .testimonials-slider {
      --picture-ratio: 100%;
      --text-width: 380px; } }
  @media screen and (max-width: 800px) {
    .testimonials-slider {
      --picture-ratio: 125%;
      --text-width: 350px;
      --padding-text: 30px; } }
  @media screen and (max-width: 700px) {
    .testimonials-slider {
      --margin-width: 0;
      --picture-ratio: 75%; } }
  .testimonials-slider__testimonials {
    background-color: white;
    max-width: var(--text-width);
    position: absolute;
    top: 50%;
    left: var(--x-padding-180);
    z-index: 4;
    transform: translate(0, -50%); }
    @media screen and (max-width: 700px) {
      .testimonials-slider__testimonials {
        position: relative;
        transform: none;
        max-width: 100%;
        left: auto;
        background-color: #f5f5f5; } }
  .testimonials-slider__testimonial {
    color: #202020;
    padding: var(--padding-text);
    padding-bottom: 100px; }
  .testimonials-slider__testimonial-text {
    color: rgba(32, 32, 32, 0.5);
    font-size: 1.125rem;
    line-height: 1.6666em;
    padding-top: 35px;
    position: relative; }
    .testimonials-slider__testimonial-text::before {
      content: '';
      position: absolute;
      background-image: url(../medias/images/icons/quote.png);
      width: 21px;
      height: 21px;
      background-size: cover;
      left: 0;
      top: 2px; }
  .testimonials-slider__testimonial-author {
    color: #202020;
    font-size: 0.875rem;
    font-weight: 500;
    margin-top: 20px; }
  .testimonials-slider__arrows {
    position: absolute;
    left: var(--padding-text);
    bottom: var(--padding-text);
    z-index: 5; }
  .testimonials-slider__pager {
    position: absolute;
    right: var(--padding-text);
    bottom: var(--padding-text);
    z-index: 5;
    font-size: 1.125rem;
    color: #999999; }
    .testimonials-slider__pager span:first-child {
      color: #202020; }
  .testimonials-slider__pictures .slick-list {
    padding-left: var(--margin-width);
    overflow: visible; }
  .testimonials-slider__picture {
    width: 100%;
    padding-bottom: var(--picture-ratio);
    position: relative; }
    .testimonials-slider__picture picture {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0; }

.generic-slider {
  /* the parent */ }
  @media (max-width: 500px) {
    .generic-slider {
      padding-top: 80px; } }
  .generic-slider__wrapper {
    position: relative;
    z-index: 4; }
  .generic-slider__arrows {
    position: absolute;
    top: -50px;
    right: 0; }
    @media (max-width: 500px) {
      .generic-slider__arrows {
        top: -50px; } }
  .generic-slider__slide {
    background-color: #999999;
    width: 100%;
    padding-bottom: 55.35714%;
    position: relative; }
    .generic-slider__slide picture {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0; }
  .generic-slider .slick-slide {
    margin: 0 var(--grid-gaps); }
  .generic-slider .slick-list {
    margin-left: calc(var(--grid-gaps)*-1);
    margin-right: calc(var(--grid-gaps)*-1);
    overflow: visible; }

.search-results {
  background-color: white; }

.about-us__text {
  margin-bottom: 60px; }

.about-us__title {
  margin-bottom: 35px; }

.about-us__paragraph:not(:last-child) {
  margin-bottom: 25px; }

.online-sales__container {
  background: #fff; }

.online-sales__title {
  color: #000;
  padding-top: var(--y-padding-100); }

.online-sales__subtitle {
  color: #000;
  padding-top: var(--y-padding-100);
  padding-bottom: var(--y-padding-30); }

.links {
  border-top: 1px solid #202020; }
  .links__link {
    position: relative;
    border-bottom: 1px solid #202020; }
  .links__img-and-title {
    display: flex;
    align-items: center;
    padding-left: 30px;
    padding-right: 140px; }
    @media screen and (max-width: 1024px) {
      .links__img-and-title {
        padding-right: 110px; } }
    @media screen and (max-width: 600px) {
      .links__img-and-title {
        padding-left: 10px;
        padding-right: 55px; } }
  .links__picture {
    object-fit: contain;
    width: 170px;
    min-width: 170px;
    height: auto; }
    @media screen and (max-width: 1024px) {
      .links__picture {
        width: 120px;
        min-width: 120px; } }
    @media screen and (max-width: 600px) {
      .links__picture {
        display: none; } }
  .links__box {
    position: relative;
    cursor: pointer;
    padding: 20px 0;
    min-height: 155px;
    display: flex;
    align-items: center; }
    @media screen and (max-width: 1200px) {
      .links__box {
        padding-top: 50px;
        padding-bottom: 50px; } }
    @media screen and (max-width: 1024px) {
      .links__box {
        padding-top: 40px;
        padding-bottom: 40px; } }
    @media screen and (max-width: 600px) {
      .links__box {
        padding-top: 20px;
        padding-bottom: 20px; } }
    @media screen and (max-width: 768px) {
      .links__box {
        min-height: 130px; } }
    @media screen and (max-width: 600px) {
      .links__box {
        min-height: 110px; } }
  .links__box--smaller-y-padding {
    padding-top: 20px;
    padding-bottom: 20px; }
  .links__content {
    padding-left: 65px; }
    @media screen and (max-width: 1024px) {
      .links__content {
        padding-left: 35px; } }
    @media screen and (max-width: 600px) {
      .links__content {
        padding-left: 0; } }
  .links__box--no-image .links__content {
    padding-left: 0; }
  .links__category {
    font-size: 0.875rem;
    color: #202020;
    margin-bottom: 10px; }
  .links__category--jobs {
    font-size: 0.8125rem;
    color: #00c281;
    font-weight: 500;
    text-transform: uppercase; }
  .links__title {
    line-height: 1.5;
    color: #202020;
    transition: color 0.3s cubic-bezier(0.76, 0, 0.24, 1); }
    .links__title mark {
      background-color: rgba(32, 32, 32, 0.1);
      font-style: normal;
      color: #202020; }
  .links__icon {
    position: absolute;
    width: 78px;
    height: 78px;
    top: 50%;
    right: 30px;
    transform: translateY(-50%); }
    @media screen and (max-width: 1024px) {
      .links__icon {
        width: 60px;
        height: 60px; } }
    @media screen and (max-width: 600px) {
      .links__icon {
        width: 34px;
        height: 34px;
        right: 10px; } }
    .links__icon::before {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background: #202020;
      border-radius: 50%;
      transition: background 300ms, transform 300ms cubic-bezier(0.33, 1, 0.68, 1); }
  .links__box:hover .links__icon:before {
    background: #00c281;
    transform: scale(1.20512); }
  .links__svg {
    position: absolute;
    width: 22px;
    height: 30px;
    top: 50%;
    left: 50%;
    fill: white;
    transform: translate(-50%, -50%) rotate(-90deg); }
    @media screen and (max-width: 600px) {
      .links__svg {
        width: 15px;
        height: 21px; } }
  @media screen and (max-width: 600px) {
    .links--keep-images-mobile .links__picture {
      display: block; } }
  @media screen and (max-width: 400px) {
    .links--keep-images-mobile .links__picture {
      width: 80px;
      min-width: 80px; } }
  @media screen and (max-width: 350px) {
    .links--keep-images-mobile .links__picture {
      display: none; } }
  @media screen and (max-width: 600px) {
    .links--keep-images-mobile .links__content {
      padding-left: 15px; } }
  @media screen and (max-width: 350px) {
    .links--keep-images-mobile .links__content {
      padding-left: 0px; } }

.back-to-top {
  --width: 55px;
  --height: 55px;
  position: fixed;
  width: var(--width);
  height: var(--height);
  bottom: 30px;
  right: 30px;
  z-index: 5;
  transition: opacity 0.3s;
  opacity: 0;
  pointer-events: none; }
  @media screen and (max-width: 768px) {
    .back-to-top {
      bottom: 15px;
      right: 15px; } }
  .back-to-top svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(180deg);
    fill: white;
    width: 21px;
    height: 29px;
    pointer-events: none;
    z-index: 2; }
  .back-to-top::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #00c281;
    border-radius: 50%;
    transform: scale(1);
    transition: transform 0.3s;
    z-index: 1;
    cursor: pointer; }
  .back-to-top:hover::after {
    transform: scale(1.1); }

.overlay-dropdown {
  --dropdown-menu-width: 400px;
  position: fixed;
  width: 100%;
  top: var(--header-height);
  bottom: 0;
  visibility: hidden;
  pointer-events: none;
  z-index: 20; }
  @media screen and (max-width: 1200px) {
    .overlay-dropdown {
      --dropdown-menu-width: 375px; } }
  @media screen and (max-width: 1024px) {
    .overlay-dropdown {
      --dropdown-menu-width: 50%; } }
  @media screen and (max-width: 900px), screen and (max-height: 600px) {
    .overlay-dropdown {
      display: none; } }
  .overlay-dropdown__background {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(0, 0, 0, 0);
    transition: background 800ms;
    z-index: -1; }
  .overlay-dropdown__container {
    position: absolute;
    width: 100%;
    height: 0px;
    top: 0;
    left: 0;
    background: #202020;
    overflow: hidden;
    transition: height 0.8s cubic-bezier(0.76, 0, 0.24, 1); }
  .overlay-dropdown__section {
    position: absolute;
    display: flex;
    width: 100%;
    flex-wrap: nowrap;
    visibility: hidden;
    opacity: 0;
    max-height: calc(100vh - 125px);
    overflow-y: auto;
    overflow-x: hidden;
    scrollbar-width: none;
    height: 100%; }
    .overlay-dropdown__section::-webkit-scrollbar {
      display: none; }
  .overlay-dropdown__sub-section {
    width: var(--dropdown-menu-width);
    height: 100%;
    max-height: calc(100vh - 125px);
    overflow-y: scroll;
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */ }
    .overlay-dropdown__sub-section::-webkit-scrollbar {
      display: none; }
    @media screen and (max-width: 1300px) {
      .overlay-dropdown__sub-section {
        padding: 60px; } }
    @media screen and (max-width: 1200px) {
      .overlay-dropdown__sub-section {
        padding: 50px 40px; } }
  .overlay-dropdown__picture {
    flex-grow: 1;
    height: 100%;
    width: calc( 100% - var(--dropdown-menu-width)); }
    .overlay-dropdown__picture picture {
      height: 100%;
      position: relative; }
      .overlay-dropdown__picture picture img {
        opacity: 0;
        transition: opacity 0.3s;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0; }
        .overlay-dropdown__picture picture img.active {
          opacity: 1; }
  .overlay-dropdown__sub-section-container {
    display: inline-block;
    text-align: left;
    transform: translateY(100px); }
  .overlay-dropdown__sub-section-title {
    color: white;
    margin-bottom: 30px; }
    @media screen and (max-width: 1400px) {
      .overlay-dropdown__sub-section-title {
        margin-bottom: 30px; } }
    @media screen and (max-width: 1200px) {
      .overlay-dropdown__sub-section-title {
        margin-bottom: 20px; } }
  .overlay-dropdown__close {
    position: absolute;
    left: var(--dropdown-menu-width);
    top: 50%;
    width: 72px;
    height: 72px;
    transform: translate(-50%, -50%);
    cursor: pointer;
    z-index: 2; }
    .overlay-dropdown__close > span {
      width: 21px;
      height: 21px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 1; }
      .overlay-dropdown__close > span::before, .overlay-dropdown__close > span::after {
        content: '';
        width: 2px;
        height: 29px;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%) rotate(45deg);
        background-color: #00c281;
        z-index: 2; }
      .overlay-dropdown__close > span::after {
        transform: translate(-50%, -50%) rotate(-45deg); }
    .overlay-dropdown__close::after {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      background-color: white;
      border-radius: 50%;
      transform: scale(1);
      transition: transform 0.3s; }
    .overlay-dropdown__close:hover::after {
      transform: scale(1.1); }
  .overlay-dropdown__list-item:not(:last-child) {
    margin-bottom: 24px; }
    @media screen and (max-width: 1200px), screen and (max-height: 700px) {
      .overlay-dropdown__list-item:not(:last-child) {
        margin-bottom: 10px; } }
  .overlay-dropdown__link {
    font: 1.5625rem/1.2 "Aktiv Grotesk", sans-serif;
    color: white;
    transition: color 300ms cubic-bezier(0.33, 1, 0.68, 1); }
    @media screen and (max-width: 1200px) {
      .overlay-dropdown__link {
        padding-right: 10px; } }
    @media screen and (max-width: 1024px) {
      .overlay-dropdown__link {
        font-size: 1.25rem; } }
    .overlay-dropdown__link:hover {
      color: #00c281; }

.overlay-search {
  position: absolute;
  width: 0%;
  top: 0;
  left: 0;
  overflow: hidden;
  z-index: 10; }
  .overlay-search__background {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(0, 0, 0, 0);
    transition: background 800ms cubic-bezier(0.65, 0, 0.35, 1);
    z-index: -1; }
  .overlay-search__container {
    position: absolute;
    width: 100%;
    max-width: 560px;
    height: 0px;
    top: 0;
    right: 0;
    background: white;
    overflow: hidden; }
  .overlay-search__form {
    opacity: 0;
    transform: translateY(100px);
    transition: transform 800ms cubic-bezier(0.65, 0, 0.35, 1), opacity 800ms cubic-bezier(0.65, 0, 0.35, 1); }

.overlay-share {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  visibility: hidden;
  pointer-events: none;
  z-index: 15; }
  .overlay-share__background {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(0, 0, 0, 0);
    transition: background 800ms;
    z-index: -1; }
  .overlay-share__container {
    position: absolute;
    padding: 85px;
    width: 520px;
    top: 50%;
    left: 50%;
    background: #fff;
    text-align: center;
    opacity: 0;
    z-index: 151;
    pointer-events: none;
    transform: translateX(-50%);
    transition: opacity 800ms cubic-bezier(0.65, 0, 0.35, 1), transform 800ms cubic-bezier(0.65, 0, 0.35, 1); }
    @media screen and (max-width: 1200px) {
      .overlay-share__container {
        width: 490px; } }
    @media screen and (max-width: 1024px) {
      .overlay-share__container {
        width: 400px;
        padding: 50px; } }
    @media screen and (max-width: 600px) {
      .overlay-share__container {
        width: auto;
        right: 20px;
        left: 20px;
        padding: 50px 20px;
        transform: none; } }
  .overlay-share__title {
    margin-bottom: 20px; }
  .overlay-share__close {
    position: absolute;
    width: 16px;
    height: 16px;
    top: 20px;
    right: 20px;
    cursor: pointer; }
    .overlay-share__close:hover:before, .overlay-share__close:hover:after {
      background: #03b177; }
    .overlay-share__close:before, .overlay-share__close:after {
      content: '';
      position: absolute;
      width: 16px;
      height: 2px;
      top: 50%;
      left: 50%;
      background: #00c281;
      transition: background 300ms; }
    .overlay-share__close:before {
      transform: translate(-50%, -50%) rotate(45deg); }
    .overlay-share__close:after {
      transform: translate(-50%, -50%) rotate(-45deg); }
  .overlay-share__list {
    position: relative;
    margin-top: 16px; }
  .overlay-share__list-li {
    display: inline-block; }
    .overlay-share__list-li:not(:last-child) {
      margin-right: 20px; }
      @media screen and (max-width: 600px) {
        .overlay-share__list-li:not(:last-child) {
          margin-right: 10px; } }
  .overlay-share__link {
    position: relative;
    width: 45px;
    height: 45px;
    background: #00c281;
    border-radius: 50%;
    transition: background 300ms; }
    .overlay-share__link:hover {
      background: #03b177; }
  .overlay-share__svg {
    position: absolute;
    width: 16px;
    height: 16px;
    top: 50%;
    left: 50%;
    fill: #fff;
    transform: translate(-50%, -50%); }

.overlay-menu {
  --x-padding: 40px;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  visibility: hidden;
  pointer-events: none;
  z-index: 10; }
  @media screen and (max-width: 800px) {
    .overlay-menu {
      --x-padding: 30px; } }
  .overlay-menu__background {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(0, 0, 0, 0);
    transition: background 800ms;
    z-index: -1; }
  .overlay-menu__close {
    position: fixed;
    right: 25px;
    top: 20px;
    color: white;
    text-transform: uppercase;
    font-size: 0.6875rem;
    font-weight: bold;
    cursor: pointer;
    display: flex;
    align-items: center;
    opacity: 0;
    transition: color 0.3s cubic-bezier(0.76, 0, 0.24, 1), opacity 0.3s cubic-bezier(0.76, 0, 0.24, 1); }
    .overlay-menu__close span {
      display: inline-block;
      padding-right: 22px; }
    .overlay-menu__close div {
      width: 18px;
      height: 18px;
      position: relative; }
      .overlay-menu__close div::before, .overlay-menu__close div::after {
        content: '';
        position: absolute;
        height: 22px;
        width: 2px;
        top: 50%;
        left: 50%;
        background-color: white;
        transform: translate(-50%, -50%) rotate(45deg);
        transition: background-color 0.3s cubic-bezier(0.76, 0, 0.24, 1); }
      .overlay-menu__close div::after {
        transform: translate(-50%, -50%) rotate(-45deg); }
    .overlay-menu__close:hover {
      color: #00c281; }
      .overlay-menu__close:hover div::before, .overlay-menu__close:hover div::after {
        background-color: #00c281; }
  .overlay-menu__container {
    position: absolute;
    width: 500px;
    padding: 60px 0;
    top: 0;
    right: -500px;
    bottom: 0;
    background: #202020;
    overflow-y: auto;
    overflow-x: hidden;
    scrollbar-width: none;
    transition: right 800ms cubic-bezier(0.65, 0, 0.35, 1); }
    @media screen and (max-width: 600px) {
      .overlay-menu__container {
        width: 100%;
        right: -100%;
        padding-top: 50px; } }
    .overlay-menu__container::-webkit-scrollbar {
      display: none; }
  .overlay-menu__main-level, .overlay-menu__secondary-level {
    opacity: 0;
    transform: translateX(200px);
    transition: opacity 1000ms cubic-bezier(0.65, 0, 0.35, 1), transform 1000ms cubic-bezier(0.65, 0, 0.35, 1); }
  .overlay-menu__second-level-li:last-child {
    margin-bottom: 30px; }
  .overlay-menu__second-level-button {
    position: relative;
    margin: 0 var(--x-padding);
    padding: 20px 0 0;
    padding-right: 30px;
    font: 700 1.25rem/1.2 "Aktiv Grotesk", sans-serif;
    color: white;
    text-transform: uppercase;
    letter-spacing: 1px;
    transition: padding 700ms cubic-bezier(0.65, 0, 0.35, 1), color 0.3s cubic-bezier(0.76, 0, 0.24, 1); }
    .overlay-menu__second-level-button:hover {
      color: #00c281; }
  .overlay-menu__second-level-arrow {
    position: absolute;
    right: 0;
    bottom: 0.6em;
    width: 15px;
    height: 8px;
    transform: translate(0, 50%) rotate(0deg);
    transition: transform 0.3s cubic-bezier(0.76, 0, 0.24, 1); }
    .overlay-menu__second-level-arrow span {
      width: 0;
      height: 0;
      border-left: 8px solid transparent;
      border-right: 8px solid transparent;
      border-top: 8px solid #00c281;
      position: absolute;
      top: 0;
      left: 0; }
  .overlay-menu__third-level {
    height: 0;
    overflow: hidden;
    opacity: 0;
    transition: opacity 700ms cubic-bezier(0.65, 0, 0.35, 1); }
  .overlay-menu__third-level-li--button-item {
    padding: 20px var(--x-padding) 0; }
  .overlay-menu__third-level-li:first-child .overlay-menu__third-level-link {
    margin-top: 20px; }
  .overlay-menu__third-level-li:last-child .overlay-menu__third-level-link {
    margin-bottom: 20px; }
  .overlay-menu__third-level-li-button {
    margin: 20px var(--x-padding) 0; }
  .overlay-menu__third-level-link:not(.btn) {
    margin: 10px var(--x-padding) 0;
    font: 1.25rem/1.5 "Aktiv Grotesk", sans-serif;
    color: white; }
  .overlay-menu__secondary-level {
    padding: 20px var(--x-padding) 0; }
    .overlay-menu__secondary-level li:not(:last-child) {
      margin-bottom: 16px; }
    .overlay-menu__secondary-level li a {
      color: white;
      font-size: 1.3125rem;
      transition: color 300ms cubic-bezier(0.76, 0, 0.24, 1); }
      .overlay-menu__secondary-level li a:hover {
        color: #00c281; }

.show-overlay-search:not(.closing-overlay-search) .header__nav__primary__search svg {
  opacity: 0; }

.show-overlay-search:not(.closing-overlay-search) .header__nav__primary__search > span {
  opacity: 1; }

.showOverlayMenu .hamburger span:nth-child(1) {
  width: 0%;
  top: 16px;
  left: 50%;
  opacity: 0; }

.showOverlayMenu .hamburger span:nth-child(2) {
  transform: rotate(45deg); }

.showOverlayMenu .hamburger span:nth-child(3) {
  transform: rotate(-45deg); }

.showOverlayMenu .hamburger span:nth-child(4) {
  width: 0%;
  top: 16px;
  left: 50%;
  opacity: 0; }

.showOverlayMenu.closingMenu .hamburger span {
  width: 100%;
  opacity: 1;
  left: 0%;
  transform: rotate(0deg);
  transition: 250ms cubic-bezier(0.76, 0, 0.24, 1); }
  .showOverlayMenu.closingMenu .hamburger span:nth-child(1) {
    top: 0px; }
  .showOverlayMenu.closingMenu .hamburger span:nth-child(2), .showOverlayMenu.closingMenu .hamburger span:nth-child(3) {
    top: 8px; }
  .showOverlayMenu.closingMenu .hamburger span:nth-child(4) {
    top: 16px; }

/*
|--------------------------------------------------------------------------
| RUBRIQUE OUVERTE
|--------------------------------------------------------------------------
*/
.rubric.js-accordion-opened {
  border-bottom: 7px solid #202020; }
  .rubric.js-accordion-opened .rubric__transition {
    opacity: 1;
    transform: none;
    transition: opacity 700ms cubic-bezier(0.65, 0, 0.35, 1) 100ms, transform 700ms cubic-bezier(0.65, 0, 0.35, 1) 100ms; }
  .rubric.js-accordion-opened .rubric__title {
    color: #00c281; }
  .rubric.js-accordion-opened .rubric__icon:before {
    background: #00c281; }
  .rubric.js-accordion-opened .rubric__icon .rubric__svg {
    transform: translate(-50%, -50%) rotate(180deg); }

.show-back-to-top .back-to-top {
  opacity: 1;
  pointer-events: all; }

/*
|--------------------------------------------------------------------------
| OVERLAY VISIBLE
|--------------------------------------------------------------------------
*/
.show-overlay-dropdown .overlay-dropdown {
  visibility: visible;
  pointer-events: all; }
  .show-overlay-dropdown .overlay-dropdown__background {
    background: rgba(0, 0, 0, 0.4); }
  .show-overlay-dropdown .overlay-dropdown__section {
    transition: opacity 800ms cubic-bezier(0.65, 0, 0.35, 1), visibility 0ms ease 800ms; }
  .show-overlay-dropdown .overlay-dropdown__sub-section-container {
    transition: transform 800ms cubic-bezier(0.65, 0, 0.35, 1); }

/*
|--------------------------------------------------------------------------
| SECTION VISIBLE
|--------------------------------------------------------------------------
*/
.show-overlay-dropdown[data-section="products"] .header [data-section="products"] span::after {
  transform: translate(0, -50%) rotate(180deg); }

.show-overlay-dropdown[data-section="products"] .overlay-dropdown__container {
  height: 100%; }

.show-overlay-dropdown[data-section="products"] .overlay-dropdown [data-section="products"] {
  opacity: 1;
  visibility: visible;
  transition: opacity 800ms cubic-bezier(0.65, 0, 0.35, 1); }
  .show-overlay-dropdown[data-section="products"] .overlay-dropdown [data-section="products"] .overlay-dropdown__sub-section-container {
    transform: none;
    transition: transform 800ms cubic-bezier(0.65, 0, 0.35, 1); }

.show-overlay-dropdown[data-section="choose-technoflex"] .header [data-section="choose-technoflex"] span::after {
  transform: translate(0, -50%) rotate(180deg); }

.show-overlay-dropdown[data-section="choose-technoflex"] .overlay-dropdown__container {
  height: 100%; }

.show-overlay-dropdown[data-section="choose-technoflex"] .overlay-dropdown [data-section="choose-technoflex"] {
  opacity: 1;
  visibility: visible;
  transition: opacity 800ms cubic-bezier(0.65, 0, 0.35, 1); }
  .show-overlay-dropdown[data-section="choose-technoflex"] .overlay-dropdown [data-section="choose-technoflex"] .overlay-dropdown__sub-section-container {
    transform: none;
    transition: transform 800ms cubic-bezier(0.65, 0, 0.35, 1); }

.show-overlay-dropdown[data-section="where-to-find"] .header [data-section="where-to-find"] span::after {
  transform: translate(0, -50%) rotate(180deg); }

.show-overlay-dropdown[data-section="where-to-find"] .overlay-dropdown__container {
  height: 100%; }

.show-overlay-dropdown[data-section="where-to-find"] .overlay-dropdown [data-section="where-to-find"] {
  opacity: 1;
  visibility: visible;
  transition: opacity 800ms cubic-bezier(0.65, 0, 0.35, 1); }
  .show-overlay-dropdown[data-section="where-to-find"] .overlay-dropdown [data-section="where-to-find"] .overlay-dropdown__sub-section-container {
    transform: none;
    transition: transform 800ms cubic-bezier(0.65, 0, 0.35, 1); }

/*
|--------------------------------------------------------------------------
| OVERLAY SE FERME
|--------------------------------------------------------------------------
*/
.show-overlay-dropdown.closing-overlay-dropdown .overlay-dropdown__background {
  background: rgba(0, 0, 0, 0); }

/*
|--------------------------------------------------------------------------
| OVERLAY VISIBLE
|--------------------------------------------------------------------------
*/
.show-overlay-search .btn-search__x {
  opacity: 1; }

.show-overlay-search .btn-search__svg {
  opacity: 0; }

.show-overlay-search .overlay-search {
  width: 100%;
  top: var(--header-height);
  bottom: 0; }
  .show-overlay-search .overlay-search__background {
    background: rgba(0, 0, 0, 0.4); }
  .show-overlay-search .overlay-search__form {
    opacity: 1;
    transform: none; }

/*
|--------------------------------------------------------------------------
| OVERLAY SE FERME
|--------------------------------------------------------------------------
*/
.show-overlay-search.closing-overlay-search .btn-search__x {
  opacity: 0; }

.show-overlay-search.closing-overlay-search .btn-search__svg {
  opacity: 1; }

.show-overlay-search.closing-overlay-search .overlay-search__background {
  background: rgba(0, 0, 0, 0); }

.show-overlay-search.closing-overlay-search .overlay-search__form {
  opacity: 0;
  transform: translateY(100px); }

/*
|--------------------------------------------------------------------------
| OVERLAY VISIBLE
|--------------------------------------------------------------------------
*/
.show-overlay-share .overlay-share {
  pointer-events: all;
  visibility: visible; }
  .show-overlay-share .overlay-share__background {
    background: rgba(0, 0, 0, 0.4); }
  .show-overlay-share .overlay-share__container {
    opacity: 1;
    pointer-events: all;
    transform: translate(-50%, -50%); }
    @media screen and (max-width: 600px) {
      .show-overlay-share .overlay-share__container {
        transform: translateY(-50%); } }

/*
|--------------------------------------------------------------------------
| OVERLAY SE FERME
|--------------------------------------------------------------------------
*/
.show-overlay-share.closing-overlay-share .overlay-share__background {
  background: rgba(0, 0, 0, 0); }

.show-overlay-share.closing-overlay-share .overlay-share__container {
  opacity: 0;
  transform: translateX(-50%); }
  @media screen and (max-width: 600px) {
    .show-overlay-share.closing-overlay-share .overlay-share__container {
      transform: none; } }

/*
|--------------------------------------------------------------------------
| OVERLAY VISIBLE
|--------------------------------------------------------------------------
*/
.show-overlay-menu .overlay-menu {
  visibility: visible;
  pointer-events: all; }
  .show-overlay-menu .overlay-menu__close {
    opacity: 1; }
  .show-overlay-menu .overlay-menu__background {
    background: rgba(0, 0, 0, 0.4); }
  .show-overlay-menu .overlay-menu__container {
    right: 0px; }
    @media screen and (max-width: 600px) {
      .show-overlay-menu .overlay-menu__container {
        right: 0%; } }
  .show-overlay-menu .overlay-menu__main-level, .show-overlay-menu .overlay-menu__secondary-level {
    opacity: 1;
    transform: none; }

/*
|--------------------------------------------------------------------------
| OVERLAY SE FERME
|--------------------------------------------------------------------------
*/
.show-overlay-menu.closing-overlay-menu .overlay-menu__background {
  background: rgba(0, 0, 0, 0); }

.show-overlay-menu.closing-overlay-menu .overlay-menu__close {
  opacity: 0; }

.show-overlay-menu.closing-overlay-menu .overlay-menu__container {
  right: -500px; }
  @media screen and (max-width: 600px) {
    .show-overlay-menu.closing-overlay-menu .overlay-menu__container {
      right: -100%; } }

.show-overlay-menu.closing-overlay-menu .overlay-menu__main-level, .show-overlay-menu.closing-overlay-menu .overlay-menu__secondary-level {
  opacity: 0;
  transform: translateX(200px); }

/*
|--------------------------------------------------------------------------
| SOUS-MENU OUVERT
|--------------------------------------------------------------------------
*/
.js-accordion-opened .overlay-menu__second-level-arrow {
  transform: translate(0, 50%) rotate(-180deg); }

.js-accordion-opened .overlay-menu__third-level {
  opacity: 1; }

.curtain {
  position: fixed;
  width: 100vw;
  top: 0;
  left: 0;
  bottom: 0;
  background: #fff;
  z-index: 200;
  opacity: 0;
  pointer-events: none;
  transition: opacity 500ms; }
  .curtain__logo {
    position: absolute;
    width: 300px;
    top: 50%;
    left: 50%;
    opacity: 0;
    transform: translate(-50%, -50%);
    transition: opacity 500ms; }
  .curtain__loader {
    position: absolute;
    width: 60px;
    height: 60px;
    top: calc(50% - 30px);
    left: calc(50% - 30px);
    background: linear-gradient(to bottom, #00c281 10%, rgba(255, 255, 255, 0) 100%);
    opacity: 0;
    border-radius: 50%;
    animation: load 1s infinite linear;
    transition: opacity 500ms; }
  .curtain__loader:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: linear-gradient(to right, rgba(255, 255, 255, 0) 50%, #00c281 50%);
    border-radius: 100%; }
  .curtain__loader:after {
    content: '';
    position: absolute;
    width: 90%;
    height: 90%;
    margin: auto;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: #fff;
    border-radius: 50%; }

@keyframes load {
  from {
    transform: rotate(0deg); }
  to {
    transform: rotate(360deg); } }

.show-once-transition {
  pointer-events: none; }
  .show-once-transition .curtain {
    opacity: 1;
    z-index: 51; }
    .show-once-transition .curtain__logo {
      opacity: 1; }

.show-default-transition {
  pointer-events: none; }
  .show-default-transition .curtain {
    opacity: 1; }
    .show-default-transition .curtain__loader {
      opacity: 1; }

.show-map-transition {
  pointer-events: none; }
  .show-map-transition .curtain {
    opacity: 1;
    z-index: 51; }
    .show-map-transition .curtain__loader {
      opacity: 1; }

:root {
  --scrollfire-fade-up__opacity: 0;
  --scrollfire-fade-up__transform: translateY(50px);
  --scrollfire-fade-up__transition: opacity 800ms, transform 800ms cubic-bezier(.215,.61,.355,1);
  --scrollfire-fade-up__opacity-fire: 1;
  --scrollfire-fade-up__transform-fire: none; }
  @media screen and (max-width: 1024px) {
    :root {
      --scrollfire-fade-up__transform: translateY(25px); } }

.js-scrollfire.fade-up,
.js-premiumScrollfire > .fade-up,
.show-once-transition .js-scrollfire.fade-up[data-fire],
.show-once-transition .js-premiumScrollfire[data-fire] > .fade-up {
  opacity: var(--scrollfire-fade-up__opacity);
  transform: var(--scrollfire-fade-up__transform);
  transition: var(--scrollfire-fade-up__transition); }

.js-scrollfire[data-fire].fade-up,
.js-premiumScrollfire[data-fire] > .fade-up {
  opacity: var(--scrollfire-fade-up__opacity-fire);
  transform: var(--scrollfire-fade-up__transform-fire); }

.js-scrollfire.curtain-top, .js-premiumScrollfire.curtain-top,
.js-scrollfire.curtain-right, .js-premiumScrollfire.curtain-right {
  overflow: hidden; }
  .js-scrollfire.curtain-top:after, .js-premiumScrollfire.curtain-top:after,
  .js-scrollfire.curtain-right:after, .js-premiumScrollfire.curtain-right:after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: #fff;
    z-index: 10; }
  .js-scrollfire.curtain-top img, .js-premiumScrollfire.curtain-top img,
  .js-scrollfire.curtain-right img, .js-premiumScrollfire.curtain-right img {
    transform: scale(1.1);
    transition: transform 1000ms cubic-bezier(0.83, 0, 0.17, 1); }
  .js-scrollfire.curtain-top[data-fire] img, .js-premiumScrollfire.curtain-top[data-fire] img,
  .js-scrollfire.curtain-right[data-fire] img, .js-premiumScrollfire.curtain-right[data-fire] img {
    transform: none; }

.js-scrollfire.curtain-top:after, .js-premiumScrollfire.curtain-top:after {
  transition: top 1000ms cubic-bezier(0.83, 0, 0.17, 1); }

.js-scrollfire.curtain-top[data-fire]:after, .js-premiumScrollfire.curtain-top[data-fire]:after {
  top: 100%; }

.js-scrollfire.curtain-right:after, .js-premiumScrollfire.curtain-right:after {
  transition: right 1000ms cubic-bezier(0.83, 0, 0.17, 1); }

.js-scrollfire.curtain-right[data-fire]:after, .js-premiumScrollfire.curtain-right[data-fire]:after {
  right: 100%; }

.js-scrollfire-delay-enabled .delay1, .js-scrollfire-delay-enabled .delay1:after, .js-scrollfire-delay-enabled .delay1 > img {
  transition-delay: 400ms; }

.js-scrollfire-delay-enabled .delay2, .js-scrollfire-delay-enabled .delay2:after, .js-scrollfire-delay-enabled .delay2 > img {
  transition-delay: 500ms; }

.js-scrollfire-delay-enabled .delay3, .js-scrollfire-delay-enabled .delay3:after, .js-scrollfire-delay-enabled .delay3 > img {
  transition-delay: 600ms; }

.js-scrollfire-delay-enabled .delay4, .js-scrollfire-delay-enabled .delay4:after, .js-scrollfire-delay-enabled .delay4 > img {
  transition-delay: 700ms; }

.js-scrollfire-delay-enabled .delay5, .js-scrollfire-delay-enabled .delay5:after, .js-scrollfire-delay-enabled .delay5 > img {
  transition-delay: 800ms; }

.js-scrollfire-delay-enabled .delay6, .js-scrollfire-delay-enabled .delay6:after, .js-scrollfire-delay-enabled .delay6 > img {
  transition-delay: 900ms; }

.js-scrollfire-delay-enabled .delay7, .js-scrollfire-delay-enabled .delay7:after, .js-scrollfire-delay-enabled .delay7 > img {
  transition-delay: 1000ms; }
