.product-thumb{

    &__picture{
        width: 100%;
        padding-bottom: 100%;
        background-color: $color3;
        position: relative;
    }

    &__picture-text{
        position: absolute;
        top: 0;
        left: 0;
        color: white;
        font-size: rem(11);
        background-color: $color1;
        text-transform: uppercase;
        font-weight: 500;
        padding: 14px 24px;
        z-index: 2;
    }

    &__picture-wrapper{
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        picture{
            height: 100%;
            width: 100%;
            overflow: hidden;
            img{
                transform: scale(1);
                transition: transform 0.3s;
            }
        }
    }

    &__text{
        padding-top: 22px;
        padding-bottom: 22px;
        color: $color2;
    }

    &__over-title{
        font-size: rem(14);
        margin-bottom: 8px;
    }

    &__title{
        font-size: rem(25);
        font-weight: bold;
    }

    :hover{
        .product-thumb__picture-wrapper{
            picture{
                img{
                    transform: scale(1.05)
                }
            }
        }
    }
}