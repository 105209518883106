.jobs-list {
    &__container {
        background: #fff;
    
        @extend .x-padding-180;
        @extend .y-padding-100;
    }
}


