.showOverlayMenu {
    .hamburger {
        span {
            &:nth-child(1) { width: 0%; top: 16px; left: 50%; opacity: 0; }
            &:nth-child(2) { transform: rotate(45deg); }
            &:nth-child(3) { transform: rotate(-45deg); }
            &:nth-child(4) { width: 0%; top: 16px; left: 50%; opacity: 0; }
        }
    }
    &.closingMenu {
        .hamburger span {
            width: 100%;
            opacity: 1;
            left: 0%;
            transform: rotate(0deg);
            transition: 250ms $easeInOutCubic;

            &:nth-child(1) { top: 0px; }
            &:nth-child(2), &:nth-child(3) { top: 8px; }
            &:nth-child(4) { top: 16px; }
        }
    }
}