.generic-slider{
    @extend .top-padding-160;
    @media( max-width: 500px ) { padding-top: 80px; }

    &__wrapper{
      position: relative;
      z-index: 4;
    }

    &__arrows{
      position: absolute;
      top: -50px;
      right: 0;
      @media( max-width: 500px ) { top: -50px; }
  }

    &__slide{
        background-color: $color4;
        width: 100%;
        padding-bottom: 55.35714%; // 620px / 1120px
        position: relative;
        picture{
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
        }
    }

    .slick-slide {
        margin: 0 var(--grid-gaps);
      }
    /* the parent */
    .slick-list {
      margin-left: calc(var(--grid-gaps)*-1);
      margin-right: calc(var(--grid-gaps)*-1);
      overflow: visible;
    }
}