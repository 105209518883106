/*
|--------------------------------------------------------------------------
| OVERLAY VISIBLE
|--------------------------------------------------------------------------
*/
.show-overlay-share {
    
    .overlay-share {
        pointer-events: all;
        visibility: visible;

        &__background { background: rgba(#000, 0.4); }

        &__container {
            opacity: 1;
            pointer-events: all;
            transform: translate(-50%, -50%);

            @media screen and (max-width: 600px) { transform: translateY(-50%); }
        }
    }
}


/*
|--------------------------------------------------------------------------
| OVERLAY SE FERME
|--------------------------------------------------------------------------
*/
.show-overlay-share.closing-overlay-share {
    .overlay-share {
        &__background { background: rgba(#000, 0); }
        &__container {
            opacity: 0;
            transform: translateX(-50%);

            @media screen and (max-width: 600px) { transform: none; }
        }
    }
}
