.component{

    &:first-child{ margin-top: 0; }
    &:last-child{ margin-bottom: 0; }

    &--form{
        @extend .x-padding-180;
        @extend .y-padding-130;
    }

    &--installation{
        //Pas de padding à gérér
    }

    &--buy-online{
        @extend .y-padding-160;
    }

    &--similar-products{
        @extend .y-padding-130;
    }
}