.shareBtn{
    display: flex;
    align-items: center;
    font-size: rem(13);
    font-weight: 700;
    text-transform: uppercase;
    color: $color2;
    transition: color 0.3s $easeInOutCubic;
    svg{
        width: 15px;
        height: 15px;
        margin-right: 20px;
        fill: $color2;
        transition: fill 0.3s $easeInOutCubic;
    }

    &:hover{
        color: $color1;
        svg{
            fill: $color1;
        }
    }
}