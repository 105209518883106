:root {
    --breadcrumbs-color: #{$color4};
    --breadcrumbs-color-hover: #{$color2};
}
.dark-bg{
    --breadcrumbs-color: white;
    --breadcrumbs-color-hover: rgba(255,255,255,0.6);
}

.breadcrumbs{ 

    height: 70px;
    display: flex;
    align-items: center;
    overflow-y: auto;
    ul{
        display: flex;
        align-items: center;
        li{
            position: relative;
            &:not(:first-child){ padding-left: 13px; }
            &:not(:last-child){ padding-right: 17px; }

            @media screen and (max-width: 768px) {
                &:not(:first-child){ padding-left: 9px; }
                &:not(:last-child){ padding-right: 15px; }
            }

            a,span{
                color: var(--breadcrumbs-color);
                font-weight: 700;
                font-size: rem(12);
                display: inline-block;
                text-transform: uppercase;
                transition: color 0.3s $easeInOutCubic;

                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
            }

            a{
                svg{
                    width: 14px;
                    height: 13px;
                    fill: var(--breadcrumbs-color);
                    transition: fill 0.3s $easeInOutCubic;
                }
                &:hover{
                    color: var(--breadcrumbs-color-hover);
                    svg{
                        fill: var(--breadcrumbs-color-hover);
                    }
                }
            }

            &:not(:last-child)::after{
                content: '';
                position: absolute;
                top: calc(50%);
                right: 0;
                transform: translate(0,-50%);
                font-size: rem(14);
                color: var(--breadcrumbs-color);
                width: 4px;
                height: 4px;
                border-radius: 50%;
                background-color: var(--breadcrumbs-color);
            }

            &:last-child{
                span{
                    font-weight: 700;
                    max-width: 160px;
                }
            }
        }
    }
}