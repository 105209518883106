.contact-form {
    @extend .x-padding-120;
    @extend .top-padding-130;
    @extend .bottom-padding-150;
    background: #fff;

    &__title {
        @extend .huge-title;
        @extend .bottom-padding-60;
    }

    &__title--job {
        @extend .bottom-padding-100;
    }

    &__align-center {
        text-align: center;
    }

    &__title-color {
        color: $color1;
    }

    &__sub-title {
        @extend .large-title;
        @extend .bottom-padding-30;
        color: $color4;
    }

    &__sub-title--center-black {
        text-align: center;
        color: $color2;
    }
    &__sub-title--black {
        color: $color2;
    }
    &__sub-title--white {
        color: white;
        text-align: center;
        padding-top: var(--y-padding-40)
    }
}