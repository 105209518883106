.filter-list{
    display: flex;
    flex-wrap: wrap;
    margin-bottom: -10px;

    @media screen and (max-width: 1024px) {
        display: none;
    }

    li{
        margin-bottom: 10px;
        &:not(:last-child){
            margin-right: 20px;
            @media screen and (max-width: 768px) {
                margin-right: 10px;
            }
        }

        .filter-list__item{
            padding: 16px 20px;
            display: inline-block;
            border: 1px solid rgba($color4,0.3);
            background-color: white;
            text-transform: uppercase;
            font-size: rem(13);
            color: $color2;
            font-weight: 500;
            cursor: pointer;
            transition: color 0.3s $easeInOutCubic, background-color 0.3s $easeInOutCubic;
            white-space: nowrap;

            &:hover{
                color: rgba($color2,0.3);
            }

            &--active{
                background-color: $color1;
                color: white;
                &:hover{
                    background-color: $color5;
                    color: white;
                }
            }
        }
    }
}

.filter-list-mobile{
    display: none;

    @media screen and (max-width: 1024px) {
        display: block;
    }

    .field{
        margin-bottom: 0;
    }
}