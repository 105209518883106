.home-products{
    @extend .x-padding-120;
    @extend .top-padding-130;
    background-color: white;
    overflow-x: hidden;
    position: relative;

    &__over-title{
        @extend .over-title;
    }

    &__title{
        @extend .huge-title;
        @extend .top-padding-40;
        @extend .bottom-padding-80;
        span{
            display: block;
        }
        @media screen and (max-width: 1024px) {
            padding-bottom: 40px;
        }
    }

    &__text{
        p{
            font-size: rem(17);
            line-height: 2em; // 34px/17px
        }
    }

    &__button{
        @extend .top-padding-70;
        @media screen and (max-width: 1024px) {
            padding-top: 40px;
            padding-bottom: 30px;
        }
    }

    &__content{
        display: flex;
        align-items: center;
        align-items: stretch;

        @media screen and (max-width: 1024px) {
            flex-wrap: wrap;
        }
    }

    &__content-left{
        @extend .right-padding-120;
        //width: 36.333333%; // 436px/1200px
        z-index: 2;
        background: #fff;
        margin-left: calc(var(--x-padding-120) * -1);
        padding-left: var(--x-padding-120);
        display: flex;
        align-items: center;

        @media screen and (max-width: 1024px) {
            width: 100%;
            padding-bottom: 70px;
        }
    }

    &__content-right{
        width: 63.666666%;

        @media screen and (max-width: 1024px) {
            width: 100%;
            z-index: 3;
            position: relative;
        }
    }

    &__content-right-wrapper{
        margin-right: calc(var(--x-padding-120) * -1);
        padding-right: var(--x-padding-120);
    }

    &__picture{
        @extend .top-padding-130;

        @media screen and (max-width: 768px) {
            margin-left: calc(var(--x-padding-120)* -1);
            margin-right: calc(var(--x-padding-120)* -1);
        }

        &>div{
            position: relative;
            width: 100%;
            padding-top: 50%;
            @media screen and (max-width: 1500px) { padding-top: 60%; }
            @media screen and (max-width: 600px) { padding-top: 75%; }
            picture{
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
            }
        }
    }

    .grid-pattern{
        top: 0;
        bottom: 0;
        left: 0;
        width: var(--pattern-width-normal);
    }

    &__over-title,
    &__title,
    &__text,
    &__button,
    &__picture{
        z-index: 3;
        position: relative;
    }
}