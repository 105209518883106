.footer{

    border-top: 1px solid $color2;

    &__top{
        @extend .x-padding-120;
        @extend .top-padding-100;
        @extend .bottom-padding-130;
    }
    &__bottom{
        display: flex;
        background-color: $color3;

        @media screen and (max-width: 800px) {
            flex-direction: column;
        }
    }

    &__logo{
        @extend .bottom-padding-80;
        svg{
            max-width: 100%;
            max-height: 67px;
            width: 340px;
        }
    }

    &__content{
        justify-content: space-between;
        @media screen and (max-width: 768px) {
            &>div:not(:first-child){
                display: none;
            }
        }

        .column:first-child{
            min-width: 55%;
        }
    }

    &__title{
        font-size: rem(18);
        font-weight: 700;
        margin-bottom: 18px;
    }

    &__contact-info{
        font-size: rem(18);
        line-height: 2em; //45px/18px
        a:not([class^="btn"]){
            color: $color2;

            &:hover{
                text-decoration: underline;
            }
        }
    }

    &__button{
        @extend .top-padding-30;
        @media screen and (max-width: 1024px) {
            padding-bottom: 50px;
        }
        @media screen and (max-width: 768px) {
            padding-bottom: 0;
        }

        margin-bottom: -10px;

        a{
            &:not(:last-child){
                margin-right: 10px;
                margin-bottom: 10px;
            }
        }
    }

    &__menu{
        li{
            a{
                font-size: rem(18);
                padding: 8px 0;
                color: $color2;
                transition: color 0.3s $easeInOutCubic;

                &:hover{
                    color: $color1;
                }
            }
        }
    }

    &__lang{
        @extend .x-padding-120;
        background: $color2;
        color: white;
        display: flex;
        align-items: center;
        height: 100px;
        @media screen and (max-width: 800px) {
            height: 70px;
        }
        span{
            padding-right: 15px;
            display: inline-block;
            font-weight: 700;
        }
        ul{
            display: flex;
            li{
                a{
                    color: white;
                    padding: 15px;
                    transition: color 0.3s $easeInOutCubic;
                    &.active,
                    &:hover{
                        color: $color1;
                    }
                }

                &:last-child{
                    position: relative;
                    &::before{
                        content:'';
                        position: absolute;
                        width: 2px;
                        height: 13px;
                        background-color: white;
                        left: 0;
                        top: 50%;
                        transform: translate(-50%,-50%);
                        pointer-events: none;
                    }
                }
            }
        }
    }

    &__copyright{
        @extend .x-padding-80;
        display: flex;
        align-items: center;
        flex-grow: 1;
        font-size: rem(12);

        @media screen and (max-width: 800px) {
            padding-top: 20px;
            padding-bottom: 20px;
        }

        @media screen and (max-width: 650px) {
            flex-wrap: wrap;
        }

        span{
            margin-right: 60px;
            @media screen and (max-width: 900px) { margin-right: 40px; }
            a{
                color: $color2;
                transition: color 0.3s $easeInOutCubic;
                &:hover{
                    color: $color1;
                }
            }
        }

        @media screen and (max-width: 650px) {
            span:first-child{
                width: 100%;
                margin-bottom: 10px;
            }
        }

        &__social{
            display: flex;
            align-items: center;
            margin-left: auto;
            li{
                a{
                    padding-left: 12px;
                    padding-right: 12px;
                    display: inline-block;
                    svg{
                        width: 18px;
                        height: 18px;
                        fill: $color2;
                        transition: fill 0.3s $easeInOutCubic;
                    }
                    &:hover{
                        svg{
                            fill: $color1;
                        }
                    }
                }
            }
        }
    }
}