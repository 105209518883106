.overlay-menu {

    --x-padding: 40px;
    @media screen and (max-width: 800px) { --x-padding: 30px; }

    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    visibility: hidden;
    pointer-events: none;
    z-index: 10;

    &__background {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: rgba(#000, 0);
        transition: background 800ms;
        z-index: -1;
    }

    &__close{
        position: fixed;
        right: 25px;
        top: 20px;
        color: white;
        text-transform: uppercase;
        font-size: rem(11);
        font-weight: bold;
        cursor: pointer;
        display: flex;
        align-items: center;
        opacity: 0;
        transition: color 0.3s $easeInOutCubic, opacity 0.3s $easeInOutCubic;
        span{
            display: inline-block;
            padding-right: 22px;
        }
        div{
            width: 18px;
            height: 18px;
            position: relative;
            &::before,
            &::after{
                content: '';
                position: absolute;
                height: 22px;
                width: 2px;
                top: 50%;
                left: 50%;
                background-color: white;
                transform: translate(-50%,-50%) rotate(45deg);
                transition: background-color 0.3s $easeInOutCubic;
            }
            &::after{
                transform: translate(-50%,-50%) rotate(-45deg);
            }
        }

        &:hover{
            color: $color1;
            div{
                &::before,
                &::after{
                    background-color: $color1;
                }
            }
        }
    }

    &__container {
        position: absolute;
        width: 500px;
        padding: 60px 0;
        top: 0;
        right: -500px;
        bottom: 0;
        background: $color2;
        overflow-y: auto;
		overflow-x: hidden;
        scrollbar-width: none;
        transition: right 800ms $easeInOutQuart;

        @media screen and (max-width: 600px) { width: 100%; right: -100%; padding-top: 50px; }

        &::-webkit-scrollbar { display: none; }
    }

    &__main-level,
    &__secondary-level{
        opacity: 0;
        transform: translateX(200px);
        transition: opacity 1000ms $easeInOutQuart, transform 1000ms $easeInOutQuart; 
    }

    &__second-level-li:last-child {
        margin-bottom: 30px;
        
    }

    &__second-level-button {
        position: relative;
        margin: 0 var(--x-padding);
        padding: 20px 0 0;
        padding-right: 30px;
        font: 700 rem(20)/1.2 $font1;
        color: white;
        text-transform: uppercase;
        letter-spacing: 1px;
        transition: padding 700ms $easeInOutQuart, color 0.3s $easeInOutCubic;

        &:hover{
            color: $color1;
        }
    }

    &__second-level-arrow {
        position: absolute;
        right: 0;
        bottom: 0.6em;
        width: 15px;
        height: 8px;
        transform: translate(0,50%) rotate(0deg);
        transition: transform 0.3s $easeInOutCubic;
        span{
            width: 0; 
            height: 0; 
            border-left: 8px solid transparent;
            border-right: 8px solid transparent;
            border-top: 8px solid $color1;
            position: absolute;
            top: 0;
            left: 0;
        }
    }
    
    &__third-level {
        height: 0;
        overflow: hidden;
        opacity: 0;
        transition: opacity 700ms $easeInOutQuart;
    }

    &__third-level-li {
        &--button-item{
            padding: 20px var(--x-padding) 0;
        }
    }
    &__third-level-li:first-child &__third-level-link{ margin-top: 20px; }
    &__third-level-li:last-child &__third-level-link{ margin-bottom: 20px; }

    &__third-level-li-button {
        margin: 20px var(--x-padding) 0;
    }
    
    &__third-level-link:not(.btn) {
        margin: 10px var(--x-padding) 0;
        font: rem(20)/1.5 $font1;
        color: white;
    }
    
    &__secondary-level {
        padding: 20px var(--x-padding) 0;
        li{
            &:not(:last-child){margin-bottom: 16px;}
            a{
                color: white;
                font-size: rem(21);
                transition: color 300ms $easeInOutCubic;

                &:hover{
                    color: $color1;
                }
            }
        }
    }
    
}