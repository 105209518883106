/*
|--------------------------------------------------------------------------
| PADDING X
|--------------------------------------------------------------------------
*/
:root {
    --x-padding-180: 180px;
    --x-padding-120: 120px;
    --x-padding-80: 80px;
    --x-padding-50: 50px;
    --x-padding-40: 40px;

    @media screen and (min-width: 1801px) {
        --x-padding-180: 15vw;
        --x-padding-120: 12vw;
    }
    @media screen and (max-width: 1400px) {
        --x-padding-180: 140px;
        --x-padding-120: 110px;
    }
    @media screen and (max-width: 1200px) {
        --x-padding-180: 100px;
        --x-padding-120: 100px;
    }
    @media screen and (max-width: 1024px) {
        --x-padding-180: 50px;
        --x-padding-120: 50px;
        --x-padding-80: 50px;
    }
    @media screen and (max-width: 768px) {
        --x-padding-180: 30px;
        --x-padding-120: 30px;
        --x-padding-80: 30px;
        --x-padding-50: 30px;
        --x-padding-40: 30px;
    }
    @media screen and (max-width: 600px) {
        --x-padding-180: 25px;
        --x-padding-120: 25px;
        --x-padding-80: 25px;
        --x-padding-50: 25px;
        --x-padding-40: 25px;
    }
    @media screen and (max-width: 320px) {
        --x-padding-50: 20px;
    }
}

.x-padding-180 { @extend .right-padding-180; @extend .left-padding-180; }
.right-padding-180 { padding-right: var(--x-padding-180); }
.left-padding-180 { padding-left:  var(--x-padding-180); }

.x-padding-120 { @extend .right-padding-120; @extend .left-padding-120; }
.right-padding-120 { padding-right: var(--x-padding-120); }
.left-padding-120 { padding-left:  var(--x-padding-120); }

.x-padding-80 { @extend .right-padding-80; @extend .left-padding-80; }
.right-padding-80 { padding-right: var(--x-padding-80); }
.left-padding-80 { padding-left:  var(--x-padding-80); }

.x-padding-50 { @extend .right-padding-50; @extend .left-padding-50; }
.right-padding-50 { padding-right: var(--x-padding-50); }
.left-padding-50 { padding-left:  var(--x-padding-50); }

.x-padding-40 { @extend .right-padding-40; @extend .left-padding-40; }
.right-padding-40 { padding-right: var(--x-padding-40); }
.left-padding-40 { padding-left:  var(--x-padding-40); }



/*
|--------------------------------------------------------------------------
| PADDING Y
|--------------------------------------------------------------------------
*/
:root {
    --y-padding-190: 190px;
    --y-padding-170: 170px;
    --y-padding-160: 160px;
    --y-padding-150: 150px;
    --y-padding-130: 130px;
    --y-padding-100: 100px;
    --y-padding-90: 90px;
    --y-padding-80: 80px;
    --y-padding-70: 70px;
    --y-padding-60: 60px;
    --y-padding-50: 50px;
    --y-padding-40: 40px;
    --y-padding-30: 30px;
    
    @media screen and (max-width: 1400px) {
        --y-padding-190: 160px;
        --y-padding-170: 150px;
        --y-padding-160: 140px;
        --y-padding-150: 130px;
        --y-padding-130: 110px;
    }
    @media screen and (max-width: 1200px) {
        --y-padding-190: 140px;
        --y-padding-170: 150px;
        --y-padding-160: 130px;
        --y-padding-150: 120px;
        --y-padding-130: 110px;
    }
    @media screen and (max-width: 1024px) {
        --y-padding-190: 120px;
        --y-padding-170: 110px;
        --y-padding-160: 110px;
        --y-padding-150: 110px;
        --y-padding-130: 100px;
        --y-padding-100: 90px;
        --y-padding-90:  80px;
        --y-padding-80:  70px;
        --y-padding-60:  50px;
        --y-padding-50:  40px;
    }
    @media screen and (max-width: 768px)  {
        --y-padding-190: 100px;
        --y-padding-170: 90px;
        --y-padding-160: 90px;
        --y-padding-150: 90px;
        --y-padding-130: 80px;
        --y-padding-100: 80px;
        --y-padding-90:  70px;
        --y-padding-80:  60px;
        --y-padding-70:  50px;
        --y-padding-60:  40px;
        --y-padding-50:  30px;
        --y-padding-40:  30px;
    }
    @media screen and (max-width: 500px)  {
        --y-padding-190: 50px;
        --y-padding-170: 50px;
        --y-padding-160: 50px;
        --y-padding-150: 50px;
        --y-padding-130: 50px;
        --y-padding-100: 50px;
        --y-padding-90:  50px;
        --y-padding-80:  50px;
        --y-padding-70:  40px;
    }
}

.y-padding-190 { @extend .top-padding-190; @extend .bottom-padding-190; }
.top-padding-190 { padding-top: var(--y-padding-190); }
.bottom-padding-190 { padding-bottom: var(--y-padding-190); }

.y-padding-170 { @extend .top-padding-170; @extend .bottom-padding-170; }
.top-padding-170 { padding-top: var(--y-padding-170); }
.bottom-padding-170 { padding-bottom: var(--y-padding-170); }

.y-padding-160 { @extend .top-padding-160; @extend .bottom-padding-160; }
.top-padding-160 { padding-top: var(--y-padding-160); }
.bottom-padding-160 { padding-bottom: var(--y-padding-160); }

.y-padding-150 { @extend .top-padding-150; @extend .bottom-padding-150; }
.top-padding-150 { padding-top: var(--y-padding-150); }
.bottom-padding-150 { padding-bottom: var(--y-padding-150); }

.y-padding-130 { @extend .top-padding-130; @extend .bottom-padding-130; }
.top-padding-130 { padding-top: var(--y-padding-130); }
.bottom-padding-130 { padding-bottom: var(--y-padding-130); }

.y-padding-100 { @extend .top-padding-100; @extend .bottom-padding-100; }
.top-padding-100 { padding-top: var(--y-padding-100); }
.bottom-padding-100 { padding-bottom: var(--y-padding-100); }

.y-padding-90 { @extend .top-padding-90; @extend .bottom-padding-90; }
.top-padding-90 { padding-top: var(--y-padding-90); }
.bottom-padding-90 { padding-bottom: var(--y-padding-90); }

.y-padding-80 { @extend .top-padding-80; @extend .bottom-padding-80; }
.top-padding-80 { padding-top: var(--y-padding-80); }
.bottom-padding-80 { padding-bottom: var(--y-padding-80); }

.y-padding-70 { @extend .top-padding-70; @extend .bottom-padding-70; }
.top-padding-70 { padding-top: var(--y-padding-70); }
.bottom-padding-70 { padding-bottom: var(--y-padding-70); }

.y-padding-60 { @extend .top-padding-60; @extend .bottom-padding-60; }
.top-padding-60 { padding-top: var(--y-padding-60); }
.bottom-padding-60 { padding-bottom: var(--y-padding-60); }

.y-padding-50 { @extend .top-padding-50; @extend .bottom-padding-50; }
.top-padding-50 { padding-top: var(--y-padding-50); }
.bottom-padding-50 { padding-bottom: var(--y-padding-50); }

.y-padding-40 { @extend .top-padding-40; @extend .bottom-padding-40; }
.top-padding-40 { padding-top: var(--y-padding-40); }
.bottom-padding-40 { padding-bottom: var(--y-padding-40); }

.y-padding-30 { @extend .top-padding-30; @extend .bottom-padding-30; }
.top-padding-30 { padding-top: var(--y-padding-30); }
.bottom-padding-30 { padding-bottom: var(--y-padding-30); }



/*
|--------------------------------------------------------------------------
| MARGIN Y
|--------------------------------------------------------------------------
*/
:root {
    --y-margin-60: 60px;
    @media screen and (max-width: 1024px) {
        --y-margin-60:  40px;
    }
    @media screen and (max-width: 768px)  {
        --y-margin-60:  30px;
    }
}

.y-margin-60 { @extend .top-margin-60; @extend .bottom-margin-60; }
.top-margin-60 { margin-top: var(--y-margin-60); }
.bottom-margin-60 { margin-bottom: var(--y-margin-60); }


/*
|--------------------------------------------------------------------------
| CONTENT
|--------------------------------------------------------------------------
*/
body{
    font-family: $font1;
}
p {
    @include paragraph();
}

a {
	display: inline-block;
	text-decoration: none;
	cursor: pointer;
}


/*
|--------------------------------------------------------------------------
| IMAGES
|--------------------------------------------------------------------------
*/
picture {
    display: block;
    font-size: 0;

    img { width: 100%; height: 100%; }

    [data-object-fit='cover'] {
        object-fit: cover;
        font-family: 'object-fit: cover;';
    }

    [data-object-fit='contain'] {
        object-fit: contain;
        font-family: 'object-fit: contain;';
    }
}

:root{
    --pattern-img: url('/themes/core/assets/medias/images/content/general/grid-pattern.png');
}
.dark-bg{
    --pattern-img: url('/themes/core/assets/medias/images/content/general/grid-pattern-dark-bg.png');
}


.grid-pattern{

    --pattern-width-large: 820px;
    --pattern-width-normal: 280px;
    --pattern-width-smaller: 150px;
    @media screen and (max-width: 1200px) {
        --pattern-width-large: 520px;
        --pattern-width-normal: 230px;
    }
    @media screen and (max-width: 1024px) {
        --pattern-width-large: 425px;
        --pattern-width-normal: 150px;
        --pattern-width-smaller: 100px;
    }
    @media screen and (max-width: 768px) {
        --pattern-width-large: 323px;
        --pattern-width-normal: 100px;
        --pattern-width-smaller: 75px;
    }
    @media screen and (max-width: 500px) { 
        --pattern-width-large: 220px;
        --pattern-width-normal: 50px;
        --pattern-width-smaller: 50px;
    }

    pointer-events: none;
    position: absolute;
    background-image: var(--pattern-img);
    opacity: 0.25;
    z-index: 2;

    &--top-right-horizontal{
        top: 0;
        right: 0;
        height: 70px;
        width: 371px;
        background-position: right top;

        @media screen and (max-width: 900px) { 
            height: 50px;
        }

        @media screen and (max-width: 500px) { 
            height: 30px;
        }
    }

    &--bottom-left-vertical{
        bottom: 0;
        left: 0;
        height: 870px;
        width: var(--pattern-width-normal);
        background-position: 0px 1px;
    }

    &--right-horizontal{
        top: 80px;
        right: 0;
        bottom: 0;
        width: var(--pattern-width-smaller);
        background-position: 1px 1px;
    }

    
}

.table-wrapper::-webkit-scrollbar,
.scroll-style::-webkit-scrollbar{
    -webkit-appearance: none;
    width: 5px;
    height: 5px;
    background-color: white;
}
.table-wrapper::-webkit-scrollbar-thumb,
.scroll-style::-webkit-scrollbar-thumb{
    background-color: $color4;
}