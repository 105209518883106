.overlay-dropdown {

    --dropdown-menu-width: 400px;

    position: fixed;
    width: 100%;
    top: var(--header-height);
    bottom: 0;
    visibility: hidden;
    pointer-events: none;
    z-index: 20;

    @media screen and (max-width: 1200px) { --dropdown-menu-width: 375px; }
    @media screen and (max-width: 1024px) { --dropdown-menu-width: 50%; }

    @media screen and (max-width: 900px), screen and (max-height: 600px) { display: none; }

    &__background {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: rgba(#000, 0);
        transition: background 800ms;
        z-index: -1;
    }
    
    &__container {
        position: absolute;
        width: 100%;
        height: 0px;
        top: 0;
        left: 0;
        background: $color2;
        overflow: hidden;
        transition: height 0.8s $easeInOutCubic;
    }

    &__section {
        position: absolute;
        display: flex;
        width: 100%;
        flex-wrap: nowrap;
        visibility: hidden;
        opacity: 0;
        max-height: calc(100vh - 125px);
        overflow-y: auto;
		overflow-x: hidden;
        scrollbar-width: none;
        height: 100%;

        &::-webkit-scrollbar { display: none; }
    }

    &__sub-section {
        @extend .x-padding-50;
        @extend .y-padding-90;
        width: var(--dropdown-menu-width);
        height: 100%;
        max-height: calc(100vh - 125px);
        overflow-y: scroll;
        -ms-overflow-style: none;  /* IE and Edge */
        scrollbar-width: none;  /* Firefox */
        &::-webkit-scrollbar { display: none; }

        @media screen and (max-width: 1300px) { padding: 60px; }
        @media screen and (max-width: 1200px) { padding: 50px 40px; }
    }

    &__picture {
        flex-grow: 1;
        height: 100%;
        width: calc( 100% - var(--dropdown-menu-width) );
        picture{
            height: 100%;
            position: relative;

            img{
                opacity: 0;
                transition: opacity 0.3s;

                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;

                &.active{
                    opacity: 1;
                }
            }
        }
    }

    &__sub-section-container {
        display: inline-block;
        text-align: left;
        transform: translateY(100px);
    }

    &__sub-section-title {
        @extend .large-title;
        color: white;
        margin-bottom: 30px;

        @media screen and (max-width: 1400px) { margin-bottom: 30px; }
        @media screen and (max-width: 1200px) { margin-bottom: 20px; }
    }

    &__btn {
        @extend .top-padding-50;
    }

    &__close {
        position: absolute;
        left: var(--dropdown-menu-width);
        top: 50%;
        width: 72px;
        height: 72px;
        transform: translate(-50%,-50%);
        cursor: pointer;
        z-index: 2;

        &>span{
            width: 21px;
            height: 21px;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%,-50%);
            z-index: 1;

            &::before,
            &::after{
                content: '';
                width: 2px;
                height: 29px;
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%,-50%) rotate(45deg);
                background-color: $color1;
                z-index: 2;
            }
            &::after{
                transform: translate(-50%,-50%) rotate(-45deg);
            }
        }

        &::after{
            content: '';
            position: absolute;
            width: 100%;
            height: 100%;
            background-color: white;
            border-radius: 50%;
            transform: scale(1);
            transition: transform 0.3s;
        }

        &:hover{
            &::after{
                transform: scale(1.1);
            }
        }
    }

    &__list-item {
        &:not(:last-child) {
            margin-bottom: 24px;

            @media screen and (max-width: 1200px), screen and (max-height: 700px) { margin-bottom: 10px; }
        }
    }

    &__link {
        font: rem(25)/1.2 $font1;
        color: white;
        transition: color 300ms $easeOutCubic;

        @media screen and (max-width: 1200px) { padding-right: 10px; }
        @media screen and (max-width: 1024px) { font-size: rem(20); }

        &:hover {
            color: $color1;
        }
    }
}