.header{
    @extend .left-padding-50;
    display: flex;
    align-items: center;
    box-shadow: 0px 0px 20px rgba($color4,0.2);
    z-index: 1;
    position: relative;

    &__logo{
        margin-right: auto;
        a{
            margin-right: 20px;
            @media screen and (max-width: 768px) { margin-right: 0; }
            svg{
                width: 100%;
                max-width: 330px;
                height: calc(var(--header-height) - 4px);
                @media screen and (max-width: 768px) {
                    max-width: 200px;
                }
            }
        }
    }

    &__nav{

        &__secondary,
        &__primary{
            display: flex;
            justify-content: flex-end;
            li{
                a{
                    color: $color2;
                }
            }
        }

        &__secondary{
            @media screen and (max-width: 900px) {
                display: none;
            }
            li{
                a{
                    font-size: rem(12);
                    padding: 15px 18px;
                    opacity: 1;
                    transition: opacity 0.3s $easeInOutCubic;
                    &:hover{
                        opacity: 0.5;
                    }
                }
                &:last-child{
                    position: relative;
                    &::before{
                        content: '';
                        position: absolute;
                        height: 12px;
                        width: 2px;
                        left: 0;
                        top: 50%;
                        background-color: $color4;
                        transform: translate(0,-50%);
                        pointer-events: none;
                    }
                    a{
                        padding-left: 30px;
                        padding-right: 30px;
                    }
                }
            }
        }
        &__primary{
            border-top: 1px solid rgba($color2, $alpha: 0.1);

            @media screen and (max-width: 900px) {
                border-top: 0;
            }

            li{
                &:nth-last-child(2){
                    display: none;
                }
                a{
                    font-size: rem(13);
                    padding: 10px 25px;
                    font-weight: 700;
                    height: 100%;
                    display: flex;
                    align-items: center;
                    transition: color 0.3s $easeInOutCubic;
                    text-transform: uppercase;

                    span{
                        padding-right: 20px;
                        position: relative;

                        &::after{
                            content: '';
                            position: absolute;
                            top: 50%;
                            right: 0;
                            width: 0; 
                            height: 0; 
                            border-left: 5px solid transparent;
                            border-right: 5px solid transparent;
                            border-top: 5px solid $color1;
                            transform: translate(0,-50%);
                            transition: transform 0.3s $easeInOutCubic;
                        }
                    }

                    &:hover{
                        color: $color1;
                    }

                    &.no-link{
                        span{
                            padding-right: 0;
                            &::after{ content: normal; }
                        }
                    }
                }
                @media screen and (max-width: 900px) {
                    &:nth-last-child(2){
                        display: block;
                    }
                    &:not(:nth-last-child(2)):not(:last-child){
                        display: none;
                    }
                }
            }

            &__menu-btn,
            &__search{
                width: 82px;
                height: 82px;
                position: relative;
                display: block;
                cursor: pointer;
                transition: background-color 0.3s $easeInOutCubic;
            }

            &__menu-btn{
                background-color: transparent;
                &:hover{
                    background-color: $color3;
                }
            }

            &__search{
                background-color: $color1;
                margin-left: 15px;
                @media screen and (max-width: 900px) { margin-left: 0; }
                
                // Icone loupe
                svg{
                    position: absolute;
                    left: 50%;
                    top: 50%;
                    fill: white;
                    width: 30px;
                    height: 30px;
                    transform: translate(-50%,-50%);
                    opacity: 1;
                    transition: opacity 0.3s $easeInOutCubic;
                }

                // Icone X
                &>span{
                    width: 21px;
                    height: 21px;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%,-50%);
                    z-index: 1;
                    opacity: 0;
                    transition: opacity 0.3s $easeInOutCubic;
        
                    &::before,
                    &::after{
                        content: '';
                        width: 2px;
                        height: 29px;
                        position: absolute;
                        left: 50%;
                        top: 50%;
                        transform: translate(-50%,-50%) rotate(45deg);
                        background-color: white;
                        z-index: 2;
                    }
                    &::after{
                        transform: translate(-50%,-50%) rotate(-45deg);
                    }
                }
                &:hover{
                    background-color: $color5;
                }
            }
        }

    }
}