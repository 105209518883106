/*
|--------------------------------------------------------------------------
| COMMON STYLES
|--------------------------------------------------------------------------
*/
@mixin largeTitle() {
    
}

@mixin mediumTitle() {
    
}

@mixin smallTitle() {
    
}

@mixin paragraph() {
    
}


/*
|--------------------------------------------------------------------------
| FUNCTIONS
|--------------------------------------------------------------------------
*/
// Converti les pixels en Rem
@function rem($pixels, $context: $browser-context) {
    @return #{$pixels/$context}rem;
}

// Créer une font responsive selon des valeurs définies
@mixin fontsize($base, $fs1440: 0, $fs1024: 0, $fs768: 0, $fs425: 0, $fs375: 0, $fs320: 0) {
    font-size: rem($base);
    @if $fs1440 != 0 { @media screen and (max-width: 1440px) { font-size: rem($fs1440); }}
    @if $fs1024 != 0 { @media screen and (max-width: 1024px) { font-size: rem($fs1024); }}
    @if $fs768 != 0  { @media screen and (max-width: 768px)  { font-size: rem($fs768);  }}
    @if $fs425 != 0  { @media screen and (max-width: 425px)  { font-size: rem($fs425);  }}
    @if $fs375 != 0  { @media screen and (max-width: 375px)  { font-size: rem($fs375);  }}
    @if $fs320 != 0  { @media screen and (max-width: 320px)  { font-size: rem($fs320);  }}
}


@function ratio($width, $height) {
    @return #{$height/$width * 100%};
}