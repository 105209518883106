:root {
    --dynamic-ol-inside-left-padding: 35px;
    --dynamic-ul-inside-left-padding: 22px;

    @media screen and (max-width: 768px) {
        --dynamic-ol-inside-left-padding: 25px;
        --dynamic-ul-inside-left-padding: 15px;
    }
    @media screen and (max-width: 500px) {
        --dynamic-ol-inside-left-padding: 10px;
        --dynamic-ul-inside-left-padding: 10px;
    }
}


.dynamic{

    > :first-child { margin-top: 0 !important; }
    > :last-child { margin-bottom: 0 !important; }

    h2{
        @extend .large-title;
        margin-top: 50px;
        margin-bottom: 30px;
        @media screen and (max-width: 768px) { margin-bottom: 20px; }
    }

    h3{
        @extend .medium-title;
        margin-top: 50px;
        margin-bottom: 20px;
    }

    h4{
        @extend .small-title;
        margin-top: 40px;
        margin-bottom: 15px;
    }

    h2 + h3,
    h3 + h4,
    h4 + h5,
    h5 + h6{
        margin-top: 0;
    }

    p{
        @extend .paragraph;
        margin-top: 20px;
        margin-bottom: 20px;

        &.buttonMargin{
            margin-top: 40px;
            margin-bottom: 35px;

            .btn{
                margin-bottom: 5px;
            }
        }
    }

    strong,b{
        font-weight: 700;
    }

    em,i{
        font-style: italic;
    }

    a:not([class^="btn"]){
        color: $color2;
        text-decoration: underline;
        transition: color 0.3s $easeInOutCubic;

        &:hover{
            color: $color1;
        }
    }

    ol {
        list-style: none;
        counter-reset: li;

        > li {
            position: relative;

            &:last-child { margin-bottom: 0; }

            &:before {
                content: counter(li) ".";
                counter-increment: li;
                display: inline-block;
                color: $color1;
                font-weight: 700;

                @media screen and (max-width: 768px) { font-size: rem(16); }
                @media screen and (max-width: 500px) { font-size: rem(15); }
            }

            ol {
                width: 100%;
                counter-reset: li;
    
                li {
                    padding-left: var(--dynamic-ol-inside-left-padding);
                    &:before {
                        content: counter(li,upper-alpha) ")";
                        color: $color2;
                    }
                }
            }
    
            ul {
                width: 100%;
                counter-reset: li;
    
                li {
                    padding-left: var(--dynamic-ol-inside-left-padding);
                }
            }
        }

    }

    ul {
        > li {
            position: relative;

            &:before{
                content: '';
                width: 4px;
                height: 4px;
                background: $color1;
                border: 1px solid $color1;
                border-radius: 50%;
                transform: translateY(-3px);
            }

            &:last-child{ padding-bottom: 0; }

            ul {
                width: 100%;
                padding-left: var(--dynamic-ul-inside-left-padding);

                li {
                    &::before {
                        background: transparent;
                        border-color: $color1;

                        border: 2px solid $color1;
                    }

                    ul li:before {
                        background: $color1;
                        border: 1px solid $color1;
                    }
                }
            }

            ol {
                width: 100%;
                counter-reset: li;
    
                li {
                    padding-left: var(--dynamic-ul-inside-left-padding);
                }
            }
        }
    }

    > ol, > ul {
        margin-bottom: 20px;

        @media screen and (max-width: 1024px) { margin-bottom: 15px; }
        @media screen and (max-width: 768px)  { margin-bottom: 10px; }

        > li:first-child { padding-top: 0; }

        li {
            display: flex;
            flex-flow: row wrap;
            align-items: baseline;
            padding-top: 20px;
            @extend .paragraph;

            @media screen and (max-width: 1024px) { padding-top: 15px; }
            @media screen and (max-width: 768px)  { padding-top: 10px; }

            &:before {
                margin-right: 15px;

                @media screen and (max-width: 768px) { margin-right: 10px; }
            }

            p { flex: 1; margin: 0; }
            b, strong { font-weight: 500; }
        }
    }

    blockquote{
        position: relative;
        padding-left: 40px;
        p{
            color: $color1;
            @include fontsize(22,0,20,18,16,0,0);
            line-height: 1.5909em; // 35px/22px
        }
        &::before{
            content: '';
            position: absolute;
            background-image: url('../medias/images/icons/quote.png');
            width: 21px;
            height: 21px;
            background-size: cover;
            left: 0;
            top: 2px;
        }
    }

    pre{
        font-family: $font1;
        white-space: normal;
        font-size: rem(13);
        line-height: 1.5384em; // 20px/13px
        color: $color4;
        margin-top: 20px;
        margin-bottom: 20px;
    }

    .table-wrapper{
        overflow-x: auto;
        margin-top: 30px;
        margin-bottom: 30px;

        table{
            overflow-x: hidden;
            width: 100%;
            tr{
                th,td{
                    padding: 20px 25px;
                    color: $color2;
                    line-height: 1.5625em; // 25px/16px
                    background-color: white;
                    @include fontsize(18, 0, 17, 16, 0, 15, 0);

                    & > :first-child{ margin-top: 0; }
                    & > :last-child{ margin-bottom: 0}

                    &.dark{
                        color: white;
                        background-color: $color2;
                        a,p,pre{
                            color: white;
                        }
                        a:hover{
                            color: $color1;
                        }
                        h1,h2,h3,h4{
                            color: $color1;
                        }
                    }
                }
                th{
                    background-color: $color1;
                    color: white;
                    text-align: left;
                }
                &:not(:last-child) td{
                    border-bottom: 1px solid #d9d9d9;
                }

                &:only-child{
                    th,td{
                        &:only-child{
                            padding: 55px 60px;
                            border: 0;
                        }
                    }
                }
            }
        }
    }

}