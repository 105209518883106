.home-mission{
    background-color: white;
    &>div{
        @extend .x-padding-120;
        @extend .y-padding-130;
        margin-right: calc(var(--x-padding-120)*1.8);
        margin-top: calc(var(--y-padding-160)*-1);
        background-color: $color2;
        z-index: 4;
        position: relative;

        @media screen and (max-width: 768px) { 
            margin-right: 0;
            margin-top: 0;
        }
    }

    &__over-title{
        @extend .over-title;
    }

    &__title{
        @extend .huge-title;
        @extend .top-padding-40;
        @extend .bottom-padding-70;
        @include fontsize(60, 0, 40, 30, 28, 0, 0);
    }

}