.products-slider{
    position: relative;

    &__arrows{
        position: absolute;
        top: -50px;
        right: 0;
    }

    &__slide{
        width: 46%;
    }

    .slick-slide {
        margin: 0 var(--grid-gaps);
      }
    /* the parent */
    .slick-list {
      margin-left: calc(var(--grid-gaps)*-1);
      margin-right: calc(var(--grid-gaps)*-1);
      overflow: visible;
    }


}