.similar-news{
    @extend .x-padding-120;
    @extend .y-padding-160;
    background-color: white;
    position: relative;

    &>div{
        display: flex;
        align-items: center;
        flex-wrap: wrap;
    }

    &__text{
        width: 38%;// 460px/1200px

        @media screen and (max-width: 1400px) {
            width: 50%;
        }
        @media screen and (max-width: 1024px) {
            width: 100%;
        }
    }

    &__text-title{
        @extend .huge-title;
    }

    &__text-button{
        @extend .top-padding-60;
    }

    &__list{
        width: 62%;// 460px/1200px
        @media screen and (max-width: 1400px) {
            width: 50%;
        }
        @media screen and (max-width: 1024px) {
            width: 100%;
            padding-top: 60px;
        }
        @media screen and (max-width: 768px) {
            width: 100%;
            padding-top: 60px;

            .column{
                &:not(:last-child){
                    padding-bottom: 60px;
                }
            }
        }

    }

    &__text{
        position: relative;
        z-index: 3;
    }

    .grid-pattern{
        top: 0;
        bottom: 0;
        left: 0;
        width: var(--pattern-width-normal);
    }
    
}