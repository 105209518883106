@media print {

    *{
        color: black !important;
    }

    // Cacher plein de stuff -----------
    .header,
    .breadcrumbs,
    .showcase,
    .footer,
    .grid-pattern,
    .main__components,
    .page-top-2-cols__rapid-access,
    .page-top-2-cols__print-btn,
    .slick-dots,
    .slick-arrows,
    .generic-slider,
    .filter-list,
    .pagination,
    .news-nav,
    .similar-news,
    .back-to-top{
        display: none !important;
    }

    .page-top__title,
    .page-top__picture,
    .main__content,
    .main__rubrics,
    .page-top-2-cols__content,
    .products-list,
    .banner-contact__container,
    .contact-form,
    .jobs-list__container,
    .jobs-form,
    .online-sales__container{
        padding-left: 0 !important;
        padding-right: 0 !important;
    }

    .page-top__title{
        padding-bottom: 0 !important;
    }

    .main__content{
        background-color: white !important;
        padding-bottom: 0;
    }


    .page-top-2-cols{
        flex-wrap: nowrap !important;
        flex-direction: column !important;
        height: 100vh !important;

        &__wrapper{
            width: 100%;
            overflow: hidden;
        }

        &__content{
            width: 100% !important;
            height: auto !important;
            padding-bottom: 80px !important;
        }

        &__over-title-print-logo{
            display: block !important;
        }

        &__picture{
            width: 100% !important;
            height: auto !important;
            flex-grow: 1 !important;
            padding-bottom: 0 !important;
            overflow: hidden !important;
        }

        .slick-list,
        .slick-track,
        .slick-slide{
            width: 100% !important;
        }

        // Forcer la première slide
        .slick-list{
            .slick-track{
                .slick-slide{
                    opacity: 1 !important;
                    &:not(:first-child){
                        display: none !important;
                    }
                }
            }
        }

    }


    .main__content__print{
        &__footer{
            margin-top: 80px;
            display: flex !important;
            justify-content: space-between;
            font-size: 13px;
            svg{
                width: 200px;
                height: 40px;
            }

            &__info{
                display: flex;
                align-items: center;
                flex-wrap: wrap;

                &__wrapper{
                    div:first-child{
                        margin-bottom: 10px;
                    }
                    span{
                        margin-left: 30px;
                    }
                }
            }
        }
    }


    //Rubriques
    .rubric{
        margin-left: 0;
        margin-right: 0;

        &__header,
        &__content{
            margin-left: 0;
            margin-right: 0;
        }

        &__content{
            height: auto !important;
        }

        &__transition{
            opacity: 1 !important;
            transform: none !important;
        }
    }

    // Produits
    /*.products-list__items .column{

    }*/
}