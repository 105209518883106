:root{
    --arrow-color:#{$color1};
    --arrow-color-hover:#{$color1};
}
.dark-bg{
    --arrow-color: #{$color1};
}

.slick-arrows{

    --arrow-width: 26px;
    --arrow-height: 19px;
    --arrow-travel: 5px;

    width: 100px;
    height: 20px;
    position: relative;

    .slick-prev,
    .slick-next{
        width: var(--arrow-width);
        height: var(--arrow-height);
        z-index: 3;
        &::before{
            content: normal;
        }
        svg{
            width: var(--arrow-height);
            height: var(--arrow-width);
            fill: var(--arrow-color);
            transition: fill 0.3s $easeInOutCubic, transform 0.3s $easeInOutCubic;
        }
    }

    .slick-prev{
        left: 0;
        svg{
            transform: rotate(90deg) translate(-3px, -4px);
        }
    }

    .slick-next{
        right: 0;
        svg{
            transform: rotate(-90deg) translate(3px, 4px);
        }
    }

    .slick-prev,
    .slick-next{
        &:hover{
            svg{
                fill: var(--arrow-color-hover);
            }
        }
    }

    .slick-prev:hover svg{
        transform: rotate(90deg) translate(-3px, calc( -4px - (var(--arrow-travel)*-1) ));
    }

    .slick-next:hover svg{
        transform: rotate(-90deg) translate(3px, calc( 4px + var(--arrow-travel) ));
    }
}