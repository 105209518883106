.links {
    border-top: 1px solid $color2;

    &__link {
        position: relative;
        border-bottom: 1px solid $color2;
    }

    &__img-and-title {
        display: flex;
        align-items: center;
        padding-left: 30px;
        padding-right: 140px;

        @media screen and (max-width: 1024px)  { padding-right: 110px; }
        @media screen and (max-width: 600px)  { padding-left: 10px; padding-right: 55px; }
    }

    &__picture {
        object-fit: contain;
        width: 170px;
        min-width: 170px;
        height: auto;

        @media screen and (max-width: 1024px) {
            width: 120px;
            min-width: 120px;
        }

        @media screen and (max-width: 600px) {
            display: none;
        }
    }

    &__box {
        position: relative;
        cursor: pointer;

        padding: 20px 0;
        min-height: 155px;
        display: flex;
        align-items: center;

        @media screen and (max-width: 1200px) { padding-top: 50px; padding-bottom: 50px; }
        @media screen and (max-width: 1024px) { padding-top: 40px; padding-bottom: 40px; }
        @media screen and (max-width: 600px)  { padding-top: 20px; padding-bottom: 20px; }

        @media screen and (max-width: 768px) { min-height: 130px; }
        @media screen and (max-width: 600px) { min-height: 110px; }
    }

    &__box--smaller-y-padding{
        padding-top: 20px;
        padding-bottom: 20px;
    }

    &__content{
        padding-left: 65px;
        @media screen and (max-width: 1024px) { padding-left: 35px; }
        @media screen and (max-width: 600px) {
            padding-left: 0;
        }
    }

    &__box--no-image{
        .links__content{
            padding-left: 0;
        }
    }

    &__category {
        font-size: rem(14);
        color: $color2;
        margin-bottom: 10px;
    }

    &__category--jobs {
        font-size: rem(13);
        color: $color1;
        font-weight: 500;
        text-transform: uppercase;
    }

    &__title {
        @extend .large-title-smaller-mobile;
        line-height: 1.5;
        color: $color2;
        transition: color 0.3s $easeInOutCubic;

        mark{
            background-color: rgba($color2,0.1);
            font-style: normal;
            color: $color2;
        }
    }

    &__icon {
        position: absolute;
        width: 78px;
        height: 78px;
        top: 50%;
        right: 30px;
        transform: translateY(-50%);

        @media screen and (max-width: 1024px) { width: 60px; height: 60px; }
        @media screen and (max-width: 600px) { width: 34px; height: 34px; right: 10px; }

        &::before {
            content: '';
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            background: $color2;
            border-radius: 50%;
            transition: background 300ms, transform 300ms $easeOutCubic;
        }
    }

    &__box:hover &__icon:before {
        background: $color1;
        transform: scale(1.20512); // 94px/78px
    }

    &__svg {
        position: absolute;
        width: 22px;
        height: 30px;
        top: 50%;
        left: 50%;
        fill: white;
        transform: translate(-50%, -50%) rotate(-90deg);
        @media screen and (max-width: 600px) { width: 15px; height: 21px; }
    }

    &--keep-images-mobile{

        .links__picture {
            @media screen and (max-width: 600px) {
                display: block;
            }
            @media screen and (max-width: 400px) {
                width: 80px;
                min-width: 80px;
            }
            @media screen and (max-width: 350px) {
                display: none;
            }
        }
        .links__content{
            @media screen and (max-width: 600px) {
                padding-left: 15px;
            }
            @media screen and (max-width: 350px) {
                padding-left: 0px;
            }
        }

    }
}