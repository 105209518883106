@font-face {
    font-family: 'Aktiv Grotesk';
    src: url('../fonts/aktivgrotesk-bold.woff2') format('woff2'),
         url('../fonts/aktivgrotesk-bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Aktiv Grotesk';
    src: url('../fonts/aktivgrotesk-medium.woff2') format('woff2'),
         url('../fonts/aktivgrotesk-medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Aktiv Grotesk';
    src: url('../fonts/aktivgrotesk-regular.woff2') format('woff2'),
         url('../fonts/aktivgrotesk-regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}