.news-thumb{

    position: relative;
    z-index: 3;
    width: 100%;

    &__picture{
        &>div{
            width: 100%;
            padding-top: 114.7058823529412%; // 390px/340px
            position: relative;
            overflow: hidden;

            @media screen and (max-width: 1024px) {
                padding-top: 100%;
            }

            picture{
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                transform: scale(1);
                transition: transform 0.3s;
            }
        }
    }

    &__content{
        @extend .top-padding-30;
    }

    &__date{
        color: $color1;
        font-size: rem(13);
        text-transform: uppercase;
        font-weight: 500;
        margin-bottom: 15px;
    }

    &__title{
        @extend .medium-title;
    }


    &:hover{
        .news-thumb__picture{
            &>div{
                picture{
                    transform: scale(1.05);
                }
            }
        }
    }

}