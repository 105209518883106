// --------
// Fade up
// --------
:root {
    --scrollfire-fade-up__opacity: 0;
    --scrollfire-fade-up__transform: translateY(50px);
    @media screen and (max-width: 1024px) { --scrollfire-fade-up__transform: translateY(25px); }
    --scrollfire-fade-up__transition: opacity 800ms, transform 800ms cubic-bezier(.215,.61,.355,1);
    --scrollfire-fade-up__opacity-fire: 1;
    --scrollfire-fade-up__transform-fire: none;
}
.js-scrollfire.fade-up,
.js-premiumScrollfire > .fade-up,
.show-once-transition .js-scrollfire.fade-up[data-fire],
.show-once-transition .js-premiumScrollfire[data-fire] > .fade-up {
    opacity: var(--scrollfire-fade-up__opacity);
    transform: var(--scrollfire-fade-up__transform);
    transition: var(--scrollfire-fade-up__transition);
}
.js-scrollfire[data-fire].fade-up,
.js-premiumScrollfire[data-fire] > .fade-up {
    opacity: var(--scrollfire-fade-up__opacity-fire);
    transform: var(--scrollfire-fade-up__transform-fire);
}



.js-scrollfire.curtain-top, .js-premiumScrollfire.curtain-top,
.js-scrollfire.curtain-right, .js-premiumScrollfire.curtain-right {
    overflow: hidden;

    &:after {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: #fff;
        z-index: 10;
    }

    img {
        transform: scale(1.1);
        transition: transform 1000ms cubic-bezier(0.83, 0, 0.17, 1);
    }

    &[data-fire] {
        img { transform: none; }
    }
}

.js-scrollfire.curtain-top, .js-premiumScrollfire.curtain-top {
    &:after { transition: top 1000ms cubic-bezier(0.83, 0, 0.17, 1); }
    &[data-fire]:after { top: 100%; }
}

.js-scrollfire.curtain-right, .js-premiumScrollfire.curtain-right {
    &:after { transition: right 1000ms cubic-bezier(0.83, 0, 0.17, 1); }
    &[data-fire]:after { right: 100%; }
}

.js-scrollfire-delay-enabled {
    .delay1, .delay1:after, .delay1 > img { transition-delay: 400ms; }
    .delay2, .delay2:after, .delay2 > img { transition-delay: 500ms; }
    .delay3, .delay3:after, .delay3 > img { transition-delay: 600ms; }
    .delay4, .delay4:after, .delay4 > img { transition-delay: 700ms; }
    .delay5, .delay5:after, .delay5 > img { transition-delay: 800ms; }
    .delay6, .delay6:after, .delay6 > img { transition-delay: 900ms; }
    .delay7, .delay7:after, .delay7 > img { transition-delay: 1000ms; }
}