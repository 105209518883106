.btn,
.btn--secondary{
    color: $color2;
    position: relative;
    background-color: transparent;
    text-transform: uppercase;
    font-weight: 700;
    font-size: rem(12);
    padding: 22px 34px;
    border: 1px solid $color2;
    transition: color 0.3s $easeInOutCubic;

    span{
        position: relative;
        z-index: 2;

        /*svg{
            fill: $color1;
            height: 1em;
            width: 1em;
            margin-left: 20px;
        }*/
    }

    &::before{
        content: "";
        position: absolute;
        top: 0;
        height: 100%;
        left: 0;
        background-color: $color2;
        width: 100%;
        transform-origin: right;
        transition: transform 0.3s $easeInOutButtons;
        transform: scaleX(0);
        z-index: 1;
    }

    &:hover{
        color: white;
        &::before{
            transition: transform 0.3s $easeInOutButtons;
            transform: scaleX(1);
            transform-origin: left;
        }
    }

    &__with-icon{
        span{
            display: flex;
            align-items: center;
        }
        svg{
            margin-left: 18px;
            fill: $color1;
            width: 16px;
            height: 16px;
        }
    }

}

// Bouton secondaire
.btn--secondary{
    color: white;
    background-color: $color2;

    &::before{
        background-color: $color3;
    }

    &:hover{
        color: $color2;
    }
}

//Boutons sur fond foncé
.dark-bg{
    .btn,
    .btn--secondary{
        color: white;
        border: 1px solid white;
        background-color: transparent;

        &::before{
            background-color: white;
        }

        &:hover{
            color: $color2;
        }
    }
}