:root {
    --dot-color: #{$color2};
    --dot-opacity: 0.1;
}
.dark-bg{
    --dot-color: white;
    --dot-opacity: 0.3;  
}

.dark-dots-800{
    @media screen and (max-width: 800px) {
        --dot-color: #{$color2};
        .dark-bg{
            --dot-color: #{$color2};
        }
    }
}


.slick-dots{
    width: auto;
    display: flex;
    flex-direction: column;

    &--horizontal{
        flex-direction: row;
    }

    li{
        height: 26px;
        width: 26px;
        margin: 0px;
        &:not(:last-child){margin-bottom: 10px};
        button{
            height: 26px;
            width: 26px;
            position: relative;
            &::before,
            &::after{
                content: '';
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%,-50%);
                height: 10px;
                width: 10px;
                border-radius: 50%;
                background-color : var(--dot-color);
                opacity: var(--dot-opacity);
                transition: width 0.3s $easeInOutCubic, height 0.3s $easeInOutCubic;
            }
            &::after{
                background-color: transparent;
                border: 0 solid var(--dot-color);
                transition: width 0.3s $easeInOutCubic, height 0.3s $easeInOutCubic, border 0.3s $easeInOutCubic;
            }
        }

        &.slick-active{
            button{
                &::before{
                    height: 6px;
                    width: 6px;
                    opacity: 1;
                }
                &::after{
                    height: 22px;
                    width: 22px;
                    opacity: 1;
                    border-width: 2px;
                }
            }
        }
        
    }
}

// Shifter les points en horizontal à partir de 800px
.horizontal-dots{
    .slick-dots{
        flex-direction: row;
        li{
            margin-bottom: 0;
            &:not(:last-child){margin-right: 10px};
        }
    }
}

.horizontal-dots-800{
    @media screen and (max-width: 800px) {
        .slick-dots{
            flex-direction: row;
            li{
                margin-bottom: 0;
                &:not(:last-child){margin-right: 10px};
            }
        }
    }
}