.curtain {
    position: fixed;
    width: 100vw;
    top: 0;
    left: 0;
    bottom: 0;
    background: #fff;
    z-index: 200;
    opacity: 0;
    pointer-events: none;
    transition: opacity 500ms;

    &__logo {
        position: absolute;
        width: 300px;
        top: 50%;
        left: 50%;
        opacity: 0;
        transform: translate(-50%, -50%);
        transition: opacity 500ms;
    }

    &__loader {
        position: absolute;
        width: 60px;
        height: 60px;
        top: calc(50% - 30px);
        left: calc(50% - 30px);
        background: linear-gradient(to bottom, $color1 10%, rgba(255, 255, 255, 0) 100%);
        opacity: 0;
        border-radius: 50%;
        animation: load 1s infinite linear;
        transition: opacity 500ms;
    }
    
    &__loader:before {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: linear-gradient(to right, rgba(255, 255, 255, 0) 50%, $color1 50%);
        border-radius: 100%;
    }
      
    &__loader:after {
        content: '';
        position: absolute;
        width: 90%;
        height: 90%;
        margin: auto;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: #fff;
        border-radius: 50%;
    }

    @keyframes load {
        from { transform: rotate(0deg); }
        to { transform: rotate(360deg); }
    }
}


.show-once-transition {
    pointer-events: none;

    .curtain {
        opacity: 1;
        z-index: 51;

        &__logo {
            opacity: 1;
        }
    }
}

.show-default-transition {
    pointer-events: none;

    .curtain {
        opacity: 1;

        &__loader{
            opacity: 1;
        }
    }
}

.show-map-transition {
    pointer-events: none;
    
    .curtain {
        opacity: 1;
        z-index: 51;

        &__loader {
            opacity: 1;
        }
    }
}
