.home-banner{
    --bottom-gap: 127px;
    --right-gap: 270px;
    --dots-gap: 75px;
    --circle-diameter: 110px;

    @media screen and (max-width: 1300px) {
        display: flex;
        flex-direction: column;
        --circle-diameter: 90px;
    }

    @media screen and (max-width: 768px) {
        --circle-diameter: 80px;
    }

    background-color: white;
    height: calc(100vh - var(--header-height));
    position: relative;

    .slick-list,
    .slick-track,
    .slick-slide > div{
        height: 100%;
    }


    &__slider-wrapper{
        height: 100%;
        position: relative;
        padding-bottom: var(--bottom-gap);

        @media screen and (max-width: 1300px) {
            padding-bottom: 0;
            flex-grow: 1;
            height: 0;
        }

        @media screen and (max-width: 800px) {
            &::after{
                content:'';
                position: absolute;
                z-index: 5;
                height: 70px;
                left: 0;
                right: 0;
                bottom: 0;
                background: rgb(0,0,0);
                background: linear-gradient(0deg, rgba(0,0,0,0.5) 0%, rgba(0,0,0,0) 100%);
            }
        }
    }

    &__picture-slider{
        height: 100%;
        background-color: $color4;
    }

    &__picture-slide{
        height: 100%;
        position: relative;
        picture{
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
        }
    }

    &__text-slider{
        @extend .x-padding-120;
        position: absolute;
        height: calc(var(--bottom-gap) * 2);
        left: 0;
        bottom: 0;
        right: var(--right-gap);
        background-color: $color2;
        color: white;

        @media screen and (max-width: 1300px) {
            right: 0;
            position: relative;
            height: auto;
        }

        &>div{
            height: 100%;
            &.slick-dotted{
                margin-bottom: 0;
            }
        }

        &.slick-dotted.slick-slider{ margin-bottom: 0; }

        .slick-dots{
            position: absolute;
            left: --x-padding-120;
            bottom: 50%;
            transform: translate(0,50%);

            @media screen and (max-width: 800px) {
                z-index: 6;
                transform: none;
                bottom: calc(100% + 20px );
            }
        }
    }

    &__text-slide{
        height: 100%;
        display: flex;
        align-items: center;
        padding-left: var(--dots-gap);

        @media screen and (max-width: 1300px) {
            padding-top: 45px;
            padding-bottom: 45px;
        }

        @media screen and (max-width: 800px) {
            padding-left: 0;
        }
    }

    &__text-wrapper{
        display: flex;
        align-items: center;
        height: 100%;
    }

    &__text-flex{
        display: flex;
        align-items: flex-end;
        width: 100%;

        @media screen and (max-width: 1300px) {
            flex-wrap: wrap;
        }
    }

    &__text-slide-title{
        @extend .large-title;
        color: white;
        padding-right: 100px;
        flex-grow: 1;

        @media screen and (max-width: 1300px) {
            width: 100%;
            padding-right: 0;
        }
    }
    &__text-slide-button{
        margin-right: 1px;
        @media screen and (max-width: 1300px) {
            padding-top: 15px;
        }
        .btn{
            white-space: nowrap;
        }
    }

    &__down{
        position: absolute;
        bottom: 0;
        right: calc(var(--right-gap) * -1);

        height: calc(var(--bottom-gap) * 2);
        width: var(--right-gap);

        overflow: hidden;

        @media screen and (max-width: 1300px) {
            display: none;
            width: calc(var(--circle-diameter)*1.1);
            height: calc(var(--circle-diameter)*1.1);
            bottom: 200px;
            right: 40px;
            top: 0;
            transform: translate(0, -50%);
        }

        @media screen and (max-width: 800px) {
            z-index: 6;
        }

        @media screen and (max-width: 768px) {
            right: 20px;
        }
    }

    &__down-arrow-container{
        position: absolute;
        left: 50%;
        top: 50%;
        width: var(--circle-diameter);
        height: var(--circle-diameter);
        transform: translate(-50%,-50%);
        cursor: pointer;

        svg{
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%,-50%);
            width: 28px;
            height: 40px;
            fill: white;
            z-index: 2;
            animation: float 3s ease-in-out infinite;

            @keyframes float {
                0% { transform: translate(-50%,-50%); }
                50% { transform: translate(-50%, calc(-50% + 10px )); }
                100% { transform: translate(-50%,-50%); }
            }
        }

        &::after{
            content: '';
            position: absolute;
            width: 100%;
            height: 100%;
            background-color: $color1;
            border-radius: 50%;
            transform: scale(1);
            transition: transform 0.3s $easeInOutCubic;
        }

        &:hover{
            &::after{
                transform: scale(1.1);
            }
        }

        span{
            position: absolute;
            font-size: rem(16);
            color: $color2;
            font-weight: 700;
            white-space: nowrap;
            left: 50%;
            bottom: -38px;
            transform: translate(-50%,0%);
        }
    }
    
}