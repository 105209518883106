:root {
    // Input and textarea
    --field-label-padding: 0 0 5px 0;

    --field-label-font-family: #{$font1};
    --field-label-font-size: 13px;
    --field-label-font-weight: normal;
    --field-label-line-height: 1;
    --field-label-text-transform: inherit;
    --field-label-letter-spacing: inherit;
    --field-label-color: #{$color4};

    --field-padding: 15px 0;
    --field-border-radius: 0px;

    --field-font-family: #{$font1};
    --field-font-size: 18px;
    --field-font-weight: normal;
    --field-line-height: 1;
    --field-text-transform: inherit;
    --field-letter-spacing: inherit;
    
    --field-color: #{$color2};
    --field-border-color: #{$color2};
    --field-background: transparent;
    
    --field-background-inactive: transparent;
    --field-color-inactive: #{$color2};
    --field-border-color-inactive: #{$color2};
    --field-transition: none;
    --field-color-placeholder: #{$color4};
    
    
    // Radio and checkbox
    --box-dimension: 20px;
    --box-margin-right: 15px;
    --box-background: transparent;
    --box-background-active: #{$color1};
    
    --radio-checkbox-font-family: #{$font1};
    --radio-checkbox-font-size: rem(18);
    --radio-checkbox-font-weight: normal;
    --radio-checkbox-line-height: 1;
    --radio-checkbox-text-transform: inherit;
    --radio-checkbox-letter-spacing: inherit;
    --radio-checkbox-color: #{$color2};

    --radio-hook-dimension: 12px;

    --radio-dot-dimension: 7px;
    --radio-dot-color: white;


    // Files
    --file-padding: 20px 60px 20px 0;
    --file-border-top: 0;
    --file-border-right: 0;
    --file-border-bottom: 1px solid #{$color2};
    --file-border-left: 0;

    --file-font-family: #{$font1};
    --file-font-size: rem(16);
    --file-font-weight: 500;
    --file-line-height: 1.5;
    --file-text-transform: inherit;
    --file-letter-spacing: inherit;
    --file-color: #{$color2};
    --file-over-color: #{$color1};
    
    --file-x-svg-dimension: 16px;
    --file-x-svg-top: calc(50% - (var(--file-x-svg-dimension) / 2));
    --file-x-svg-right: 0;

    --file-x-color: var(--file-color);
    --file-x-over-color: var(--file-over-color);

    --file-svg-color: var(--file-color);
    --file-svg-over-color: var(--file-over-color);

    // Select
    --tail-label-padding: 5px 30px;
    --tail-label-min-height: 70px;
    --tail-label-font-family: #{$font1};
    --tail-label-font-size: rem(18);
    --tail-label-font-weight: 400;
    --tail-label-line-height: 1.5;
    --tail-label-text-transform: inherit;
    --tail-label-letter-spacing: inherit;
    --tail-label-color: $color1;
    --tail-label-background: transparent;
    --tail-label-border: 1px solid #{$color2};
    --tail-label-transition: background 500ms var(--select-ease), border-color 500ms var(--select-ease);
    
    --tail-option-padding: 20px 30px;
    --tail-option-font-family: #{$font1};
    --tail-option-font-size: rem(18);
    --tail-option-font-weight: 400;
    --tail-option-line-height: 1.5;
    --tail-option-text-transform: inherit;
    --tail-option-letter-spacing: inherit;
    --tail-option-color: $color1;
    --tail-option-background: #fff;

    --tail-option-over-color: #{$color2};
    --tail-option-over-background: #{$color3};

    --select-ease: #{$easeInOutCubic};
}

.dark-bg{
    --field-color: white;
    --field-border-color: white;
    --field-border-color-inactive: white;
    --field-color-inactive: white;
}

.field {
    position: relative;
    margin-bottom: 55px;

    @media screen and (max-width: 1024px) { margin-bottom: 50px; }
    @media screen and (max-width: 768px) { margin-bottom: 40px; }

    &--more-margin{
        margin-bottom: 70px;
        @media screen and (max-width: 1024px) { margin-bottom: 60px; }
        @media screen and (max-width: 768px) { margin-bottom: 50px; }
    }

    // Erreur
    &__error {
        margin-top: 5px;
        font: rem(13)/1 var(--field-font-family);
        color: $color6;
    }

    &--title {
        margin-bottom: 20px;
        font: rem(18)/1 var(--field-font-family);
    }

    // Placeholder
    ::-webkit-input-placeholder { color: var(--field-color-placeholder); }
    ::-moz-placeholder { color: var(--field-color-placeholder); }
    :-ms-input-placeholder {  color: var(--field-color-placeholder); }
    :-moz-placeholder { color: var(--field-color-placeholder); }

    
    // Input textarea et newsletter
    &--input &__input, &--textarea &__input, &--newsletter &__input {
        width: 100%;
        height: 32px;
        padding: 0 0 5px;
        font: rem(18)/1.3 $font1;
        color: $color2;
        background: transparent;
        border: none;
        box-shadow: inset 0 -1px 0 0 $color2;
        border-radius: 0;
        -webkit-appearance: none;

        //transition: box-shadow 0.3s;

        &:focus{
            box-shadow: inset 0 -3px 0 0 $color1;
        }
    }

    &--input &__label, &--textarea &__label, &--newsletter &__label {
        position: absolute;
        top: 0px;
        left: 0;
        pointer-events: none;
        font: rem(18)/1.3 $font1;
        color: $color2;
        transition: font-size 300ms, top 300ms $easeOutCubic;
    }

    &--input &__input:focus ~ &__label, &--input[data-inputvalue]:not([data-inputvalue=""]) &__label,
    &--textarea &__input:focus ~ &__label, &--textarea[data-textareavalue]:not([data-textareavalue=""]) &__label, 
    &--newsletter &__input:focus ~ &__label, &--newsletter[data-inputvalue]:not([data-inputvalue=""]) &__label,
    &--input &__input:-webkit-autofill ~ &__label{
        font-size: rem(12);
        top: -20px;
    }
        
    // Textarea
    &--textarea &__input {
        outline: none;
        resize: none;
        overflow: hidden;
    }

    // Checkbox et radio
    &--checkbox &__label, &--radio &__label {
        display: flex;
        align-items: center;
		position: relative;
        margin-bottom: 20px;
		cursor: pointer;
    }

    &--checkbox-inline &__label, &--radio-inline &__label {
        display: inline-flex;
        margin: 0 30px 20px 0;
    }

    &--checkbox &__text, &--radio &__text {
        flex: 1;
        font-family: var(--radio-checkbox-font-family);
        font-size: var(--radio-checkbox-font-size);
        font-weight: var(--radio-checkbox-font-weight);
        line-height: var(--radio-checkbox-line-height);
        text-transform: var(--radio-checkbox-text-transform);
        letter-spacing: var(--radio-checkbox-letter-spacing);
        color: var(--radio-checkbox-color);
        order: 2;
        user-select: none;
    }

    &--checkbox &__input, &--radio &__input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
    }

    &--checkbox &__input:checked ~ &__box, &--radio &__input:checked ~ &__box {
        background: var(--box-background-active);
        border-color: $color1;
    }

    &--checkbox &__input:checked ~ &__box:before, &--radio &__input:checked ~ &__box:before {
        transform: translate(-50%, -50%) scale(1);
        border-color: $color1;
    }

    &--checkbox &__box, &--radio &__box {
        position: relative;
        width: var(--box-dimension);
        height: var(--box-dimension);
        margin-right: var(--box-margin-right);
        background: var(--box-background);
        order: 1;
        transition: background 225ms;
        border: 1px solid $color2;

        &:before {
            content: '';
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%) scale(0);
            transition: transform 225ms;
        }
    }


    // Checkbox
    &--checkbox &__box {
        &:before {
            width: var(--radio-hook-dimension);
            height: var(--radio-hook-dimension);
            background: url('../medias/images/icons/crochet.svg') no-repeat center / contain;
        }
    }

    
    // Radio
    &--radio &__box {
        border-radius: 50%;

        &:before {
            width: var(--radio-dot-dimension);
            height: var(--radio-dot-dimension);
            background: var(--radio-dot-color);
            border-radius: 50%;
        }
    }

    

    // File
    &--file &__input {
        position: absolute;
        width: 0.1px;
        height: 0.1px;
        opacity: 0;
        overflow: hidden;
        z-index: -1;
    }

    &--file &__label {
        display: block;
        position: relative;
        width: 100%;
        padding: var(--file-padding);
        border-top: var(--file-border-top);
        border-right: var(--file-border-right);
        border-bottom: var(--file-border-bottom);
        border-left: var(--file-border-left);
        cursor: pointer;
        transition: color 300ms, background 300ms;
    }
    
    &--file &__text {
        font-family: var(--file-font-family);
        font-size: var(--file-font-size);
        font-weight: var(--file-font-weight);
        line-height: var(--file-line-height);
        text-transform: var(--file-text-transform);
        letter-spacing: var(--file-letter-spacing);
        color: var(--file-color);
        transition: color 300ms;
    }

    &--file &__svg {
        position: absolute;
        width: var(--file-x-svg-dimension);
        height: var(--file-x-svg-dimension);
        top: var(--file-x-svg-top);
        right: var(--file-x-svg-right);
        fill: $color1;
        transition: fill 300ms, opacity 300ms;
    }

    &--file &__x {
        position: absolute;
        width: var(--file-x-svg-dimension);
        height: var(--file-x-svg-dimension);
        top: var(--file-x-svg-top);
        right: var(--file-x-svg-right);
        opacity: 0;
        z-index: 1;
        cursor: pointer;
        pointer-events: none;
        transition: opacity 300ms, visibility 0ms ease 300ms;
    }

    &--file &__x:before, &--file &__x:after {
        content: '';
        position: absolute;
        width: 16px;
        height: 2px;
        top: 50%;
        left: 50%;
        background: var(--file-x-color);
        transition: background 300ms;

        @media screen and (max-width: 600px) { width: 20px; }
    } 

    &--file &__x:before {
        transform: translate(-50%, -50%) rotate(45deg);
    } 

    &--file &__x:after {
        transform: translate(-50%, -50%) rotate(-45deg);
    } 

    &--file[data-file]:not([data-file=""]) &__label {
        pointer-events: none;
    }

    &--file[data-file]:not([data-file=""]) &__svg {
        opacity: 0;
    }

    &--file[data-file]:not([data-file=""]) &__x {
        opacity: 1;
        visibility: visible;
        pointer-events: all;
    }

    &--file &__label:hover &__text {
        color: var(--file-over-color);
    }

    &--file &__label:hover &__svg {
        fill: var(--file-svg-over-color);
    }

    &--file &__x:hover:before, &--file &__x:hover:after {
        background: var(--file-x-over-color);
    }


    // Select
    &--select &__label {
        display: block;
        padding: var(--field-label-padding);
        font-family: var(--field-label-font-family);
        font-size: var(--field-label-font-size);
        font-weight: var(--field-label-font-weight);
        line-height: var(--field-label-line-height);
        color: var(--field-label-color);
    }

    &--select .tail-select {
        position: relative;
        z-index: 3;
    }

    &--select .select-label {
        border: var(--tail-label-border);
        background: var(--tail-label-background);
        transition: var(--tail-label-transition);
        cursor: pointer;
        
        &:hover { background: #fff; }
    }

    &--select .label-inner {
        display: block;
        position: relative;
        padding: var(--tail-label-padding);
        min-height: var(--tail-label-min-height);
        font-family: var(--tail-label-font-family);
        font-size: var(--tail-label-font-size);
        font-weight: var(--tail-label-font-weight);
        line-height: var(--tail-label-line-height);
        text-transform: var(--tail-label-text-transform);
        letter-spacing: var(--tail-label-letter-spacing);
        color: var(--tail-label-color);

        display: flex;
        align-items: center;

        img{
            order: -1;
            margin-right: 30px;
        }

        &:before {
            content: '';
            position: absolute;
            width: 14px;
            height: 14px;
            top: 50%;
            right: 30px;
            transform: translateY(-50%);
            background: url('../medias/images/icons/pointer.svg') no-repeat center / contain;
            transition: transform 500ms var(--select-ease);
        }
    }

    &--select .select-dropdown {
        position: absolute;
        width: 100%;
        top: 0%;
        left: 0;
        opacity: 0;
        background: #fff;
        min-height: var(--tail-label-min-height);
        //border-top: 1px solid $color4;
        z-index: -1;
        visibility: hidden;
        pointer-events: none;
        transition: top 500ms var(--select-ease), opacity 500ms var(--select-ease), visibility 0ms ease 500ms;
        box-shadow: #cfcfcf 0 0 18px;
    }

    &--select .dropdown-inner {
        overflow: auto;
        max-height: 195px;
    }

    &--select .dropdown-option {
        padding: var(--tail-option-padding);
        font-family: var(--tail-option-font-family);
        font-size: var(--tail-option-font-size);
        font-weight: var(--tail-option-font-weight);
        line-height: var(--tail-option-line-height);
        text-transform: var(--tail-option-text-transform);
        letter-spacing: var(--tail-option-letter-spacing);
        color: var(--tail-option-color);
        background: var(--tail-option-background);
        cursor: pointer;
        transition: background 300ms, color 300ms;

        &:hover {
            color: var(--tail-option-over-color);
            background: var(--tail-option-over-background); 
        }

        &.selected { background: $color3; }

        &.has-image{
            display: flex;
            align-items: center;

            img{
                order: -1;
                margin-right: 30px;
            }
        }
    }

    // Select natif
    &--select select {
        width: 100%; 
        padding: var(--tail-label-padding);
        font-family: var(--tail-label-font-family);
        font-size: var(--tail-label-font-size);
        font-weight: var(--tail-label-font-weight);
        line-height: var(--tail-label-line-height);
        text-transform: var(--tail-label-text-transform);
        letter-spacing: var(--tail-label-letter-spacing);
        color: var(--tail-label-color);
        border: var(--tail-label-border);
        min-height: var(--tail-label-min-height);
        background: transparent;
        outline: none;
        -webkit-appearance: none;
        background: url('../medias/images/icons/pointer.svg') no-repeat calc(100% - 20px) 50% / 12px 12px;
    }

    select::-ms-expand { display: none; }

    &--select .tail-select.active {
        box-shadow: #cfcfcf 0 0 18px;
        .select-label { background: #fff; border-color: #fff; }
        .label-inner:before { transform: translateY(-50%) rotate(-180deg); }
        .select-dropdown {
            opacity: 1;
            top: 100%;
            visibility: visible;
            pointer-events: all;
            transition: top 500ms var(--select-ease), opacity 500ms var(--select-ease);
        }
    }

    &.alt-style{
        margin-bottom: 0;

        @media screen and (min-width: 1024px) {
            .select-label{
                border-left: 0;
                border-top: 0;
                border-right: 0;
            }
            .label-inner{
                min-height: 47px;
            }
        }
    }

}

:root{
    --auto-fill-color: white;
    --auto-fill-text: #{$color2};
    --auto-fill-border-color: #{$color2};
}
.dark-bg{
    --auto-fill-color: #{$color2};
    --auto-fill-text: white;
    --auto-fill-border-color: white;
}
.gray-bg{
    --auto-fill-color: #{$color3};
    --auto-fill-text: #{$color2};
    --auto-fill-border-color: #{$color2};
}

/* Change the white to any color ;) */
input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active  {
    -webkit-box-shadow: 0 0 0 30px var(--auto-fill-color) inset !important;
    -webkit-text-fill-color: var(--auto-fill-text);
    transition: none;
    border-bottom: 1px solid var(--auto-fill-border-color) !important;
}

input{
    &:-webkit-autofill::first-line {
        font-family: $font1;
        font-size: var(--field-font-size);
    }
}