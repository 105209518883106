.banner-contact {
    background: $color2;

    .breadcrumbs{
        @extend .x-padding-80;
    }

    &__container {
        width: 100%;
        position: relative;

        @extend .x-padding-80;
    }

    &__content {
        @extend .top-padding-100;
        @extend .bottom-padding-150;
    }

    &__title, &__email, &__phone {
        font-family: $font1;
        color: #fff;
    }

    &__title {
        @extend .huge-title;
        padding-bottom: 60px;

        @media screen and (max-width: 500px) {
            padding-bottom: 40px;
        }

        @media screen and (max-width: 400px) {
            padding-bottom: 30px;
        }
    }

    &__email, &__phone {
        @include fontsize(50, 0, 40, 30, 26, 20, 0);
        font-weight: 700;
        opacity: 1;
        transition: color 300ms;
        position: relative;

        &::after{
            content: '';
            bottom: -12px;
            left: 0;
            position: absolute;
            height: 6px;
            width: 100%;
            background-color: $color1;
        }

        &:hover {
            color: $color1;
        }
    }

    &__email {
        margin-bottom: 30px;
        
        @media screen and (max-width: 400px) {
            margin-bottom: 20px;
        }
    }

    &__down-button{
        position: absolute;
        right: var(--x-padding-80);
        bottom: 75px;

        @media screen and (max-width: 1024px) {
            position: static;
            padding-bottom: var(--y-padding-100);
        }
    }

    &__pattern {
        width: var(--pattern-width-large);
        height: 75px;
        left: 0;
        bottom: 0;
        background-position: 0px 1px;

        @media screen and (max-width: 500px) {
            height: 40px;
        }
    }
}