.page-top{
    background-color: white;
    position: relative;

    .breadcrumbs{
        @extend .x-padding-80;
    }

    &__over-title{
        @extend .bottom-padding-30;
        font-weight: normal;
        color: $color1;
        text-transform: uppercase;
        font-size: rem(13);
        font-weight: 500;
    }

    &__title{
        @extend .top-padding-50;
        @extend .bottom-padding-80;

        @media screen and (max-width: 768px) {
            margin-top: -20px;
        }

        h1{
            @extend .huge-title;
        }
    }

    &__title-date{
        color: $color1;
        font-size: rem(13);
        text-transform: uppercase;
        font-weight: 500;
        padding-bottom: 25px;
    }

    &__search{
        @extend .top-padding-60;
        @extend .bottom-padding-30;
        max-width: 625px;
    }

    &__picture{
        @extend .x-padding-80;
        position: relative;
        z-index: 1;

        @media screen and (max-width: 768px) {
            padding-left: 0;
            padding-right: 0;
        }

        &__wrapper{
            position: relative;
            picture{
                height: 530px;
                @media screen and (max-width: 1200px) { height: 500px; }
                @media screen and (max-width: 1024px) { height: 400px; }
                @media screen and (max-width: 768px) {  height: 350px; }
                @media screen and (max-width: 600px) {  height: 300px; }
                @media screen and (max-width: 500px) {  height: 250px; }
            }
        }
        &__credit{
            position: absolute;
            top: 0;
            right: 0;
            background-color: rgba($color2,0.5);
            color: white;
            padding: 20px 30px;
            font-size: rem(13);
            @media screen and (max-width: 768px) { padding: 14px 20px; }
        }
    }

    &__filter-wrapper{
        display: flex;
        justify-content: space-between;
        padding-top: var(--y-padding-60);
        @media screen and (max-width: 1024px) {
            flex-wrap: wrap;
        }
    }

    .filter-list,
    .filter-list-mobile{
        position: relative;
        z-index: 4;
        @media screen and (max-width: 1024px) {
            width: 100%;
        }
    }

    .filter-sort{
        min-width: 300px;
        padding-left: var(--x-padding-40);
        @media screen and (max-width: 1024px) {
            width: 100%;
            margin-top: var(--y-padding-30);
            padding-left: 0;
        }
    }

    // Ajustement des marges si il y a une image ou non
    &:not(.page-top--with-picture):not(.page-top--wide){
        .page-top__title{
            @extend .x-padding-180;
        }
    }

    &--with-picture{
        .page-top__title{
            @extend .x-padding-80;
        }

        &:after{
            content: '';
            position: absolute;
            background-color: $color3;
            bottom: 0;
            height: 270px;
            width: 100%;
            @media screen and (max-width: 1200px) { height: 250px; }
            @media screen and (max-width: 1024px) { height: 200px; }
            @media screen and (max-width: 768px) {  height: 175px; }
            @media screen and (max-width: 600px) {  height: 150px; }
            @media screen and (max-width: 500px) {  height: 125px; }
        }
    }

    &--wide{
        .page-top__title{
            @extend .x-padding-80;
        }
    }

    &>.grid-pattern{
        top: 90px;
        right: 0;
        height: 971px;
        width: var(--pattern-width-smaller);
        background-position: 1px 1px;
    }

    &--no-bottom-padding{
        .page-top__title{
            padding-bottom: 0;
        }
    }

    &--news-details{
        .page-top__title{
            h1{
                @extend .news-title;
                max-width: 80%;

                @media screen and (max-width: 1200px) { max-width: 100%; }
            }
        }
    }
}