/*** ----- TECHNICAL ----- ***/
$browser-context: 16; // La taille initiale de la typo
$colnb: 12; // Nombre de colonnes pour la grille
$gaps: 25px;  // Padding de chaque côté d'une colonne

:root {
    --grid-gaps: 25px;
    @media screen and (max-width: 1200px) { --grid-gaps: 15px; }
    @media screen and (max-width: 768px) { --grid-gaps: 10px; }

    --grid-bigger-gaps: 40px;
    @media screen and (max-width: 1200px) { --grid-bigger-gaps: 30px; }
    @media screen and (max-width: 768px) { --grid-bigger-gaps: 20px; }
}

/*** ----- COLORS ----- ***/
$color1: #00c281; // Vert principal
$color2: #202020; // Almost black
$color3: #f5f5f5; // Gris pâle
$color4: #999999; // Gris
$color5: #03b177; // Vert hover
$color6: #c93030; // Rouge erreur
$color7: #272727; // Gris foncé fond
$color8: #eaeaea; // Gris fond produit

/*** ----- FONTS ----- ***/
$font1: 'Aktiv Grotesk', sans-serif;

/*** ----- EASINGS ----- ***/
$easeOutCubic: cubic-bezier(0.33, 1, 0.68, 1);
$easeOutQuart: cubic-bezier(0.25, 1, 0.5, 1);
$easeInOutQuart: cubic-bezier(0.65, 0, 0.35, 1);
$easeInOutCubic: cubic-bezier(0.76, 0, 0.24, 1);
$easeInOutButtons: cubic-bezier(0.4,0,0,1);

:root {
    --header-height: 125px;
    @media screen and (max-width: 900px) { --header-height: 83px; }
}