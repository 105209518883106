.installation{

    --block-height: 800px;

    @media screen and (max-width: 1024px) {
        --block-height: 500px;
    }
    @media screen and (max-width: 768px) {
        --block-height: 300px;
    }

    @extend .x-padding-180;
    @extend .y-padding-100;
    background-color: $color2;
    height: var(--block-height);
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    &__title{
        @extend .huge-title;
        position: relative;
        z-index: 2;
    }

    &__play-button{
        /*position: absolute;
        bottom: -75px;
        left: 50%;
        transform: translate(-50%,0);*/
        @include fontsize(40, 0, 0, 23, 20, 0, 0);
        cursor: pointer;
        opacity: 1;
        transition: opacity 0.3s $easeInOutCubic;
        display: flex;
        align-items: center;
        width: 100%;
        justify-content: center;

        /*@media screen and (max-width: 768px) {
            position: static;
            transform: none;
            padding-top: 30px;
        }*/

        span{
            margin-right: 18px;
            color: white;
        }

        svg{
            fill: white;
            height: 50px;
            width: 50px;

            @media screen and (max-width: 768px) {
                height: 30px;
                width: 30px;
            }
        }

        &:hover{
            opacity: 0.6;
        }
    }

    picture{
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 1;
        opacity: 0.5;
    }
}