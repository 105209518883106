.huge-title { //h1
    font-weight: 700;
    line-height: 1.2;
    color: $color2;
    @include fontsize(80, 0, 60, 50, 40, 0, 0);
    
    span{
        color: $color1;
    }
}

.large-title { //h2
    font-weight: 700;
    line-height: 1.2;
    color: $color2;
    @include fontsize(35, 0, 32, 30, 26, 0, 0);
}

.large-title-smaller-mobile { //h2
    font-weight: 700;
    line-height: 1.2;
    color: $color2;
    @include fontsize(35, 0, 32, 22, 0, 0, 0);
}

.medium-title { //h3
    font-weight: 700;
    line-height: 1.2;
    color: $color2;
    @include fontsize(22, 0, 0, 0, 0, 0, 0);
}

.small-title {
    font-weight: 700;
    line-height: 1.2;
    color: $color2;
    @include fontsize(18, 0, 17, 16, 0, 0, 0);
}

.paragraph{
    line-height: 1.55555em; //28px/18px;
    color: $color2;
    @include fontsize(18, 0, 17, 16, 0, 15, 0);
}

.over-title {
    font-weight: bold;
    color: $color2;
    opacity: 0.4;
    @include fontsize(25, 0, 0, 0, 22, 0, 0);
    padding-left: 22px;
    position: relative;
    &::before{
        content: '';
        position: absolute;
        width: 6px;
        height: 6px;
        border-radius: 50%;
        top: 50%;
        left: 0;
        transform: translate(0,-50%);
        background-color: $color2;
    }
}

.minuscule-title {
    font-weight: 500;
    font-size: rem(13);
    line-height: 1.5em;
    color: $color1;
    text-transform: uppercase;
    letter-spacing: 1px;

    @media screen and (max-width: 425px) { font-size: rem(12); }
}

.news-title {
    font-weight: 700;
    line-height: 1.2;
    color: $color2;
    @include fontsize(50, 0, 40, 0, 30, 0, 0);
}

.jobs-title {
    font-weight: normal;
    line-height: 1.2;
    color: $color2;
    @include fontsize(80, 0, 60, 50, 40, 0, 0);
}

.dark-bg{
    .huge-title,
    .large-title,
    .medium-title,
    .small-title,
    .paragraph,
    .over-title {
        color: white;
    }

    .over-title {
        &::before{
            background-color: white;
        }
    }
}