.hamburger {
    position: absolute;
    width: 30px;
    height: 20px;
    cursor: pointer;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);

    @media screen and (max-width: 425px) { width: 25px; }

    span {
        display: block;
        position: absolute;
        width: 100%;
        height: 2px;
        background: $color2;
        opacity: 1;
        left: 0%;
        transform: rotate(0deg);
        transition: 250ms $easeInOutCubic;

        &:nth-child(1) { top: 0px; }
        &:nth-child(2), &:nth-child(3) { top: 8px; }
        &:nth-child(4) { top: 16px; }
    }
}
