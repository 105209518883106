.back-to-top{

    --width: 55px;
    --height: 55px;

    position: fixed;
    width: var(--width);
    height: var(--height);

    bottom: 30px;
    right: 30px;

    z-index: 5;
    transition: opacity 0.3s;
    opacity: 0;
    pointer-events: none;

    @media screen and (max-width: 768px) {
        bottom: 15px;
        right: 15px;
    }

    svg{
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%) rotate(180deg);
        fill: white;
        width: 21px;
        height: 29px;
        pointer-events: none;
        z-index: 2;
    }

    &::after{
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: $color1;
        border-radius: 50%;
        transform: scale(1);
        transition: transform 0.3s;
        z-index: 1;
        cursor: pointer;
    }

    &:hover{
        &::after{
            transform: scale(1.1);
        }
    }

}