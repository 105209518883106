.jobs-form {
    @extend .x-padding-120;
    @extend .top-padding-160;
    @extend .bottom-padding-170;

    &__title {
        @extend .huge-title;
        @extend .bottom-padding-100;
        text-align: center;
    }

    &__title-color {
        color: $color1;
    }
}