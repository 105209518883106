.news-nav{
    @extend .top-padding-80;

    &__wrapper{
        border-top: 9px solid rgba($color2,0.1);
        padding-top: 30px;
        display: flex;
        justify-content: space-between;
    }

    &__prev,
    &__next{
        text-transform: uppercase;
        font-size: rem(13);
        font-weight: 500;
        color: $color2;
        display: flex;
        align-items: center;
        padding: 0 20px;

        span{
            padding-right: 20px;
            display: inline-block;
        }

        svg{
            width: 19px;
            height: 26px;
            fill: $color1;
            transform: rotate(-90deg);
            transition: transform 0.3s $easeInOutCubic;
        }

        &:not(span):hover{
            svg{
                transform: rotate(-90deg) translate(0, 14px);
            }
        }
    }

    &__prev{
        flex-direction: row-reverse;

        span{
            padding-left: 20px;
            padding-right: 0;
            display: inline-block;
        }

        svg{
            transform: rotate(90deg);
        }

        &:not(span):hover{
            svg{
                transform: rotate(90deg) translate(0, 14px);
            }
        }
    }

    span.news-nav__prev,
    span.news-nav__next{
        color: rgba($color2,0.5);
        svg{
            fill: rgba($color2,0.2);
        }
    }
}