.newsletter-subscription{
    @extend .x-padding-180;
    @extend .top-padding-130;
    @extend .bottom-padding-160;
    background-color: $color2;
    position: relative;

    &__title{
        @extend .huge-title;
        text-align: center;
        span{
            display: block;
        }
    }

    &__form{
        @extend .top-padding-80;
        max-width: 500px;
        margin: auto;

        .field{
            margin-bottom: 0;

            &__box {
                border: 1px solid #fff;
            }

            &__text {
                color: #fff;
            }

            .field__input{
                padding-right: 55px;
                color: white;
                box-shadow: none !important;
                border-bottom: 2px solid $color1;
                padding-bottom: 10px;
                padding-top: 10px;
                height: auto;

                &:-webkit-autofill,
                &:-webkit-autofill:hover, 
                &:-webkit-autofill:focus, 
                &:-webkit-autofill:active
                {
                -webkit-box-shadow: 0 0 0 30px $color2 inset !important;
                }
            }

            button{
                position: absolute;
                right: 0;
                top: 0;
                height: 100%;
                width: 36px;
                border: none;
                background: none;
                cursor: pointer;

                &:focus {
                    outline: none;
                    box-shadow: none;
                }
                
                svg{
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    fill: $color1;
                    width: 19px;
                    height: 26px;
                    transition: transform 0.3s $easeInOutCubic;
                    transform: translate(-50%, -50%) rotate(-90deg);
                }

                &:hover{
                    svg{
                        transform: translate(calc(50% + 10px), -50%) rotate(-90deg);
                    }
                }
            }
        }
    }

    .grid-pattern{
        width: var(--pattern-width-normal);
        height: 100%;
        left: 0;
        top: 0;
    }
}