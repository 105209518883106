.showcase{
    display: block;
    &__picture{
        width: 100%;
        picture{
            width: 100%;
            height: 425px;
        }
        @media screen and (max-width: 1024px) {
            padding-top: 38%;
            position: relative;
            picture{
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                height: auto;
                width: auto;
            }
        }
        @media screen and (max-width: 1024px) {
            padding-top: 45%;
        }
    }
    &__content{
        @extend .x-padding-120;
        @include fontsize(65,60, 50, 40, 30, 25, 22);
        display: flex;
        align-items: center;
        background-color: $color2;
        color: white;
        height: 234px;
        padding-top: 20px;
        padding-bottom: 20px;
        font-weight: 700;
        position: relative;

        @media screen and (max-width: 1200px) {
            height: 180px;
        }
        @media screen and (max-width: 1024px) {
            height: 140px;
        }
        @media screen and (max-width: 768px) {
            height: 120px;
        }

        span{
            position: relative;
            z-index: 2;
            padding-right: 30px;
        }

        &::before{
            content: "";
            position: absolute;
            top: 0;
            height: 100%;
            left: 0;
            background-color: $color1;
            width: 100%;
            transform-origin: right;
            transition: transform 0.6s $easeInOutButtons;
            transform: scaleX(0);
            z-index: 1;
        }
    }
    &__icon{
        width: 120px;
        height: 120px;
        position: relative;
        margin-left: auto;
        z-index: 2;
        flex-shrink: 0;

        @media screen and (max-width: 1200px) {
            width: 100px;
            height: 100px;
        }
        @media screen and (max-width: 1024px) {
            width: 90px;
            height: 90px;
        }
        @media screen and (max-width: 768px) {
            width: 60px;
            height: 60px;
        }

        svg{
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%,-50%) rotate(-90deg);
            fill: $color1;
            width: 27px;
            height: 39px;
            z-index: 1;

            @media screen and (max-width: 768px) {
                width: 18px;
                height: 24px;
            }
        }

        &::after{
            content: '';
            position: absolute;
            width: 100%;
            height: 100%;
            border-radius: 50%;
            left: 50%;
            top: 50%;
            transform: translate(-50%,-50%) scale(1);
            background-color: white;
            z-index: 0;
            transition: transform 0.3s $easeInOutCubic;
        }
    }

    &:hover{
        .showcase__content{
            &::before{
                transition: transform 0.6s $easeInOutButtons;
                transform: scaleX(1);
                transform-origin: left;
            }
        }
        .showcase__icon{
            &::after{
                transform: translate(-50%, -50%) scale(1.1);
            }
        }
    }
}