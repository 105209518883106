.rubric {
    position: relative;
    border-bottom: 1px solid $color2;
    background: transparent;
    //overflow: hidden;
    transition: border-bottom 700ms;

    &:first-child { border-top: 1px solid $color2; }

    &__header {
        position: relative;
        cursor: pointer;
        padding: 20px 0;
        padding-right: 115px;
        min-height: 155px;
        display: flex;
        align-items: center;

        @media screen and (max-width: 1024px) { padding-right: 80px; }
        @media screen and (max-width: 768px) { min-height: 130px; }
        @media screen and (max-width: 600px) { min-height: 110px; padding-right: 50px; }
    }

    &__header:hover &__icon:before {
        background: $color1;
        transform: scale(1.20512); // 94px/78px
    }

    &__title {
        @extend .large-title-smaller-mobile;
        line-height: 1.5;
        color: $color2;
        transition: color 0.3s $easeInOutCubic;
        padding-left: 20px;
        @media screen and (max-width: 1024px) { padding-left: 0; }
    }

    &__icon {
        position: absolute;
        width: 78px;
        height: 78px;
        top: 50%;
        right: 20px;
        transform: translateY(-50%);

        @media screen and (max-width: 1024px) { right: 0; width: 60px; height: 60px; }
        @media screen and (max-width: 600px) { width: 34px; height: 34px; }
    }

    &__icon:before {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: $color2;
        border-radius: 50%;
        transition: background 300ms, transform 300ms $easeOutCubic;
    }

    &__svg {
        position: absolute;
        width: 22px;
        height: 30px;
        top: 50%;
        left: 50%;
        fill: white;
        transform: translate(-50%, -50%) rotate(0deg);
        transition: transform 300ms $easeOutCubic, stroke 300ms;
        @media screen and (max-width: 600px) { width: 15px; height: 21px; }
    }

    &__content {
        height: 0;
        will-change: height;
        overflow: hidden;
        padding: 0 20px;
        @media screen and (max-width: 1024px) { padding: 0; }
    }

    &__transition {
        opacity: 0;
        transform: translateY(100px);
        transition: opacity 700ms $easeInOutQuart, transform 700ms $easeInOutQuart;
    }
    
    &__transition > :first-child { margin-top: 0; }

    &__dynamic {
        h3 { @include fontsize(22, 0, 0, 0, 0, 21, 20); margin: 20px 0 10px; }
        h4 { @include fontsize(16, 0, 0, 0, 0,  0,  0); margin: 15px 0 5px; }
        p { margin: 5px 0 15px; }
        blockquote {
            @include fontsize(23, 0, 22, 21, 20, 19, 18);
            p { @include fontsize(23, 0, 22, 21, 20, 19, 18); }
        }
    }

    &__document, &__gallery, &__share {
        @extend .top-margin-60;
    }

    &__share {
        @extend .bottom-padding-60;
    }

}

.rubrics.rubrics--plus {
    .rubric {
        &:not(.rubric__no-image) .rubric__header {
            padding-top: 20px;
            padding-bottom: 20px;

            @media screen and (max-width: 600px) { padding-top: 30px; padding-bottom: 30px; }
        }

        &__flex {
            display: flex;
            align-items: center;
            width: 100%;
        }

        &__text {
            width: calc(100% - 170px);

            @media screen and (max-width: 1200px) { width: calc(100% - 155px); }
            @media screen and (max-width: 1024px) { width: calc(100% - 125px); }
            @media screen and (max-width: 768px)  { width: calc(100% - 95px);  }
            @media screen and (max-width: 600px)  { width: 100%; }
        }

        &__picture {
            width: 220px;
            height: 100px;
            margin-right: 30px;
            overflow: hidden;

            @media screen and (max-width: 1200px) { width: 125px; height: 125px; }
            @media screen and (max-width: 1024px) { width: 100px; height: 100px; margin-right: 25px; }
            @media screen and (max-width: 768px)  { width: 75px;  height: 75px; margin-right: 20px; }
            @media screen and (max-width: 600px)  { display: none; }
        }

        &__subtitle {
            margin-bottom: 5px;
            padding-right: 50px;
            padding-left: 20px;
            @extend .minuscule-title;
            @media screen and (max-width: 1024px) { padding-left: 0; }
        }

        &:not(.rubric__no-image){
            .rubric__title,
            .rubric__subtitle{
                padding-left: 0;
            }
            .rubric__transition {
                padding-top: 30px;
                @media screen and (max-width: 1024px) { padding-top: 15px; }
                @media screen and (max-width: 600px)  { padding-top: 0px; }
            }
        }
    }
}


.fancybox-caption__body {
    font-family: $font1;
}